import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Button,
    Typography
} from "@mui/material";
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepThree from "./Steps/StepThree";
import "../CSS/Profile.scss"
import { useSelector } from "react-redux";

const MyInsurance = (props, toggle, sendData) => {
    const refStep1 = useRef()
    const refStep2 = useRef()
    const refStep3 = useRef()
    const [Value, setValue] = useState(0)
    const [activeStep, setActiveStep] = useState(0);
    const [data, setdata] = useState(0);
    const [Btn, setBtn] = useState("Submit");
    const { insuranceInfo } = useSelector((state) => state.insurance);
    const local = localStorage.getItem("Whoare")
    const steps = [
        {
            label: 'Subsciber Information',
            component: <StepOne ref={refStep1} Called={Value} nexPage={nexPage} />
        },
        {
            label: 'Insurance Information',
            component: <StepTwo ref={refStep2} Called={Value} />
        },
        {
            label: 'Review & Submit',
            component: <StepThree ref={refStep3} Called={Value} />
        },
    ];

    const handleNext = async (index) => {
        if (index === 0) {
            await setValue(1)
            let Resulut = await refStep1.current.printSomething()
            if (Resulut === true) {
                await setdata(1)
            } else {
                return false;
            }
        }
        if (index === 1) {
            await setValue(2)
            let Resulut = await refStep2.current.printSomething()
            if (Resulut === true) {
                await setdata(2)
            } else {
                return false;
            }
        }
        if (index === 2) {
            setisSubmit(true)
            await setValue(3)
            let Resulut = await refStep3.current.printSomething()
            if (Resulut === true) {
                await setdata(3)
            } else {
                return false;
            }
        }
    };


    async function nexPage(val) {
        //console.log("Next Page")
        await setValue(1)
        await refStep1.current.printSomething()
        setdata(1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = () => {
        setdata(0)
        setActiveStep(0);
        props.sendData();
    };

    useEffect(() => {
        if (insuranceInfo[0]?.id) {
            setBtn("Submit")
        }
        if (data === 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setdata(0)
        }
        if (data === 2) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setdata(0)
        }
        if (data === 3) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setdata(0)
            handleSubmit()
        }
    }, [Value, data, local]);
    let root = document?.documentElement;
    let Primarycolor = root?.style?.getPropertyValue('--primary-color',) ? root?.style?.getPropertyValue('--primary-color',) : "#6D3580";
    let Secoundrycolor = root?.style?.getPropertyValue('--secoundry-color') ? root?.style?.getPropertyValue('--secoundry-color') : "#CC4165";

    const [isSubmit, setisSubmit] = useState(false);
    return (
        <Box sx={{ maxWidth: 'auto' }}>
            <Stepper activeStep={activeStep} orientation="vertical" >
                {steps.map((step, index) => (
                    <Step key={step.label}
                        sx={{
                            '& .MuiStepLabel-root .Mui-completed': {
                                color: `${Primarycolor}`, // circle color (COMPLETED)
                            },
                            '& .MuiStepLabel-root .Mui-active': {
                                color: `${Primarycolor}`, // circle color (ACTIVE)
                                fontSize: 25,
                                fontWeight: 'bold'
                            },
                            '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                fontWeight: 'bold',
                                fill: '#fff', // circle's number (ACTIVE)
                            },
                        }}
                    >
                        <StepLabel
                            optional={
                                index === 2 ? (
                                    <Typography variant="caption">Last step</Typography>
                                ) : null
                            }
                        >
                            {step.label}
                        </StepLabel>
                        <StepContent >
                            <Typography>{step.component}</Typography>
                            <Box sx={{ mt: 3 }}>
                                <div>
                                    <Button
                                        disabled={isSubmit}
                                        variant="contained"
                                        className="btn btn-inshover"
                                        onClick={() => handleNext(index)}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {index === steps.length - 1 ? Btn : 'Continue'}
                                    </Button>
                                    <Button
                                        disabled={index === 0}
                                        color="warning"
                                        onClick={() => {
                                            setisSubmit(false)
                                            handleBack()
                                        }}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
            {/* {
                activeStep === steps.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>All steps completed - you&apos;re finished</Typography>
                        <Button onClick={() => handleSubmit()} sx={{ mt: 1, mr: 1 }}>
                            Done
                        </Button>
                    </Paper>
                )
            } */}
        </Box >
    );
};

export default MyInsurance;
