import "../App.scss";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoginModal from "../components/Modal";
import TermsCondition from "../components/TermsCondition";
import ContactUs from "../components/ContactUsModal";
import { useLocation } from "react-router-dom";

const Terms = () => {

    const [modal, setModal] = useState(false);
    const [labCode, setlabCode] = useState(null);
    const [contactmodal, setcontactModal] = useState(false);
    const location = useLocation();
    const toggleModal = (code) => {
        setModal(!modal);
        setlabCode(code);
    };

    const ContactusToggele = () => {
        setcontactModal(!contactmodal);
    };

    useEffect(() => {
        AOS.init({
            duration: 1500,
        });
        //console.log("Labcode Terms (((((", location.state?.name)
    }, []);

    return (
        <>
            <Header toggleModal={toggleModal}
                ContactusToggele={ContactusToggele}
                Labcode={location.state?.name} />
            <LoginModal isOpen={modal} toggle={toggleModal} Labcode={labCode} />
            <ContactUs isOpen={contactmodal} ContactusToggele={ContactusToggele} />
            <TermsCondition Labcode={location.state?.name} />
            <Footer Labcode={location.state?.name} />
        </>
    );
};

export default Terms;
