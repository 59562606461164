import "../CSS/footer.scss";
import LOGO from "../Assets/i-health-logo(R)_black.png";
import ebazzar from "../Assets/Footer/i-homehealth-logo.png"
import myHealthLogo from "../Assets/myhealth-logo.png"
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../store/slices/user";
import { setinsuranceInfo } from "../store/slices/insurance";
import { ToastContainer, toast } from "react-toastify";

const Footer = (props, Labcode) => {
  // console.log("LabCode Footer", props.Labcode);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const { code } = useParams();
  const { userinfo } = useSelector((state) => state.user);
  const [imageFlag, setimageFlag] = useState()
  const toComponentB = () => {
    navigate(process.env.PUBLIC_URL + "/about/terms-conditions/", { state: { id: 1, name: props.Labcode } });

    window.scrollTo(0, 0);
  }
  async function checkLab() {
    // const match = LabDetails.find(
    //   (item) => item.labcode === code
    // );
    let Token = userinfo?.token
    let Patient_ID = userinfo?.uname

    const Baseurl = await JSON.parse(localStorage.getItem("Baseurl"))
    // console.log("Baseurl FooOOOOOOOOOOOOOter", Baseurl)

    const match = Baseurl?.find(
      (item) => item.laboratory_code === code
    );

    // console.log("match FOOOTERRRRRRRRRRRRRRRRRRRRRRRRRRRR", match)
    if (match) {
      setimageFlag(match?.app_platform)
      localStorage.setItem("BaseApiUrl", match?.base_api_url)
      localStorage.setItem("ReportApiUrl", match?.base_app_url)
      localStorage.setItem("TenantNameApi", match?.name)
      let BASEURL = await localStorage.getItem("BaseApiUrl")
      try {
        await fetch(`${BASEURL}/apipatient/public/v1/tenantsinfo`,
          {
            method: 'POST',
            headers: {
              // Authorization: 'Bearer ' + Token,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              "laboratory_code": code,
              "token": Token,
              "patient_id": Patient_ID,
              "with_auth": Patient_ID ? "yes" : "no",
            }),
          })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((responseJson) => {
                // console.log("Get Response User Info Foooterrrrr===========", responseJson);
                if (responseJson.data) {
                  let Temp = responseJson.data
                  if (Temp.id) {
                    let root = document?.documentElement;
                    root?.style?.setProperty('--primary-color', Temp?.primary_color);
                    root?.style?.setProperty('--secoundry-color', Temp?.secondary_color);
                  }
                }
              });
            } else if (response.status === 500) {
              response.json().then(async (responseJson) => {
                if (responseJson.message == "Token Expired!") {
                  // const keysToRemove = ['BaseApiUrl', 'TenantNameApi', 'TenantID', 'isStepOne', 'Whoare', 'Listdata'];
                  const keysToRemove = ['TenantID', 'isStepOne', 'Whoare', 'Listdata'];
                  dispatch(
                    setUserInfo([]),
                    setinsuranceInfo([])
                  )
                  // navigate(process.env.PUBLIC_URL + `/${userinfo.labcode || code}`);
                  navigate(process.env.PUBLIC_URL + `/${code || userinfo.labcode}`);
                  for (const key of keysToRemove) {
                    await localStorage.removeItem(`${key}`);
                  }
                  // localStorage.clear()
                  // toast.success("Session Time Out Please Login Again !!")
                }
              });
            } else {
              toast.error("Something Went Wrong !!")
            }
          })
          .catch((error) => {
            console.log("Fetch Error", error);
          });
      } catch (error) {
        console.log('Try Error', error);
      }
    } else {
      // console.log("inside Else to pass to baseuRL Labpage")
    }
  }

  useEffect(() => {
    checkLab();
  }, []);
  const currentyear = new Date().getFullYear();

  return (
    <footer
      className="main-footer position-relative ml-0 d-print-none p-3 justify-content-center"
      id="main-footer">
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        color
      />
      <div className="position-top-25-right-2 d-none d-sm-block ftr-logo">
        {props.Labcode ? "Powered by" : "Product by"} <img src={imageFlag === "ebazaar" && LOGO || imageFlag === "ibazaar" && ebazzar || imageFlag === "ihealth" && LOGO || imageFlag === "estihealthcare" && LOGO || imageFlag === "clwihealthcare" && LOGO || imageFlag === "ihomehealth" && ebazzar || imageFlag == (null || undefined) && myHealthLogo} alt="iHealth Logo" />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4 col-sm-12 ">
            <span className="term" title="Terms & Conditions">
              <a
                href={`${process.env.PUBLIC_URL}/about/terms-conditions?id=1&name=${props.Labcode}`}
                onClick={(e) => {
                  e.preventDefault();
                  toComponentB();
                }}
              >
                Terms & Conditions
              </a>
            </span>{" "}
            <span className="mx-2">|</span>
            <span className="term" title="Privacy">
              <a href={props.Labcode ? process.env.PUBLIC_URL + `/${props.Labcode}/about/privacy` : process.env.PUBLIC_URL + "/about/privacy"}>
                Privacy
              </a>
            </span>
          </div>
          <div className="col-md-4 col-sm-12 mt-sm-2 mt-md-0 text-md-center text-sm-left">
            <strong style={{ opacity: 0.7 }}>
              Copyright &copy; {currentyear}{" "}
              <a
                className="link"
                href="https://healthsoftus.com/"
                target="_blank"
                title="Opens in a new window"
              >
                Healthsoft
              </a>
              .
            </strong>{" "}
            <span className="d-sm-none d-md-inline">All rights reserved.</span>
          </div>
        </div>
        <div className="col-md-4 col-sm-12 mt-sm-2 mt-md-0 text-md-center text-sm-left d-md-none d-sm-block">
          All rights reserved.
        </div>
      </div>
    </footer >
  );
};

export default Footer;
