import React, { useEffect, useState } from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Col,
    FormGroup,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Autocomplete from "react-google-autocomplete";
import MyInsurance from "./MyInsurance";
import { setinsuranceInfo } from "../store/slices/insurance";
import { useDispatch, useSelector } from "react-redux";
import { format } from 'date-fns'
import { ToastContainer, toast } from "react-toastify";
import LoaderComp from "./Loader/Loader";
import InputMask from 'react-input-mask';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { Link, useNavigate } from "react-router-dom";
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "../CSS/Profile.scss"

let BASEURL = await localStorage.getItem("BaseApiUrl")

const UserProfile = (props, Labcode) => {
    const [isLoading, setisLoading] = useState(false);
    const dispatch = useDispatch()
    //Tab Bar Controls
    const [Active1, setActive1] = useState("active")
    const [Active2, setActive2] = useState("")
    const [Active3, setActive3] = useState("")
    const [Tabid, setTabid] = useState("1")
    const [Index, setIndex] = useState(0)
    const { userinfo } = useSelector((state) => state.user);

    //Tab 1 Input Field
    const [FirstName, setFirstName] = useState("")
    const [MiddleName, setMiddleName] = useState("")
    const [LastName, setLastName] = useState("")
    const [DOB, setDOB] = useState()
    const [Sex, setSex] = useState("")
    const [Inputemailtype, setInputemailtype] = useState("")
    const [Inputemail, setInputemail] = useState("")
    const [Inputcontacttype, setInputcontacttype] = useState("")
    const [Inputcontact, setInputcontact] = useState("")
    const [Inputaddresstype, setInputaddresstype] = useState("")
    const [Inputrace, setInputrace] = useState("")
    const [Inputethnicity, setInputethnicity] = useState("")
    const [Inputreligion, setInputreligion] = useState("")
    const [Inputlanguage, setInputlanguage] = useState("")
    const [Inputnationality, setInputnationality] = useState("")
    const [DrivingLic, setDrivingLic] = useState("")

    const [SSN, setSSN] = useState("");
    const [view, setview] = useState(false)
    //Tab 1 Mapping 
    const [Gender, setGender] = useState([])
    const [ContactNum, setContactNum] = useState([])
    const [PhoneType, setPhoneType] = useState([])
    const [EmailId, setEmailId] = useState([])
    const [EmailType, setEmailType] = useState([])
    const [Address, setAddress] = useState([])
    const [AddressType, setAddressType] = useState([])
    const [Race, setRace] = useState([])
    const [Ethnicity, setEthnicity] = useState([])
    const [Religion, setReligion] = useState([])
    const [Language, setLanguage] = useState([])
    const [Nationality, setNationality] = useState([])
    const [Userinfo, setUserinfo] = useState([])
    const [isAddFlag, setisAddFlag] = useState(false)

    //Tab 2 
    const [isNewInsCard, setisNewInsCard] = useState(false);
    //Tab 2 Modal
    const [selectedImage, setselectedImage] = useState(null);
    const [imageUrl, setimageUrl] = useState(null);
    const [Flag, setFlag] = useState(0);
    const [isPDFins, setisPDFins] = useState(false);
    const [otherDoc, setotherDoc] = useState("")
    const ref = React.useRef();

    //Tab 3
    const [isOpen, setisOpen] = useState(false);
    const [InsuranceApi, setInsuranceApi] = useState([])
    const [isActive, setisActive] = useState(false);
    const [endDate, setendDate] = useState()
    const [isCard, setisCard] = useState(false)
    const [isID, setisID] = useState("")
    const [docFlag, setdocFlag] = useState("")
    const [docfile, setdocfile] = useState("")
    const [LinkDocName, setLinkDocName] = useState(true)
    const [primaryinsName, setprimaryinsName] = useState()
    const [primaryinsModal, setprimaryinsModal] = useState(false)
    const [primaryinsID, setprimaryinsID] = useState()

    //Tab 2 Extra
    const [doctype, setdoctype] = useState("");
    const [InsuranceCard, setInsuranceCard] = useState([])
    const [isOption, setisOption] = useState(false)
    const [photoid, setphotoid] = useState([])
    const [activeid, setactiveid] = useState("")

    //Upload Modal
    const [selectedImage3, setselectedImage3] = useState(null);
    const [Flag3, setFlag3] = useState(0)
    const [imageUPLOAD, setimageUPLOAD] = useState(null)
    const [isPDFUPLOAD, setisPDFUPLOAD] = useState(false)
    const UPLOAD = React.useRef();
    const navigate = useNavigate()
    useEffect(() => {
        let BASEURL = localStorage.getItem("BaseApiUrl")
        if (BASEURL) {
            if (Tabid == "1") {
                genderList()
                phoneList()
                emailList()
                addressList()
                raceList()
                ethnicityList()
                religionList()
                languageList()
                nationalityList()
                getInfo()
            }
            if (Tabid == "2") {
                getInsurance()
                getInfo()
            }
            if (Tabid == "3") {
                getInsurance()
                getInfo()
            }
        }
    }, [Tabid]);

    useEffect(() => {
        let windowurl = window.location.href
        if (windowurl.includes("?2")) {
            setActive1("")
            setActive2("")
            setActive3("active")
            setTabid("3")
        }
    }, []);

    useEffect(() => {
        if (!Flag == 0) {
            setimageUrl(URL?.createObjectURL(selectedImage))
        }
        if (!Flag3 == 0) {
            setimageUPLOAD(URL?.createObjectURL(selectedImage3))
        }
    }, [Flag, Flag3]);

    //Function For Tab Bar Controls
    async function TabContant(TAB) {
        if (TAB === "1") {
            setActive1("active")
            setActive2("")
            setActive3("")
            setTabid("1")
        }

        if (TAB === "2") {
            setActive1("")
            setActive2("active")
            setActive3("")
            setTabid("2")
        }

        if (TAB === "3") {
            setActive1("")
            setActive2("")
            setActive3("active")
            setTabid("3")
        }
    }
    const CreditCard = ({ InsuranceName,
        MemberID,
        Group,
        Service,
        Effective_From,
        Name,
        Gender,
        Realation,
        DOB,
        insuranceID,
        endDate,
        index }) => {
        const [isHovered, setIsHovered] = useState(false);
        const handleCardHover = () => {
            setIsHovered(!isHovered);
        };
        return (
            <>
                <center>
                    <div className={`credit-card ${isHovered ? 'hovered' : ''}`} onMouseEnter={handleCardHover} onMouseLeave={handleCardHover}>
                        {index == 0 && <div className="ribbon ribbon-top-left "><span className="bg-primary">Primary</span></div>}
                        <Link title="Click to make primary" >{index == 1 && <div className="ribbon ribbon-top-left "><span className="bg-info">Secondary</span></div>}</Link>
                        <Link title="Click to make primary" >{index > 1 && <div className="ribbon ribbon-top-left "><span className="bg-secondary">Other</span></div>}</Link>
                        {index == "Expired" && <div className="ribbon ribbon-top-left"><span className="bg-danger">Expired <br />{endDate}</span></div>}
                        <div className="card-front" >
                            <div className="card-logo px-3 d-flex" >
                                <span className="ps-5 text-light fw-bold fs-5">{InsuranceName.slice(0, 15) + '...'}</span>
                            </div>
                            <div className="card-chip d-flex w-100">
                                <span className="text-light" style={{ width: "45%" }}>Subscriber Name</span>
                                <span className="fw-bold ps-10 text-light w-50"> {Name}</span>
                            </div>
                            <div className="card-chip d-flex w-100">
                                <span className="text-light" style={{ width: "45%" }}>Member ID</span>
                                <span className="fw-bold ps-10 text-light "> {MemberID}</span>
                            </div>
                            <div className="card-chip d-flex w-100">
                                <span className="text-light" style={{ width: "45%" }}>Group</span>
                                <span className="fw-bold ps-10 text-light "> {Group}</span>
                            </div>
                            <div className="card-chip d-flex w-100">
                                <span className="text-light" style={{ width: "45%" }}>Service</span>
                                <span className="fw-bold ps-10 text-light "> {Service}</span>
                            </div>
                            <div className="card-chip d-flex w-100">
                                <span className="text-light" style={{ width: "45%" }}>Effective Date</span>
                                <span className="fw-bold ps-10 text-light "> {Effective_From}</span>
                            </div>

                        </div>
                        <div className={`card-back ${isHovered ? 'show' : ''}`}>
                            <div className="card-details">
                                <div className="card-holder">
                                    <div className="label">Subscriber Holder</div>
                                    <div className="name">{Name}</div>
                                </div>
                                <div className="expiry-date">
                                    <div className="label">Relation</div>
                                    <div className="date">{Realation === "SE" && "Self"}
                                        {Realation === "SP" && "Spouse"}
                                        {Realation === "DE" && "Child"}
                                        {Realation === "OT" && "Other"}</div>
                                </div>
                                <div className="d-flex">
                                    <div className="expiry-date">
                                        <div className="label px-3">Gender</div>
                                        <div className="date">{Gender === "F" && "Female"}
                                            {Gender === "M" && "Male"}
                                            {Gender === "O" && "Other"}
                                            {Gender === "U" && "Unknown"}</div>
                                    </div>
                                    <div className="expiry-date">
                                        <div className="label px-3">Date Of Birth</div>
                                        <div className="date">{DOB}</div>
                                    </div>
                                </div>
                                <div className="cvv">{MemberID}</div>
                            </div>
                        </div>
                    </div>
                </center>
            </>
        );
    };
    //Functions For Tab 1
    async function genderList() {
        setisLoading(!isLoading)
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/mastertable/sex`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response Gender", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                setGender(Temp)
                                setisLoading(false)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    setisLoading(false)
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function phoneList() {

        try {
            await fetch(`${BASEURL}/apipatient/public/v1/mastertable/phones`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response Phone Type", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                setPhoneType(Temp)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function emailList() {

        try {
            await fetch(`${BASEURL}/apipatient/public/v1/mastertable/emails`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response Email Type", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                setEmailType(Temp)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function addressList() {

        try {
            await fetch(`${BASEURL}/apipatient/public/v1/mastertable/address`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response Address Type", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                setAddressType(Temp)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function raceList() {

        try {
            await fetch(`${BASEURL}/apipatient/public/v1/mastertable/races`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response Races", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                setRace(Temp)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function ethnicityList() {

        try {
            await fetch(`${BASEURL}/apipatient/public/v1/mastertable/ethnicities`,
                {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response Ethnicity", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                setEthnicity(Temp)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function religionList() {

        try {
            await fetch(`${BASEURL}/apipatient/public/v1/mastertable/religions`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response Religions", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                setReligion(Temp)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function languageList() {

        try {
            await fetch(`${BASEURL}/apipatient/public/v1/mastertable/language`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response Languages", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                setLanguage(Temp)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function nationalityList() {

        try {
            await fetch(`${BASEURL}/apipatient/public/v1/mastertable/nationality`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response Nationality", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                setNationality(Temp)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function getInfo() {
        let Patient_ID = userinfo?.uname
        setisLoading(!isLoading)
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/getpatientdetails/` + Patient_ID,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response User Info", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                let Contact = responseJson.data.contactmethods?.data
                                let date = Temp.dob?.split("T")
                                let datevalue = ""
                                if (date) {
                                    let date2 = date[0]?.split("-")
                                    if (date2) {
                                        datevalue = date2[0] + "/" + date2[1] + "/" + date2[2]
                                    }
                                }
                                if (Temp.ssn == "" || null || undefined) {
                                    setview(true)
                                } else {
                                    setview(false)
                                    //console.log("out", view, Temp.ssn)
                                }
                                setSSN(Temp.ssn)
                                setDOB(datevalue)
                                setUserinfo(Temp)
                                setFirstName(Temp.first_name)
                                setMiddleName(Temp.middle_name)
                                setLastName(Temp.last_name)
                                setSex(Temp.sex)
                                setInputrace(Temp.race)
                                setInputethnicity(Temp.ethnicity)
                                setInputreligion(Temp.religion)
                                setInputlanguage(Temp.language)
                                setInputnationality(Temp.nationality)
                                setDrivingLic(Temp.dr_lic)
                                setInsuranceApi(responseJson.data?.patientinsurances?.data)
                                localStorage.setItem("TenantID", responseJson.data.tenant_id)
                                let ArrayAddress = []
                                let ArrayEmail = []
                                Contact?.map((item) => {
                                    if (item.type === "PHONE") {
                                        ArrayAddress.push(item)
                                    }
                                    if (item.type === "EMAIL") {
                                        ArrayEmail.push(item)
                                    }
                                })
                                setContactNum(ArrayAddress)
                                setEmailId(ArrayEmail)
                                setAddress(responseJson.data?.addresses?.data)
                                setisLoading(false)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function ContactDetail(indexedDB, Type, id) {

        let Token = userinfo?.token
        let Patient_ID = userinfo?.uname
        if (Type === "+") {
            let array = [...ContactNum];
            array.push({
                index: indexedDB
            })
            setContactNum(array)
            setIndex(indexedDB)
        }

        if (Type === "-") {
            if (id) {
                setisLoading(true)
                try {
                    await fetch(`${BASEURL}/apipatient/public/v1/deletepatientcontact`,
                        {
                            method: 'POST',
                            headers: {
                                // Authorization: 'Bearer ' + Token,
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                "id": id,
                                "token": Token,
                                "patient_id": Patient_ID,
                            }),
                        })
                        .then((response) => {
                            if (response.status === 200) {
                                response.json().then((responseJson) => {
                                    //console.log(`Get Response ${id}`, responseJson);
                                    setisLoading(false)
                                    getInfo()
                                });
                            } else {
                                //console.log("Res False")
                            }
                        })
                        .catch((error) => {
                            console.log("Fetch Error", error);
                        });
                } catch (error) {
                    console.log('Try Error', error);
                }
            } else {
                let array = [...ContactNum];
                array.splice(indexedDB, 1)
                setContactNum(array)
            }
        }
    }
    async function EmailDetail(indexedDB, Type, id) {

        let Token = userinfo?.token
        let Patient_ID = userinfo?.uname
        if (Type === "+") {
            let array = [...EmailId];
            array.push({
                index: indexedDB
            })
            setEmailId(array)
            setIndex(indexedDB)
        }

        if (Type === "-") {
            if (id) {
                setisLoading(true)
                try {
                    await fetch(`${BASEURL}/apipatient/public/v1/deletepatientcontact`,
                        {
                            method: 'POST',
                            headers: {
                                // Authorization: 'Bearer ' + Token,
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                "id": id,
                                "token": Token,
                                "patient_id": Patient_ID,
                            }),
                        })
                        .then((response) => {
                            if (response.status === 200) {
                                response.json().then((responseJson) => {
                                    setisLoading(false)
                                    getInfo()
                                    //console.log(`Get Response ${id}`, responseJson);
                                });
                            } else {
                                //console.log("Res False")
                            }
                        })
                        .catch((error) => {
                            console.log("Fetch Error", error);
                        });
                } catch (error) {
                    console.log('Try Error', error);
                }
            } else {
                let array = [...EmailId];
                array.splice(indexedDB, 1)
                setEmailId(array)
            }
        }
    }
    async function AddressDetail(indexedDB, Type, id) {

        let Token = userinfo?.token
        let Patient_ID = userinfo?.uname
        if (Type === "+") {
            let array = [...Address];
            array.push({
                index: indexedDB
            })
            setIndex(indexedDB)
            setAddress(array)
            //console.log("New Add 0", Address)
        }

        if (Type === "-") {
            if (id) {
                setisLoading(true)
                try {
                    await fetch(`${BASEURL}/apipatient/public/v1/deletepatientaddress`,
                        {
                            method: 'POST',
                            headers: {
                                // Authorization: 'Bearer ' + Token,
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                "id": id,
                                "token": Token,
                                "patient_id": Patient_ID,
                            }),
                        })
                        .then((response) => {
                            if (response.status === 200) {
                                response.json().then((responseJson) => {
                                    //console.log(`Get Response ${id}`, responseJson);
                                    setisLoading(false)
                                    getInfo()
                                });
                            } else {
                                //console.log("Res False")
                            }
                        })
                        .catch((error) => {
                            console.log("Fetch Error", error);
                        });
                } catch (error) {
                    console.log('Try Error', error);
                }
            } else {
                let array = [...Address];
                array.splice(indexedDB, 1)
                setAddress(array)
            }
        }
    }
    async function autoComplete(selectedData, index, id, type) {
        let Temp = selectedData.formatted_address.split(",")
        const match = Address.find(
            (item) => item.short_address == Temp[0]
        )
        if (match) {
            window.confirm("You can not add Same type of Address")
            getInfo();
            return;
        }

        if (selectedData) {
            var ac = 0
            if (Address.length <= index) {
                Address.push({});
            }
            for (ac; ac < selectedData.address_components.length; ac++) {
                var component = selectedData.address_components[ac];

                if (component.types.includes('locality')) {
                    Address[index].city = component.short_name;
                }
                if (component.types.includes('administrative_area_level_1')) {
                    Address[index].state = component.short_name;
                }
                if (component.types.includes('country')) {
                    Address[index].country = component.short_name;
                }
                if (component.types.includes('postal_code')) {
                    Address[index].zip_code = component.long_name;
                }
                if (Address[index].zip_code && component.types.includes('postal_code_suffix')) {
                    Address[index].zip_code = Address[index].zip_code + component.long_name;
                }
                if (component.types.includes('administrative_area_level_2')) {
                    Address[index].county = component.short_name;
                }
            }
            let Temp = selectedData.formatted_address.split(",")
            Address[index].address_lat = selectedData.geometry.location.lat();
            Address[index].address_lng = selectedData.geometry.location.lng();
            Address[index].address_line = selectedData.formatted_address;
            Address[index].short_address = Temp[0];
            await setisAddFlag(!isAddFlag)
            //console.log("Temp Array", Address, isAddFlag);
            if (await !isAddFlag) {
                addAddress(id,
                    type,
                    Address[index].short_address,
                    Address[index].city,
                    Address[index].state,
                    Address[index].zip_code,
                    Address[index].county,
                    Address[index].country, Address[index].address_line, Address[index].address_lat, Address[index].address_lng
                )
            }
        } else {
            alert('Please enter address');
        }
    }
    async function isValiEmail(val, id, type, evalue) {

        let Token = userinfo?.token
        let Patient_ID = userinfo?.uname
        if (val) {
            try {
                await fetch(`${BASEURL}/apipatient/public/v1/sendgridemailvalidate`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + `${Token}`,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "email": val,
                            "token": Token,
                            "patient_id": Patient_ID,
                        })
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                //console.log("IDSSS", responseJson)
                                if (responseJson.result.verdict == "Valid") {
                                    setInputemail(val)
                                    addEmail(id, type, evalue)
                                } else {
                                    toast.warn("Enter Valid Email")
                                }
                            });
                        } else {
                            //console.log("Res False12")
                        }
                    })
                    .catch((error) => {
                        //console.log("Fetch Error", error)
                    })
            } catch (error) {
                console.log('Try Error', error);
            }
        }
    }
    async function updateDetail(field, date) {

        let Patient_ID = userinfo?.uname
        let Token = userinfo?.token
        let formData = ""
        if (field === "first_name") {
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "first_name",
                "fieldvalue": FirstName,
                "field_type": "string",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
            updateDetail("patient_name")
            updateDetail("name")
        }
        if (field === "middle_name") {
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "middle_name",
                "fieldvalue": MiddleName,
                "field_type": "string",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
            updateDetail("patient_name")
            updateDetail("name")
        }
        if (field === "last_name") {
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "last_name",
                "fieldvalue": LastName,
                "field_type": "string",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
            updateDetail("patient_name")
            updateDetail("name")
        }
        if (field === "patient_name") {
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "patient_name",
                "fieldvalue": FirstName + " " + MiddleName + " " + LastName,
                "field_type": "string",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        if (field === "name") {
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "name",
                "fieldvalue": FirstName + " " + MiddleName + " " + LastName,
                "field_type": "string",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        if (field === "dob") {
            let datevalue3 = (new Date(`${date}`).getFullYear()) + "/" + (new Date(`${date}`).getMonth() + 1) + "/" + new Date(`${date}`).getDate()
            // let datevalue3 = (new Date(`${DOB}`).getMonth() + 1) + "/" + new Date(`${DOB}`).getDate() + "/" + new Date(`${DOB}`).getFullYear()
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "dob",
                "fieldvalue": `${datevalue3}T00:00:00`,
                "field_type": "date",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        if (field === "sex") {
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "sex",
                "fieldvalue": Sex,
                "field_type": "string",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        if (field === "race") {
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "race",
                "fieldvalue": Inputrace,
                "field_type": "string",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        if (field === "ethnicity") {
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "ethnicity",
                "fieldvalue": Inputethnicity,
                "field_type": "string",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        if (field === "religion") {
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "religion",
                "fieldvalue": Inputreligion,
                "field_type": "string",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        if (field === "language") {
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "language",
                "fieldvalue": Inputlanguage,
                "field_type": "string",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        if (field === "nationality") {
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "nationality",
                "fieldvalue": Inputnationality,
                "field_type": "string",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        if (field === "dr_lic") {
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "dr_lic",
                "fieldvalue": DrivingLic,
                "field_type": "string",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        if (field === "ssn") {
            formData = JSON.stringify({
                "tablename": "Patients",
                "tableid": Patient_ID,
                "fieldname": "ssn",
                "fieldvalue": SSN,
                "field_type": "string",
                "isencrypt": false,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        //console.log("Formdata", formData)
        try {
            setisLoading(true)
            await fetch(`${BASEURL}/apipatient/public/v1/editfieldbytype`,
                {
                    method: 'POST',
                    headers: {
                        // Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: formData,
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log(`Get Response ${field}`, responseJson);
                            setisLoading(false)
                            getInfo()
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function addEmail(id, type, evalue) {

        let Patient_ID = userinfo?.uname
        let Token = userinfo?.token
        if (id && type) {
            //console.log("Insidee Edit", id, type, evalue);
            try {
                setisLoading(true)
                await fetch(`${BASEURL}/apipatient/public/v1/createandupdatepatientemails`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "id": id,
                            "patient_id": Patient_ID,
                            "contact_type": Inputemailtype ? Inputemailtype : type,
                            "contact_value": Inputemail ? Inputemail : evalue,
                            "contact_primary": false,
                            "primary_email": Userinfo.primary_email,
                            "token": Token,
                            "patient_id": Patient_ID,
                        }),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                //console.log(`Get Response ${id}`, responseJson);
                                setisLoading(false)
                                getInfo()
                            });
                        } else {
                            //console.log("Res False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
        if (!id && !type) {
            //console.log("Insidee New", id, type, evalue);
            try {
                setisLoading(true)
                await fetch(`${BASEURL}/apipatient/public/v1/createandupdatepatientemails`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "id": "",
                            "patient_id": Patient_ID,
                            "contact_type": Inputemailtype ? Inputemailtype : "1",
                            "contact_value": Inputemail,
                            "contact_primary": false,
                            "primary_email": Userinfo.primary_email,
                            "token": Token,
                            "patient_id": Patient_ID,
                        }),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                //console.log(`Get Response ${id}`, responseJson);
                                setisLoading(false)
                                getInfo()
                            });
                        } else {
                            //console.log("Res False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
    }
    async function addContact(id, type, evalue) {

        let Patient_ID = userinfo?.uname
        let Token = userinfo?.token
        if (id && type) {
            //console.log("Insidee Edit", id, type, evalue);
            try {
                setisLoading(true)
                await fetch(`${BASEURL}/apipatient/public/v1/createandupdatepatientphones`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "id": id,
                            "patient_id": Patient_ID,
                            "contact_type": Inputcontacttype ? Inputcontacttype : type,
                            "contact_value": Inputcontact ? Inputcontact : evalue,
                            "token": Token,
                            "patient_id": Patient_ID,
                        }),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                setisLoading(false)
                                getInfo()
                                //console.log(`Get Response ${id}`, responseJson);
                            });
                        } else {
                            //console.log("Res False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
        if (!id && !type) {
            //console.log("Insidee New", id, type, evalue);
            try {
                setisLoading(true)
                await fetch(`${BASEURL}/apipatient/public/v1/createandupdatepatientphones`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "id": "",
                            "patient_id": Patient_ID,
                            "contact_type": Inputcontacttype ? Inputcontacttype : "1",
                            "contact_value": Inputcontact ? Inputcontact : evalue,
                            "token": Token,
                            "patient_id": Patient_ID,
                        }),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                setisLoading(false)
                                getInfo()
                                //console.log(`Get Response ${id}`, responseJson);
                            });
                        } else {
                            //console.log("Res False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
    }
    async function addAddress(id, type, evalue, city, state, zip, county, country, add_line, lat, lng) {

        let Patient_ID = userinfo?.uname
        let Token = userinfo?.token
        //console.log("First Console Log", id, type)
        if (id && type) {
            //console.log("Insidee Edit", id, type, evalue, city, state, zip, county, country, add_line, lat, lng);
            try {
                setisLoading(true)
                await fetch(`${BASEURL}/apipatient/public/v1/createandupdatepatientaddress`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "id": id,
                            "parent_type": "3",
                            "parent_id": Patient_ID,
                            "address_type": Inputaddresstype ? Inputaddresstype : type,
                            "short_address": evalue,
                            "address_line": add_line,
                            "city": city,
                            "state": state,
                            "county": county,
                            "country": country,
                            "zip_code": zip,
                            "address_lat": lat,
                            "address_lng": lng,
                            "token": Token,
                            "patient_id": Patient_ID,
                        }),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                setisLoading(false)
                                getInfo()
                                //console.log(`Get Response ${id}`, responseJson);
                            });
                        } else {
                            //console.log("Res False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
        if (!id && !type) {
            //console.log("Insidee New", id, type, evalue, city, state, zip, county, country, add_line, lat, lng);
            try {
                setisLoading(true)
                await fetch(`${BASEURL}/apipatient/public/v1/createandupdatepatientaddress`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "id": "",
                            "parent_type": "3",
                            "parent_id": Patient_ID,
                            "address_type": Inputaddresstype ? Inputaddresstype : "1",
                            "short_address": evalue,
                            "address_line": add_line,
                            "city": city,
                            "state": state,
                            "county": county,
                            "country": country,
                            "zip_code": zip,
                            "address_lat": lat,
                            "address_lng": lng,
                            "token": Token,
                            "patient_id": Patient_ID,
                        }),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                //console.log(`Get Response ${id}`, responseJson);
                                setisLoading(false)
                                getInfo()
                            });
                        } else {
                            //console.log("Res False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
    }
    function handleChanges(event) {
        let data = event.target.value.replace(/[^0-9]/g, '')
        setSSN(data)
        //console.log("fieldValue:", data);
    }

    //Functions For Tab 2
    function handleClick(type) {

        if (type === "INS") {
            setFlag(0)
            ref.current.value = ""
            setimageUrl(null)
            setisPDFins(false)
        }
        if (type === "UPLOAD") {
            setFlag3(0)
            UPLOAD.current.value = ""
            setimageUPLOAD(null)
            setisPDFUPLOAD(false)
        }
    }
    const handleFileSelect = (event, type) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                const base64String = e.target.result;
                //console.log(base64String); // This is your base64 representation of the file
                setotherDoc(base64String)
                if (type === "UPLOAD") {
                    //console.log("UPLOAD")
                    setdocfile(base64String)
                }
            };
        }
    }
    async function getInsurance() {

        let Patient_ID = userinfo?.uname
        setisLoading(true)
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/getallpatientdocument/` + Patient_ID,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            if (responseJson.data) {
                                setInsuranceCard(responseJson.data)
                                let arr = responseJson.data.filter(item => item.type === 0)
                                arr = arr.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
                                arr = arr.slice(0, 2);
                                setphotoid(arr)
                                setisLoading(false)
                            }
                            //console.log("IDS for documents", InsuranceCard)
                        });
                    } else {
                        //console.log("Res False12")
                    }
                })
                .catch((error) => {
                    //console.log("Fetch Error", error)
                })
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function addInsurance() {
        setisLoading(true)
        let Patient_ID = userinfo?.uname
        let Token = userinfo?.token
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/createpatientdocument`,
                {
                    method: 'POST',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "patient_id": Patient_ID,
                        "type": doctype.toString(),
                        "document_file": otherDoc,
                        "token": Token,
                        "patient_id": Patient_ID,
                    }),
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            if (responseJson.data) {
                                //console.log("Dataaaa OF Insurance Image data upload", responseJson)
                                getInsurance()
                                setisLoading(false)
                                setisNewInsCard(!isNewInsCard)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function cardDocument(doctype) {
        setisNewInsCard(true)
        setimageUrl(null)
        setselectedImage(null)
        setisPDFins(false)
        setFlag(0)
        setdoctype(doctype)
    }

    //Functions For Tab 3
    function getData() {
        setisOpen(!isOpen)
        getInfo()
    }
    const toggle = async (type, id) => {
        setisOpen(!false)
        if (type === "new") {
            localStorage.setItem("isStepOne", "New")
            dispatch(
                setinsuranceInfo([])
            )
            return false;
        }
        if (type === "edit") {
            if (id) {
                let Demo = ""
                InsuranceApi.map((item) => {
                    if (id === item.id) {
                        Demo = item
                    }
                })
                //console.log("Datatatat", Demo)
                let datestring = Demo?.dob.split("/")
                let datevalue = ""
                if (datestring) {
                    // datevalue = datestring[1] + "/" + datestring[0] + "/" + datestring[2]
                    datevalue = datestring[2] + "/" + datestring[0] + "/" + datestring[1]
                }
                localStorage.setItem("isStepOne", "Edit")
                dispatch(
                    setinsuranceInfo([{
                        id: id,
                        patient_id: Demo.patient_id,
                        // are_you: Demo.are_you,
                        first_name: Demo.first_name,
                        middle_name: Demo.middle_name,
                        last_name: Demo.last_name,
                        date_birth: datevalue,
                        ssn_num: Demo.ssn,
                        gender: Demo.gender,
                        relation_with: Demo.relation,
                        insurance_type: Demo.insurances.data[0].insurance_type,
                        selected_insurance: Demo.insurance_name,
                        insurance_id: Demo.insurance_id,
                        member_id: Demo.insurance_policy,
                        group: Demo.insurance_group,
                        service: Demo.insurance_code,
                        effective_from: Demo.start_date_time,
                        insurance_document_id: Demo.insurance_document_id,
                        end_date: Demo.end_date_time
                    }])
                )
            }
            return false;
        }
    }
    async function reActive(id, date) {
        if (date && id) {
            let datestring = date?.split("/")
            let datevalue = ""
            if (datestring) {
                datevalue = datestring[0] + "/" + datestring[1] + "/" + datestring[2]
                setendDate(new Date(`${datevalue}`))
            }
            setisID(id)
            setisActive(true)
        }
        if (id && !date) {
            setendDate("")
            setisID(id)
            setisActive(true)
        }
    }
    async function expiry() {
        setisLoading(true)
        let Patient_ID = userinfo?.uname
        let Token = userinfo?.token
        if (!endDate) {
            toast.warn("Select End Date")
            //console.log("Date of effectice Ending One", endDate)
            return false;
        } else {
            //console.log("End Date", JSON.stringify({
            // "id": isID,
            //     "patient_id": Patient_ID,
            //         "status": 0,
            //             "end_date_time": new Date(`${endDate}`).getFullYear() + "/" + (new Date(`${endDate}`).getMonth() + 1) + "/" + new Date(`${endDate}`).getDate(),
            //                 "token": Token,
            //                     "patient_id": Patient_ID,
            // }))
            try {
                await fetch(`${BASEURL}/apipatient/public/v1/updatepatientinsurancesstatus`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + `${Token}`,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "id": isID,
                            "patient_id": Patient_ID,
                            "status": 0,
                            "end_date_time": new Date(`${endDate}`).getFullYear() + "/" + (new Date(`${endDate}`).getMonth() + 1) + "/" + new Date(`${endDate}`).getDate(),
                            "token": Token,
                            "patient_id": Patient_ID,
                        }),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                if (responseJson.data) {
                                    setisLoading(false)
                                    setisID("")
                                    setisActive(!isActive)
                                    getInfo()
                                }
                            });
                        } else {
                            //console.log("Res False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
    }
    async function checkCard(id, doc, insid, insname) {

        //console.log("id", id, "doc", doc, "insid", insid)
        setLinkDocName(insname)
        if (id && doc) {
            // setisCard(!isCard)
            try {
                await fetch(`${BASEURL}/apipatient/public/v1/showpatientprivatedocument/${doc}`,
                    {
                        method: 'GET',
                        headers: {
                            // Authorization: 'Bearer ' + `${Token}`,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },

                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                if (responseJson.document_url) {
                                    setisCard(!isCard)
                                    setisID(id)
                                    setdocFlag(responseJson.document_url)

                                    return true;
                                }
                            });
                        } else {
                            //console.log("Res False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
        if (id) {
            setselectedImage3(null)
            setFlag3(0)
            setisPDFUPLOAD(false)
            setimageUPLOAD(null)
            setisCard(!isCard)
            setdocFlag("")
            setisID(id)
        }
    }
    async function linkDoc(type, id, url, docid) {

        let Patient_ID = userinfo?.uname
        let Token = userinfo?.token
        setisLoading(!isLoading)
        let formdata = ""
        if (type === "edit") {
            formdata = JSON.stringify({
                "patient_id": Patient_ID,
                "type": "1",
                "patient_insurances_id": isID,
                "document_file": docfile,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        if (type === "new") {
            formdata = JSON.stringify({
                "patient_id": Patient_ID,
                "type": "1",
                "patient_insurances_id": id,
                "document_id": docid,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        if (type === "editTab3") {
            formdata = JSON.stringify({
                "patient_id": Patient_ID,
                "type": "1",
                "patient_insurances_id": isID,
                "document_id": id,
                "token": Token,
                "patient_id": Patient_ID,
            })
        }
        //console.log("Form Data", "type", type, formdata)
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/createandupdatepatienticard`,
                {
                    method: 'POST',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: formdata,
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            if (responseJson.data) {
                                //console.log("insyeance Edit buy item ", responseJson)
                                type === "edit" && setisCard(!isCard)
                                setdocFlag("")
                                setisID("")
                                setisOption(false)

                                getInsurance()
                                getInfo()
                            }
                        });
                    } else {
                        setisOption(false)
                        setisLoading(false)
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function makePrimary(id) {

        let Patient_ID = userinfo?.uname
        let Token = userinfo?.token
        setisLoading(!isLoading)
        //console.log("Form Data", "type")
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/updateprimarypatientinsurance`,
                {
                    method: 'POST',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "id": id,
                        "patient_id": Patient_ID,
                        "token": Token
                    }),
                })
                .then((response) => {
                    if (response.status === 500) {
                        response.json().then(async (responseJson) => {
                            if (responseJson.message == "Token Expired!") {
                                const keysToRemove = ['TenantID', 'isStepOne', 'Whoare', 'Listdata'];
                                for (const key of keysToRemove) {
                                    await localStorage.removeItem(`${key}`);
                                }
                                toast.warn("Session Expired !!")
                                navigate(process.env.PUBLIC_URL + `/${props.Labcode}`);
                            }
                        });
                    }
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            if (responseJson) {
                                getInsurance()
                                getInfo()
                                setprimaryinsModal(false)
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function makePrimaryModal(id, name) {
        if (id) {
            setprimaryinsModal(true)
            setprimaryinsID(id)
            setprimaryinsName(name)
        }
    }
    let root = document?.documentElement;
    let color1 = root?.style?.getPropertyValue('--primary-color',);
    let color2 = root?.style?.getPropertyValue('--secoundry-color');
    return (
        <>
            <div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    color
                />
            </div>
            <LoaderComp isLoading={isLoading} color1={color1} color2={color2} />
            <Modal
                zIndex={999}
                isOpen={isNewInsCard}
                backdropTransition={{ timeout: 100 }}
                toggle={() => setisNewInsCard(false)}
                centered={true}
                size="md"
            >
                <ModalHeader toggle={() => setisNewInsCard(false)}>Add New Document</ModalHeader>
                <ModalBody>
                    <div className="col-12 patients-sidebar mb-2 p-1 " data-aos="fade-in">
                        <div className="card">
                            <div className="card-footer p-2"><strong>Upload Document Card</strong></div>
                            <div className="card-body p-3">
                                <Row>
                                    <Col md="12" sm="12" xs="12">
                                        <label for="" className="drop-container">
                                            {(imageUrl && !isPDFins) &&
                                                <img className="wimg" src={imageUrl} alt={selectedImage?.name} />
                                            }
                                            {isPDFins &&
                                                <>
                                                    <p>{selectedImage?.name}</p>
                                                    <object
                                                        className="data-object"
                                                        data={imageUrl}
                                                        type="application/pdf"
                                                        width="100%"
                                                        height="380px"
                                                        style={{ alignContent: 'center' }}>
                                                        <p>
                                                            <a href={imageUrl} target="_blank">Link to the attached PDF!</a>
                                                        </p>
                                                    </object>
                                                </>
                                            }
                                            {!imageUrl &&
                                                <>
                                                    <span className="drop-title">Drop Files Here</span>
                                                    <span className="fw-bold">OR</span>
                                                </>
                                            }
                                            {imageUrl &&
                                                <button
                                                    className="btn btn-sm ms-2 btn-danger imgicon"
                                                    onClick={() => handleClick("INS")}>
                                                    <i className="fa fa-times" style={{ zIndex: 99 }}></i>
                                                </button>
                                            }
                                            <input ref={ref}
                                                onChange={(e) => {
                                                    handleFileSelect(e)
                                                    if (e.target.files[0].type === "application/pdf") {
                                                        setisPDFins(true)
                                                        setselectedImage(e.target.files[0])
                                                        setFlag(Flag + 1)
                                                    }
                                                    else {
                                                        setisPDFins(false)
                                                        setselectedImage(e.target.files[0])
                                                        setFlag(Flag + 1)
                                                    }
                                                }}
                                                type="file"
                                                id="images"
                                                accept="image/jpeg,image/png,application/pdf"
                                            />
                                        </label>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <button
                            disabled={isLoading}
                            onClick={() => addInsurance()}
                            className="btn btn-inshover float-right px-5 mt-3">Submit</button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal
                zIndex={999}
                isOpen={isActive}
                backdropTransition={{ timeout: 100 }}
                toggle={() => setisActive(false)}
                centered={true}
                size="md"
            >
                <ModalHeader toggle={() => setisActive(false)}>Coverage Effective untill</ModalHeader>
                <ModalBody>
                    <div className="col-12 patients-sidebar mb-2 p-1 " data-aos="fade-in">
                        <div className="form-group">
                            <label for="dob">End Date</label>
                            {/* <DatePicker
                                dateFormat="MM-dd-yyyy"
                                className="form-control"
                                selected={endDate}
                                placeholderText="MM-DD-YYYY"
                                onChange={(date) => {
                                    //console.log("Date", date)
                                    setendDate(date);
                                }} /> */}

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={[
                                    'DatePicker',
                                    'MobileDatePicker',
                                    'DesktopDatePicker',
                                    'StaticDatePicker',
                                ]}>
                                    <DemoItem>
                                        <MobileDatePicker
                                            views={['year', 'month', 'day']}
                                            onAccept={async (e) => {
                                                if (e.$d == "Invalid Date") {
                                                    toast.warn("Select End Date")
                                                }
                                                await setendDate(e.$d.toString())
                                            }}
                                            className="customDatePickerClass"
                                            defaultValue={endDate && dayjs(`${endDate}`)}
                                        />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        <button
                            disabled={isLoading}
                            className="btn btn-inshover float-right px-5 mt-3"
                            onClick={() => expiry()}
                        >Submit</button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal
                zIndex={999}
                isOpen={isCard}
                backdropTransition={{ timeout: 100 }}
                toggle={() => setisCard(false)}
            >
                <ModalHeader toggle={() => setisCard(false)}>{LinkDocName}</ModalHeader>
                <ModalBody>
                    {!docFlag &&
                        <>
                            <div className="col-12 patients-sidebar mb-2 p-1 " data-aos="fade-in">
                                <div className="card">
                                    <div className="card-footer p-2"><strong>Your Document is Not Linked !!</strong></div>
                                    <div className="card-body p-3">
                                        <div className="">
                                            <label>Not Is Linked!</label>
                                            <select
                                                // onClick={(e) => idofimage = e.target.value}
                                                // onBlur={() => linkDoc("editTab3", idofimage)}
                                                onBlur={(e) => linkDoc("editTab3", e.target.value)}
                                                style={{ borderColor: "#d3d3d3", borderRadius: 5 }}
                                                className="custom-select custom-select-lg py-2 px-0 w-100 cellbtn">
                                                {InsuranceCard.map((data) => {
                                                    if (data.type === 1) {
                                                        let flag = false
                                                        return (
                                                            <>
                                                                {InsuranceApi.map((item) => {
                                                                    if (data.id == item.insurance_document_id) {
                                                                        flag = true;
                                                                        return (
                                                                            <></>
                                                                        )
                                                                    }
                                                                })}
                                                                {!flag &&
                                                                    <option value={data.id}>{data.id}</option>
                                                                }
                                                            </>
                                                        )
                                                    }
                                                })}
                                            </select>
                                        </div>
                                        <Row>
                                            <Col md="12" sm="12" xs="12">
                                                <label for="" className="drop-container">
                                                    {(imageUPLOAD && !isPDFUPLOAD) &&
                                                        <img className="wimg" src={imageUPLOAD} alt={selectedImage3.name} />
                                                    }
                                                    {isPDFUPLOAD &&
                                                        <>
                                                            <p>{selectedImage3.name}</p>
                                                            <object
                                                                className="data-object"
                                                                data={imageUPLOAD}
                                                                type="application/pdf"
                                                                width="100%"
                                                                height="380px"
                                                                style={{ alignContent: 'center' }}>
                                                                <p>
                                                                    <a href={imageUPLOAD} target="_blank">Link to the attached PDF!</a>
                                                                </p>
                                                            </object>
                                                        </>
                                                    }
                                                    {!imageUPLOAD &&
                                                        <>
                                                            <span className="drop-title">Drop Files Here</span>
                                                            <span className="fw-bold">OR</span>
                                                        </>
                                                    }
                                                    {imageUPLOAD &&
                                                        <button
                                                            className="btn btn-sm ms-2 btn-danger imgicon"
                                                            onClick={() => handleClick("UPLOAD")}>
                                                            <i className="fa fa-times" style={{ zIndex: 99 }}></i>
                                                        </button>
                                                    }
                                                    <input ref={UPLOAD}
                                                        onChange={(e) => {
                                                            handleFileSelect(e, "UPLOAD")
                                                            if (e.target.files[0].type === "application/pdf") {
                                                                setisPDFUPLOAD(true)
                                                                setselectedImage3(e.target.files[0])
                                                                setFlag3(Flag3 + 1)
                                                            }
                                                            else {
                                                                setisPDFUPLOAD(false)
                                                                setselectedImage3(e.target.files[0])
                                                                setFlag3(Flag3 + 1)
                                                            }
                                                        }}
                                                        type="file"
                                                        id="images"
                                                        accept="image/jpeg,image/png,application/pdf" />
                                                </label>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className="col-12 patients-sidebar mb-2 p-1 " data-aos="fade-in">
                                    <button className="btn btn-inshover float-right px-5 mt-3" onClick={() => linkDoc("edit")}>Submit</button>
                                </div>
                            </div>
                        </>}
                    {docFlag &&
                        <>
                            {docFlag.includes(".pdf") == false &&
                                <img className="wimg" alt="image-7" src={docFlag} />
                            }
                            {docFlag.includes(".pdf") &&
                                <>
                                    <object
                                        className="data-object"
                                        data={docFlag}
                                        type="application/pdf"
                                        width="100%"
                                        height="380px"
                                        style={{ alignContent: 'center' }}>
                                        <p>
                                            <a href={docFlag} target="_blank">Link to the attached PDF!</a>
                                        </p>
                                    </object>
                                </>
                            }
                            <div className="col-12 patients-sidebar mb-2 p-1" data-aos="fade-in">

                                <button className="btn btn-inshover float-right px-5 mt-3" onClick={() => setisCard(false)}>Okay</button>
                            </div>
                        </>
                    }
                </ModalBody>
            </Modal >
            <Modal
                isOpen={primaryinsModal}
                modalTransition={{ timeout: 200 }}
                backdropTransition={{ timeout: 200 }}
                toggle={() => setprimaryinsModal(false)}
            >
                <ModalHeader toggle={() => setprimaryinsModal(false)}>Make Insurance Primary.</ModalHeader>
                <ModalBody>
                    <p className="fw-bold">Is {primaryinsName} your Primary Insurance ?</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => makePrimary(primaryinsID)}>
                        Yes
                    </Button>{' '}
                    <Button color="secondary" onClick={() => setprimaryinsModal(false)}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>
            <div className="overflow-x-hidden main-container" style={{ backgroundColor: "#f3f3f3" }}>
                <div className="row mt-2 ms-md-5 ms-2" >
                    <div className="mx-sm-1 p-0">
                        <h4 className="thmclr">{`Welcome , ${Userinfo?.first_name !== undefined ? Userinfo?.first_name : ""} ${Userinfo?.last_name !== undefined ? Userinfo?.last_name : ""}`}</h4>
                    </div>
                </div>
                <div className="container p-2 ">
                    <Breadcrumb className="my-md-2 mx-md-3 px-1 py-0 colorfor">
                        <BreadcrumbItem
                            href={process.env.PUBLIC_URL + `/${props.Labcode}/dashboard`}
                            tag="a"
                        >
                            Dashboard
                        </BreadcrumbItem>
                        {Tabid === "1" &&
                            <BreadcrumbItem
                                onClick={() => TabContant("1")}
                                href="#"
                                tag="a"
                                active={Active1}
                            >
                                My Profile
                            </BreadcrumbItem>
                        }
                        {Tabid === "2" &&
                            <BreadcrumbItem
                                onClick={() => TabContant("2")}
                                active={Active2}
                                tag="a"
                                href="#"
                            >
                                My Documents
                            </BreadcrumbItem>
                        }
                        {Tabid === "3" &&
                            <BreadcrumbItem
                                onClick={() => TabContant("3")}
                                active={Active3}
                                tag="a"
                                href="#"
                            >
                                My Insurance
                            </BreadcrumbItem>
                        }
                    </Breadcrumb>
                    <Row>
                        <Col xs="12" md="12" className="px-4">
                            <Nav tabs={true} >
                                < NavItem >
                                    <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={`${Active1} p-1`}
                                        onClick={() => TabContant("1")}
                                    >
                                        My Profile
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={`${Active3} p-1`}
                                        onClick={() => TabContant("3")}
                                    >
                                        My Insurance
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: 'pointer' }}
                                        className={`${Active2} p-1`}
                                        onClick={() => TabContant("2")}
                                    >
                                        My Documents
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                    <TabContent className="p-3" activeTab={Tabid}>
                        {/* //User Profile */}
                        <TabPane tabId="1">
                            <form >
                                <div className="card patients-sidebar mb-2" >
                                    <div className="card-footer p-2"><strong>Personal Info</strong></div>
                                    <div className="card-body p-3">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-4 p-2">
                                                <div className="form-group">
                                                    <label for="first_name">First Name</label>
                                                    <input
                                                        onBlur={(e) => {
                                                            if (e.target.value == "") {
                                                                toast.error("Please Enter First Name")
                                                            }
                                                            else {
                                                                //console.log("Length", FirstName.length)
                                                                updateDetail("first_name")
                                                            }
                                                        }}
                                                        // onBlur={() => updateDetail("first_name")}
                                                        onChange={(e) => setFirstName(e.target.value)}
                                                        defaultValue={Userinfo.first_name}
                                                        type="text"
                                                        autoComplete="nope"
                                                        className="form-control"
                                                        id="first_name"
                                                        name="first_name" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 p-2">
                                                <div className="form-group">
                                                    <label for="middle_name">Middle Name</label>
                                                    <input
                                                        onBlur={() => updateDetail("middle_name")}
                                                        onChange={(e) => setMiddleName(e.target.value)}
                                                        defaultValue={Userinfo.middle_name}
                                                        type="text"
                                                        autoComplete="nope"
                                                        className="form-control"
                                                        id="middle_name"
                                                        name="middle_name" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 p-2">
                                                <div className="form-group">
                                                    <label for="last_name">Last Name</label>
                                                    <input
                                                        onBlur={(e) => {
                                                            if (e.target.value == "") {
                                                                toast.error("Please Enter Last Name")
                                                            }
                                                            else {
                                                                updateDetail("last_name")
                                                            }
                                                        }}
                                                        // onBlur={() => updateDetail("last_name")}
                                                        onChange={(e) => setLastName(e.target.value)}
                                                        defaultValue={Userinfo.last_name}
                                                        type="text"
                                                        autoComplete="nope"
                                                        className="form-control"
                                                        id="last_name"
                                                        name="last_name" />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 p-2">
                                                <div className="form-group">
                                                    <label for="dob">Date Of Birth</label>
                                                    {/* <DatePicker
                                                        onBlur={() => updateDetail("dob")}
                                                        dateFormat="MM-dd-yyyy"
                                                        className="form-control"
                                                        selected={DOB && new Date(`${DOB}`)}
                                                        onChange={(date) => setDOB(date)} /> */}

                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={[
                                                            'DatePicker',
                                                            'MobileDatePicker',
                                                            'DesktopDatePicker',
                                                            'StaticDatePicker',
                                                        ]}>
                                                            <DemoItem>
                                                                <MobileDatePicker
                                                                    views={['year', 'month', 'day']}
                                                                    onAccept={async (e) => {
                                                                        await setDOB(e.$d.toString())
                                                                        updateDetail('dob', e.$d)
                                                                    }}
                                                                    className="customDatePickerClass"
                                                                    value={dayjs(`${DOB}`)}
                                                                />
                                                            </DemoItem>
                                                        </DemoContainer>
                                                    </LocalizationProvider>

                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 p-2">
                                                <div className="form-group">
                                                    <label for="sex">Gender</label>
                                                    <select
                                                        onBlur={() => updateDetail("sex")}
                                                        onChange={(e) => setSex(e.target.value)}
                                                        value={Sex}
                                                        style={{ borderColor: "#d3d3d3", }}
                                                        className="form-select">
                                                        <option>Select Gender</option>
                                                        {Gender.map((item) => {
                                                            return (
                                                                <option value={item.key}>{item.value}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 p-2">
                                                <div className="form-group">
                                                    <label for="ssn">SSN</label>
                                                    <InputGroup >
                                                        <Input
                                                            autoComplete="nope"
                                                            className="form-control"
                                                            type={view ? "hidden" : "text"}
                                                            value={'****-**-' + SSN?.substring(SSN?.length - 4)}
                                                            disabled
                                                        />
                                                        <InputMask
                                                            onBlur={() => {
                                                                if (SSN.length < 9) {
                                                                    toast.error("Please Enter Valid SSN Number")
                                                                }
                                                                else {
                                                                    updateDetail("ssn")
                                                                }
                                                            }}
                                                            // onBlur={() => updateDetail("ssn")}
                                                            type={view ? "text" : "hidden"}
                                                            value={SSN}
                                                            className="form-control"
                                                            mask={"999-99-9999"}
                                                            maskChar=""
                                                            alwaysShowMask={true}
                                                            id="ssn"
                                                            name="ssn"
                                                            onChange={(e) => handleChanges(e)}
                                                        />
                                                        <Button onClick={() => setview(!view)}>
                                                            <i onClick={() => setview(false)} className={view ? "fas fa-eye" : "far fa-eye-slash"}></i>
                                                        </Button>
                                                    </InputGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card patients-sidebar mb-2" >
                                    <div className="card-footer p-2 position-relative"><strong>Contact Details</strong></div>
                                    <div className="card-body p-3">
                                        <div className="card patients-sidebar mb-2 pt-0 pb-2">
                                            {ContactNum.map((item, index) => {
                                                return (
                                                    <>
                                                        <div className="container-fluid">
                                                            <Row className="p-0 position-relative">
                                                                <div className="Test text-danger">
                                                                    {/* <i className="fa fa-minus-circle" onClick={() => ContactDetail(index, "-", item.id)}></i> */}
                                                                    <i className="fa fa-minus-circle"
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            if (window.confirm("Are You sure you want to delete ?")) {
                                                                                ContactDetail(index, "-", item.id)
                                                                                //console.log('true');
                                                                            } else {
                                                                                //console.log('false');
                                                                            }
                                                                            // let res = confirm("datatat onm clik of apply")
                                                                        }}></i>
                                                                </div>
                                                                <Col xs="5" md="2" className="p-1 ps-4">
                                                                    <div className="form-group ">
                                                                        <select
                                                                            onBlur={() => item.id && addContact(item.id, item.contact_type, item.contact_value)}
                                                                            // onBlur={() => {
                                                                            //     const match = ContactNum.find(
                                                                            //         (item) => item.contact_type == Inputcontacttype
                                                                            //     )
                                                                            //     if (!match) {
                                                                            //         item.id && addContact(item.id, item.contact_type, item.contact_value)
                                                                            //     } else {
                                                                            //         window.confirm("Tou Can not Add Same Type of Contact Number")
                                                                            //         getInfo()
                                                                            //         return false;
                                                                            //     }
                                                                            // }}
                                                                            onChange={(e) => setInputcontacttype(e.target.value)}
                                                                            defaultValue={item.contact_type}
                                                                            style={{ borderColor: "#d3d3d3", borderRadius: 5 }}
                                                                            className="custom-select custom-select-lg py-2 px-0 w-100 cellbtn">
                                                                            {PhoneType.map((item) => {
                                                                                return (
                                                                                    <option value={item.key}>{item.value}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="7" md="6" className="p-1">
                                                                    <InputMask
                                                                        // onBlur={() => {
                                                                        //     if (Inputcontact.length < 10) {
                                                                        //         toast.error("Please Enter Valid Contact Number")
                                                                        //     }
                                                                        //     else {
                                                                        //         addContact(item.id, item.contact_type, item.contact_value)
                                                                        //     }
                                                                        // }}

                                                                        onBlur={(e) => {
                                                                            console.log("eee valueeeee", Inputcontact)
                                                                            if (Inputcontact.length < 10) {
                                                                                toast.error("Please Enter Valid Contact Number")
                                                                            }
                                                                            else {
                                                                                const matchemail = ContactNum.find(
                                                                                    (item) => item.contact_value == Inputcontact
                                                                                )

                                                                                if (matchemail) {
                                                                                    window.confirm("You can not add Same Contact Number")
                                                                                    getInfo()
                                                                                    return false;
                                                                                } else {
                                                                                    addContact(item.id, item.contact_type, item.contact_value)
                                                                                }
                                                                            }
                                                                        }}

                                                                        // onBlur={() => {
                                                                        //     const match = ContactNum.find(
                                                                        //         (item) => item.contact_type == Inputcontacttype
                                                                        //     )
                                                                        //     const matchnumber = ContactNum.find(
                                                                        //         (item) => item.contact_value == Inputcontact
                                                                        //     )
                                                                        //     //console.log("match", match, Inputcontacttype)
                                                                        //     if (Inputcontact.length < 10) {
                                                                        //         toast.error("Please Enter Valid Contact Number")
                                                                        //     }
                                                                        //     else if (!match && !Inputcontacttype) {
                                                                        //         window.confirm("You can not add Same type and Same ContactNumber")
                                                                        //         getInfo()
                                                                        //         return false;
                                                                        //     }
                                                                        //     else if (match) {
                                                                        //         window.confirm("You can not add Same type and Same ContactNumber")
                                                                        //         getInfo()
                                                                        //         return false;
                                                                        //     }
                                                                        //     else if (matchnumber) {
                                                                        //         window.confirm("You can not add Same type and Same ContactNumber")
                                                                        //         getInfo()
                                                                        //         return false;
                                                                        //     }
                                                                        //     else {
                                                                        //         addContact(item.id, item.contact_type, item.contact_value)
                                                                        //     }
                                                                        // }}
                                                                        // onBlur={() => addContact(item.id, item.contact_type, item.contact_value)}
                                                                        defaultValue={item.contact_value}
                                                                        className="inBorder form-control px-0"
                                                                        mask={"(999)-999-9999"}
                                                                        maskChar=""
                                                                        alwaysShowMask={true}
                                                                        id="ssn"
                                                                        name="ssn"
                                                                        onChange={(e) => setInputcontact(e.target.value.replace(/[^0-9]/g, ''))}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <hr style={{ width: "99%", alignSelf: 'center' }} className="my-1" />
                                                    </>
                                                )
                                            })}
                                            <div className="container-fluid">
                                                <Row className="ps-4 position-relative">
                                                    <div className="Test text-success">
                                                        <i className="fa fa-plus-circle " style={{ cursor: 'pointer' }} onClick={() => ContactDetail(Index + 1, "+")}></i>
                                                    </div>
                                                    <Col
                                                        onClick={() => ContactDetail(Index + 1, "+")}
                                                        md="11" xs="10"
                                                        className="fw-bold text-success justify-content-center"
                                                        style={{ paddingTop: "7px", cursor: 'pointer' }}>
                                                        Add Phone
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="card patients-sidebar mb-2 pt-0 pb-2" >
                                            {EmailId.map((item, index) => {
                                                return (
                                                    <>
                                                        <div className="container-fluid">
                                                            <Row className="p-0 position-relative" >
                                                                <div className="Test text-danger">
                                                                    {/* <i className="fa fa-minus-circle " onClick={() => EmailDetail(index, "-", item.id)}></i> */}
                                                                    <i className="fa fa-minus-circle"
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            if (window.confirm("Are You sure you want to delete ?")) {
                                                                                EmailDetail(index, "-", item.id)
                                                                                //console.log('true');
                                                                            } else {
                                                                                //console.log('false');
                                                                            }
                                                                            // let res = confirm("datatat onm clik of apply")
                                                                        }}></i>
                                                                </div>
                                                                <Col xs="5" md="2" className="p-1 ps-4">
                                                                    <div className="form-group ">
                                                                        <select
                                                                            onBlur={() => item.id && addEmail(item.id, item.contact_type, item.contact_value)}
                                                                            // onBlur={() => {
                                                                            //     const match = EmailId.find(
                                                                            //         (item) => item.contact_type == Inputemailtype
                                                                            //     )
                                                                            //     if (!match) {
                                                                            //         item.id && addEmail(item.id, item.contact_type, item.contact_value)
                                                                            //     } else {
                                                                            //         window.confirm("Tou Can not Add Same Type of Email")
                                                                            //         getInfo()
                                                                            //         return false;
                                                                            //     }
                                                                            // }}
                                                                            onChange={(e) => setInputemailtype(e.target.value)}
                                                                            defaultValue={item.contact_type}
                                                                            style={{ borderColor: "#d3d3d3", borderRadius: 5 }}
                                                                            className="custom-select custom-select-lg py-2 px-0 w-100 cellbtn">
                                                                            {EmailType.map((item) => {
                                                                                return (
                                                                                    <option value={item.key}>{item.value}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                                <Col xs="7" md="6" className="p-1">
                                                                    <Input
                                                                        onChange={(e) => setInputemail(e.target.value)}
                                                                        defaultValue={item.contact_value}
                                                                        // onBlur={(e) => isValiEmail(e.target.value, item.id, item.contact_type, item.contact_value)}
                                                                        onBlur={(e) => {
                                                                            const matchemail = EmailId.find(
                                                                                (item) => item.contact_value == e.target.value
                                                                            )

                                                                            if (matchemail) {
                                                                                window.confirm("You can not add Same Email Id")
                                                                                getInfo()
                                                                                return false;
                                                                            } else {
                                                                                isValiEmail(e.target.value, item.id, item.contact_type, item.contact_value)
                                                                            }
                                                                        }}
                                                                        // onBlur={(e) => {
                                                                        //     const match = EmailId.find(
                                                                        //         (item) => item.contact_type == Inputemailtype
                                                                        //     )
                                                                        //     const matchemail = EmailId.find(
                                                                        //         (item) => item.contact_value == Inputemail
                                                                        //     )
                                                                        //     //console.log("match", match, Inputemailtype)
                                                                        //     if (!match && !Inputemailtype) {
                                                                        //         window.confirm("You can not add Same type and Same Email")
                                                                        //         getInfo()
                                                                        //         return false;
                                                                        //     }
                                                                        //     else if (match) {
                                                                        //         window.confirm("You can not add Same type and Same Email")
                                                                        //         getInfo()
                                                                        //         return false;
                                                                        //     }
                                                                        //     else if (matchemail) {
                                                                        //         window.confirm("You can not add Same type and Same Email")
                                                                        //         getInfo()
                                                                        //         return false;
                                                                        //     }
                                                                        //     else {
                                                                        //         isValiEmail(e.target.value, item.id, item.contact_type, item.contact_value)
                                                                        //     }
                                                                        // }}
                                                                        type="email"
                                                                        className="inBorder px-0">
                                                                    </Input>
                                                                </Col>
                                                            </Row>
                                                            <hr style={{ width: "99%", alignSelf: 'center' }} className="my-1" />
                                                        </div >
                                                    </>
                                                )
                                            })}

                                            <div className="container-fluid">
                                                <Row className="ps-4 position-relative">
                                                    <div className="Test text-success">
                                                        <i className="fa fa-plus-circle " style={{ cursor: 'pointer' }} onClick={() => EmailDetail(Index + 1, "+")}></i>
                                                    </div>
                                                    <Col
                                                        md="11"
                                                        xs="10"
                                                        className="fw-bold text-success justify-content-center"
                                                        onClick={() => EmailDetail(Index + 1, "+")}
                                                        style={{ paddingTop: "7px", cursor: 'pointer' }}>Add Email</Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="card patients-sidebar mb-2 pt-0 pb-2" >
                                            {Address?.map((item, index) => {
                                                return (
                                                    <>
                                                        <div className="container-fluid">
                                                            <Row className="p-0 position-relative">
                                                                <div className="Test text-danger">
                                                                    {/* <i className="fa fa-minus-circle " onClick={() => AddressDetail(index, "-", item.id)}></i> */}
                                                                    <i className="fa fa-minus-circle"
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => {
                                                                            if (window.confirm("Are You sure you want to delete ?")) {
                                                                                AddressDetail(index, "-", item.id)
                                                                                //console.log('true');
                                                                            } else {
                                                                                //console.log('false');
                                                                            }
                                                                            // let res = confirm("datatat onm clik of apply")
                                                                        }}></i>
                                                                </div>
                                                                <Col xs="12" md="2" className="p-1 ps-4">
                                                                    <div className="form-group ">
                                                                        <select
                                                                            onBlur={() => item.id && addAddress(item.id,
                                                                                item.address_type,
                                                                                item.short_address,
                                                                                item.city,
                                                                                item.state,
                                                                                item.zip_code,
                                                                                item.county,
                                                                                item.country, item.address_line, item.address_lat, item.address_lng
                                                                            )}
                                                                            // onBlur={() => {
                                                                            //     const match = Address.find(
                                                                            //         (item) => item.address_type == Inputaddresstype
                                                                            //     )
                                                                            //     //console.log("Matche", match, Inputaddresstype)
                                                                            //     if (!match) {
                                                                            //         item.id && addAddress(item.id,
                                                                            //             item.address_type,
                                                                            //             item.short_address,
                                                                            //             item.city,
                                                                            //             item.state,
                                                                            //             item.zip_code,
                                                                            //             item.county,
                                                                            //             item.country, item.address_line, item.address_lat, item.address_lng
                                                                            //         )
                                                                            //     } else {
                                                                            //         window.confirm("Tou Can not Add Same Type of Address")
                                                                            //         getInfo()
                                                                            //         return false;
                                                                            //     }
                                                                            // }}
                                                                            onChange={(e) => setInputaddresstype(e.target.value)}
                                                                            defaultValue={item.address_type}
                                                                            style={{ borderColor: "#d3d3d3", borderRadius: 5 }}
                                                                            className="custom-select custom-select-lg py-2 px-0 w-100 cellbtn">
                                                                            {AddressType.map((item) => {
                                                                                return (
                                                                                    <option value={item.key}>{item.value}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md="12" className="px-1 py-1 ps-4 " >
                                                                    <Autocomplete
                                                                        onPlaceSelected={(place) => { autoComplete(place, index, item.id, item.address_type,) }}
                                                                        // onPlaceSelected={(place) => {
                                                                        //     //console.log("Matcheplace", Inputaddresstype)
                                                                        //     const match = Address.find(
                                                                        //         (item) => item.address_type == Inputaddresstype
                                                                        //     )
                                                                        //     //console.log("match match", match, Inputaddresstype)
                                                                        //     if (!match && !Inputaddresstype) {
                                                                        //         window.confirm("You can not add Same type of Address1")
                                                                        //         getInfo()
                                                                        //         return false;
                                                                        //     }
                                                                        //     else if (match) {
                                                                        //         window.confirm("You can not add Same type of Address")
                                                                        //         getInfo()
                                                                        //         return false;
                                                                        //     }
                                                                        //     else {
                                                                        //         autoComplete(place, index, item.id, item.address_type,)
                                                                        //     }
                                                                        // }}
                                                                        className="w-100 inBorder form-control "
                                                                        placeholder="Select Location"
                                                                        defaultValue={item.short_address}
                                                                        options={{
                                                                            types: ["geocode"],
                                                                            // componentRestrictions: { country: "USA", },
                                                                        }}
                                                                        apiKey="AIzaSyBdAMZn02FDm-1mNglz6KGkxYxnWb4Swl"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row key={item.short_address}>
                                                                <Col md="4" xs="6" className="px-1 py-1 ps-4">
                                                                    <Input disabled defaultValue={item.city} placeholder="City" className="inBorder"></Input>
                                                                </Col>
                                                                <Col md="4" xs="6" className="px-1 py-1 ps-1">
                                                                    <Input disabled defaultValue={item.state} placeholder="State" className="inBorder"></Input>
                                                                </Col>
                                                                <Col md="4" xs="6" className="px-1 py-1 ps-4 ps-md-1 col-6 col-md-4">
                                                                    <Input disabled defaultValue={item.zip_code?.length > 8 ? item.zip_code?.slice(0, 5) + "-" + item.zip_code?.slice(5, 9) : item.zip_code} placeholder="ZipCode" className="inBorder"></Input>
                                                                </Col>
                                                            </Row>
                                                            <Row key={item.city}>
                                                                <Col md="4" xs="6" className="px-1 py-1 ps-4">
                                                                    <Input disabled defaultValue={item.county} placeholder="County" className="inBorder"></Input>
                                                                </Col>
                                                                <Col md="4" xs="6" className="px-1 py-1 ps-1">
                                                                    <Input disabled defaultValue={item.country} placeholder="Country" className="inBorder"></Input>
                                                                </Col>

                                                            </Row>
                                                            <hr style={{ width: "99%", alignSelf: 'center' }} className="my-1" />
                                                        </div>
                                                    </>
                                                )
                                            })}
                                            <div className="container-fluid">
                                                <Row className="ps-4  position-relative">
                                                    <div className="Test text-success">
                                                        <i className="fa fa-plus-circle " style={{ cursor: 'pointer' }} onClick={() => AddressDetail(Index + 1, "+")}></i>
                                                    </div>
                                                    <Col
                                                        md="11" xs="10"
                                                        onClick={() => AddressDetail(Index + 1, "+")}
                                                        className="fw-bold text-success justify-content-center"
                                                        style={{ paddingTop: "7px", cursor: 'pointer' }}>Add Address</Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card patients-sidebar mb-2" >
                                    <div className="card-footer p-2"><strong>Other Details</strong></div>
                                    <div className="card-body p-3">
                                        <div className="row ">
                                            <div className="col-sm-6 col-md-4 p-2">
                                                <div className="form-group">
                                                    <label for="race">Race</label>
                                                    <select
                                                        value={Inputrace}
                                                        onBlur={() => updateDetail("race")}
                                                        onChange={(e) => setInputrace(e.target.value)}
                                                        style={{ borderColor: "#d3d3d3", }}
                                                        className="form-select">
                                                        <option>Select Race</option>
                                                        {Race.map((item) => {
                                                            return (
                                                                <option value={item.key}>{item.value}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 p-2">
                                                <div className="form-group">
                                                    <label for="ethnicity">Ethnicity</label>
                                                    <select
                                                        value={Inputethnicity}
                                                        onBlur={() => updateDetail("ethnicity")}
                                                        onChange={(e) => setInputethnicity(e.target.value)}
                                                        style={{ borderColor: "#d3d3d3", }}
                                                        className="form-select">
                                                        <option>Select Ethnicity</option>
                                                        {Ethnicity.map((item) => {
                                                            return (
                                                                <option value={item.key}>{item.value}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 p-2">
                                                <div className="form-group">
                                                    <label for="religion">Religion</label>
                                                    <select
                                                        onBlur={() => updateDetail("religion")}
                                                        onChange={(e) => setInputreligion(e.target.value)}
                                                        value={Inputreligion}
                                                        style={{ borderColor: "#d3d3d3", }}
                                                        className="form-select">
                                                        <option>Select Religion</option>
                                                        {Religion.map((item) => {
                                                            return (
                                                                <option value={item.key}>{item.value}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 p-2">
                                                <div className="form-group">
                                                    <label for="language">Language</label>
                                                    <select
                                                        onBlur={() => updateDetail("language")}
                                                        onChange={(e) => setInputlanguage(e.target.value)}
                                                        value={Inputlanguage}
                                                        style={{ borderColor: "#d3d3d3", }}
                                                        className="form-select">
                                                        <option>Select Language</option>
                                                        {Language.map((item) => {
                                                            return (
                                                                <option value={item.key}>{item.value}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 p-2">
                                                <div className="form-group">
                                                    <label for="nationality">Nationality</label>
                                                    <select
                                                        onBlur={() => updateDetail("nationality")}
                                                        onChange={(e) => setInputnationality(e.target.value)}
                                                        value={Inputnationality}
                                                        style={{ borderColor: "#d3d3d3", }}
                                                        className="form-select">
                                                        <option>Select Nationality</option>
                                                        {Nationality.map((item) => {
                                                            return (
                                                                <option value={item.key}>{item.value}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-4 p-2">
                                                <div className="form-group">
                                                    <label for="dr_lic">Driver's License</label>
                                                    <input type="text"
                                                        onBlur={() => updateDetail("dr_lic")}
                                                        onChange={(e) => setDrivingLic(e.target.value)}
                                                        value={DrivingLic}

                                                        // defaultValue={Userinfo.dr_lic}
                                                        autoComplete="nope" className="form-control" id="dr_lic" name="dr_lic" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </TabPane>

                        {/* //Document */}
                        <TabPane tabId="2">
                            {photoid.length < 2 &&
                                <div className="row mb-3 " data-aos="fade-in" style={{ alignItems: 'center', justifyContent: 'right' }}>
                                    <button className="btn btn-inshover width_20" onClick={() => cardDocument("0")}>Add Photo ID</button>
                                </div>
                            }
                            <div className="col-12 col-md-4 patients-sidebar mb-2 p-1 " data-aos="fade-in">
                                {photoid.map((item, index) => {
                                    return (
                                        <div className="col-12 col-md-12 patients-sidebar mb-2" data-aos="fade-in">
                                            <div className="card">
                                                <div className="card-footer p-2">
                                                    <strong>{index == 0 && "Photo ID Card" || index == 1 && "Other Side Photo ID"}</strong>
                                                    <button className="btn btn-inshover p-0 px-1 float-right" onClick={() => cardDocument("0")}> Retake</button>
                                                </div>
                                                <div className="card-body p-3">
                                                    <Row>
                                                        <Col md="12" sm="12" xs="12">
                                                            <label for="" className="drop-container">
                                                                {item?.aws_document_url.includes(".pdf") == false &&
                                                                    <img className="wimg" img={"image-8"} src={item?.aws_document_url} />
                                                                }
                                                                {item?.aws_document_url.includes(".pdf") &&
                                                                    <>
                                                                        <div className="d-none d-md-block">
                                                                            <object
                                                                                className="data-object"
                                                                                data={item?.aws_document_url}
                                                                                type="application/pdf"
                                                                                width="100%"
                                                                                height="380px"
                                                                                style={{ alignContent: 'center' }}>
                                                                                <p>
                                                                                    <a href={item?.aws_document_url} target="_blank">Link to the attached PDF!</a>
                                                                                </p>
                                                                            </object>
                                                                        </div>
                                                                        <div className="d-block d-md-none">
                                                                            <iframe
                                                                                src={`https://docs.google.com/gview?url=${encodeURIComponent(item?.aws_document_url)}&embedded=true`}
                                                                                frameBorder="0"
                                                                                style={{ width: '100%', height: '380px', border: 'none' }}
                                                                            ></iframe>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </label>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                })}
                            </div>
                            <hr style={{ padding: 2 }} />
                            <div className="row mb-3 " data-aos="fade-in" style={{ alignItems: 'center', justifyContent: 'right' }}>
                                <button className="btn btn-inshover width_20" onClick={() => cardDocument("1")}>Add Insurance Card</button>
                            </div>
                            {InsuranceCard.map((item, index) => {
                                //0  PI 1 IC
                                if (item.type === 1) {
                                    let idofimage = ""
                                    let flag = false;
                                    let data = item.aws_document_url.includes(".pdf")

                                    return (
                                        <div className="col-12">
                                            <div className="col-12 col-md-4 patients-sidebar mb-2" data-aos="fade-in">
                                                <div className="card">
                                                    <div className="card-footer p-2">
                                                        {InsuranceApi.map((data) => {
                                                            if (item.id === data.insurance_document_id) {
                                                                flag = true
                                                                return (
                                                                    <div >
                                                                        {(isOption && activeid === item.id) ?
                                                                            <Col>
                                                                                <select
                                                                                    onClick={(e) => idofimage = e.target.value}
                                                                                    // onBlur={() => linkDoc("new", idofimage, item.aws_document_url, item.id)}
                                                                                    style={{ borderColor: "#d3d3d3", borderRadius: 5, width: "85%" }}
                                                                                    className="custom-select custom-select-lg py-2 px-0 cellbtn">
                                                                                    <option value={data.id}>{data.insurance_name}</option>
                                                                                    {InsuranceApi.map((data) => {
                                                                                        if (!data.insurance_document_id) {
                                                                                            return (
                                                                                                <option value={data.id}>{data.insurance_name}</option>
                                                                                            )
                                                                                        }
                                                                                    })}
                                                                                </select>
                                                                                <i style={{ color: "#210154", cursor: 'pointer' }} onClick={() => setisOption(false)} className="fas fa-times-circle mt-2 fs-3  float-right" ></i>
                                                                                <i style={{ color: "#210154", cursor: 'pointer' }} onClick={() => linkDoc("new", idofimage, item.aws_document_url, item.id)} className="fas fa-check-circle mt-2 fs-3  float-right" ></i>
                                                                            </Col> :
                                                                            <>
                                                                                <strong>{data.insurance_name}</strong>
                                                                                <i style={{ color: "#210154", cursor: 'pointer' }} onClick={() => {
                                                                                    setisOption(true)
                                                                                    setactiveid(item.id)
                                                                                }} className="fas fa-edit fs-4 float-right"></i>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        })}
                                                        {!flag &&
                                                            <div>
                                                                {(isOption && activeid === item.id) ?
                                                                    <Col>
                                                                        <select
                                                                            onClick={(e) => idofimage = e.target.value}
                                                                            // onBlur={() => linkDoc("new", idofimage, item.aws_document_url, item.id)}
                                                                            style={{ borderColor: "#d3d3d3", borderRadius: 5, width: "85%" }}
                                                                            className="custom-select custom-select-lg py-2 px-0 cellbtn">
                                                                            {InsuranceApi.map((data) => {
                                                                                if (!data.insurance_document_id) {
                                                                                    return (
                                                                                        <option value={data.id}>{data.insurance_name}</option>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </select>
                                                                        <i style={{ color: "#210154", cursor: 'pointer' }} onClick={() => setisOption(false)} className="fas fa-times-circle mt-2 fs-3  float-right" ></i>
                                                                        <i style={{ color: "#210154", cursor: 'pointer' }} onClick={() => linkDoc("new", idofimage, item.aws_document_url, item.id)} className="fas fa-check-circle mt-2 fs-3  float-right" ></i>
                                                                    </Col> :
                                                                    <>
                                                                        <strong>Unassigned Insurance Card</strong>
                                                                        <i style={{ color: "#210154", cursor: 'pointer' }} onClick={() => {
                                                                            setisOption(true)
                                                                            setactiveid(item.id)
                                                                        }} className="fas fa-edit fs-4 float-right"></i>
                                                                    </>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="card-body p-3">
                                                        <Row>
                                                            <Col md="12" sm="12" xs="12">
                                                                <label for="" className="drop-container">
                                                                    {index + 1}{"    "}{item.id}
                                                                    {data == false &&
                                                                        <img className="wimg" alt={"image-9"} src={item.aws_document_url} />
                                                                    }
                                                                    {data &&
                                                                        <>
                                                                            <div className="d-none d-md-block">
                                                                                <object
                                                                                    className="data-object"
                                                                                    data={item.aws_document_url}
                                                                                    type="application/pdf"
                                                                                    width="100%"
                                                                                    height="380px"
                                                                                    style={{ alignContent: 'center' }}>
                                                                                    <p>
                                                                                        <a href={item.aws_document_url} target="_blank">Link to the attached PDF!</a>
                                                                                    </p>
                                                                                </object>
                                                                            </div>
                                                                            <div className="d-block d-md-none">
                                                                                <iframe
                                                                                    src={`https://docs.google.com/gview?url=${encodeURIComponent(item.aws_document_url)}&embedded=true`}
                                                                                    frameBorder="0"
                                                                                    style={{ width: '100%', height: '380px', border: 'none' }}
                                                                                // src={item.aws_document_url}
                                                                                // width="100%"
                                                                                // height="380px"
                                                                                ></iframe>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </label>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                        </TabPane>

                        {/* Insuarnce */}
                        <TabPane tabId="3" >
                            <div className="row mb-3 pe-3" data-aos="fade-in" style={{ alignItems: 'center', justifyContent: 'right' }}>
                                <button className="btn btn-inshover width_20" onClick={() => toggle("new")}>Add Insurance</button>
                            </div>
                            <div className="card patients-sidebar mb-2" data-aos="fade-in">
                                <div className="card-footer p-2"><strong>My Insurance Details</strong></div>
                                <div className="card-body">
                                    {InsuranceApi?.map((item, index) => {
                                        let Result = ((format(new Date(), 'MM/dd/yyyy')) < (format(new Date(item.end_date_time), 'MM/dd/yyyy')))
                                        if ((!item.end_date_time) || (item.end_date_time && Result == true)) {
                                            return (
                                                <>
                                                    <div key={Date.now()} className="row justify-content-left">
                                                        <div className="position-relative mb-0 px-0 text-end pe-md-3">
                                                            <div className="d-md-none d-sm-block">
                                                                <div className="d-none">
                                                                    {!item.end_date_time &&
                                                                        < span className="fs-6 text-success fw-bold text-nowrap"> Coverage Active</span>
                                                                    }
                                                                    {(item.end_date_time && Result == true) &&
                                                                        < span style={{ fontSize: 15 }} className="text-warning fw-bold text-nowrap"> Coverage Ending on {item.end_date_time}</span>
                                                                    }
                                                                    {(item.end_date_time && Result == false) &&
                                                                        < span className="fs-6 text-danger fw-bold text-nowrap"> Coverage ended on {item.end_date_time}</span>
                                                                    }
                                                                </div>
                                                                <i style={{ color: "#210154", cursor: 'pointer', marginLeft: 4 }} onClick={() => toggle("edit", item.id)} className="fas fa-edit fs-4 ms-1"></i>
                                                                <i style={{ color: "#210154", cursor: 'pointer', marginLeft: 4 }} className="fas fa-power-off fs-4" onClick={() => reActive(item.id, item.end_date_time)}></i>
                                                                {item.insurance_document_id ?
                                                                    <i style={{ color: "#210154", cursor: 'pointer', marginLeft: 4 }} className="fas fa-id-card fs-4" onClick={() => checkCard(item.id, item.insurance_document_id, item.insurance_id, item.insurance_name)}></i> :
                                                                    <i style={{ color: "gray", cursor: 'pointer', marginLeft: 4 }} className="fas fa-id-card fs-4" ></i>
                                                                }
                                                                {index !== 0 &&
                                                                    <img alt={"image-10"} style={{ cursor: 'pointer' }} onClick={() => {
                                                                        // if (window.confirm(`Is ${item.insurance_name} your Primary Insurance ?`)) {
                                                                        //     makePrimary(item.id)
                                                                        //     //console.log('true');
                                                                        // } else {
                                                                        //     //console.log('false');
                                                                        // }
                                                                        makePrimaryModal(item.id, item.insurance_name)
                                                                    }} title="Click to make primary" className="custom-image-primary" src={require("../Assets/Icons/plus-p.png")} />
                                                                }
                                                            </div>
                                                            <div className="d-md-block d-sm-none">
                                                                {index !== 0 &&
                                                                    <img alt="image-11" style={{ cursor: 'pointer' }} onClick={() => {
                                                                        // if (window.confirm(`Is ${item.insurance_name} your Primary Insurance ?`)) {
                                                                        //     makePrimary(item.id)
                                                                        //     //console.log('true');
                                                                        // } else {
                                                                        //     //console.log('false');
                                                                        // }
                                                                        makePrimaryModal(item.id, item.insurance_name)
                                                                    }} title="Click to make primary" className="custom-image-makeprimary float-right" src={require("../Assets/Icons/plus-p.png")} />
                                                                }
                                                                {item.insurance_document_id ?
                                                                    <i style={{ color: "#210154", cursor: 'pointer' }} className="fas fa-id-card ms-1 fs-4 float-right" onClick={() => checkCard(item.id, item.insurance_document_id, item.insurance_id, item.insurance_name)}></i> :
                                                                    <i style={{ color: "gray", cursor: 'pointer' }} className="fas fa-id-card ms-1 fs-4 float-right" ></i>
                                                                }
                                                                <i style={{ color: "#210154", cursor: 'pointer' }} className="fas fa-power-off ms-1 fs-4 float-right" onClick={() => reActive(item.id, item.end_date_time)}></i>
                                                                <i style={{ color: "#210154", cursor: 'pointer' }} onClick={() => toggle("edit", item.id)} className="fas fa-edit fs-4 ms-1 float-right"></i>
                                                                <div className="d-none">
                                                                    {!item.end_date_time &&
                                                                        < span className="float-right fs-6 text-success fw-bold"> Coverage Active</span>
                                                                    }
                                                                    {(item.end_date_time && Result == true) &&
                                                                        < span className="float-right fs-6 text-warning fw-bold"> Coverage Ending on {item.end_date_time}</span>
                                                                    }
                                                                    {(item.end_date_time && Result == false) &&
                                                                        < span className="float-right fs-6 text-danger fw-bold"> Coverage ended on {item.end_date_time}</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ padding: 3 }} className="col-auto d-md-none">
                                                            <CreditCard
                                                                insuranceID={item.id}
                                                                index={index}
                                                                InsuranceName={item.insurance_name}
                                                                MemberID={item.insurance_policy}
                                                                Group={item.insurance_group}
                                                                Service={item.insurance_code}
                                                                Effective_From={item.start_date_time}
                                                                Name={item.first_name + " " + item.last_name}
                                                                Gender={item.gender}
                                                                Realation={item.relation}
                                                                DOB={item.dob}
                                                            />
                                                        </div>
                                                        <div className="col-6 d-sm-none d-md-block">
                                                            <div className="card">
                                                                {index == 0 && <span title="Primary" className="badge primaryins" ><img alt={"image-12"} className="custom-image-icon" src={require("../Assets/Icons/p.png")} /></span>}
                                                                {index == 1 && <span title="Secondary" className="badge primaryins" id="DisabledAutoHideExample"><img alt={"image-13"} className="custom-image-icon" src={require("../Assets/Icons/s.png")} /></span>}
                                                                {index > 1 && <span title="Other" className="badge primaryins" ><img className="custom-image-icon" alt="image-14" src={require("../Assets/Icons/plus.png")} /></span>}
                                                                <div className="card-header bg-light-custom">
                                                                    <p className="fw-bold text-center m-0 text-light">Insurance Information </p>
                                                                </div>
                                                                <div className="card-body p-2">
                                                                    <Row>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2 ">
                                                                            <label>Insurance</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.insurance_name}</label>
                                                                        </Col>

                                                                        <Col md="6" sm="6" xs="6" className="mt-2 ">
                                                                            <label>Member ID</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.insurance_policy}</label>
                                                                        </Col>

                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label >Group / Service</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.insurance_group}{" "}/{" "}{item.insurance_code}</label>
                                                                        </Col>
                                                                        {/* <Col md="3" sm="3" xs="3" className="mt-2">
                                                                    <label >Service</label>
                                                                </Col>
                                                                <Col md="3" sm="3" xs="3" className="mt-2">
                                                                    <label className="fw-bold"> {item.service}</label>
                                                                </Col> */}
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label >Effective Date</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.start_date_time}</label>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 d-sm-none d-md-block">
                                                            <div className="card">
                                                                <div className="card-header bg-light-custom">
                                                                    <p className="fw-bold text-center m-0 text-light">Subscriber Information </p>
                                                                </div>
                                                                <div className="card-body p-2">
                                                                    <Row>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2 ">
                                                                            <label>Holder Name</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.first_name}{" "}{item.middle_name}{" "}{item.last_name}</label>
                                                                        </Col>

                                                                        <Col md="6" sm="6" xs="6" className="mt-2 ">
                                                                            <label>Gender</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.gender === "F" && "Female"}
                                                                                {item.gender === "M" && "Male"}
                                                                                {item.gender === "O" && "Other"}
                                                                                {item.gender === "U" && "Unknown"}</label>
                                                                        </Col>

                                                                        <Col md="6" sm="6" xs="6" className="mt-2 ">
                                                                            <label>Relation With </label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.relation === "SE" && "Self"}
                                                                                {item.relation === "SP" && "Spouse"}
                                                                                {item.relation === "DE" && "Child"}
                                                                                {item.relation === "OT" && "Other"}</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2 ">
                                                                            <label>Date Of Birth</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.dob}</label>
                                                                        </Col>

                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Crad Detail For Mobile  */}
                                                        <hr className="mt-3" style={{ padding: 2 }} />
                                                    </div >
                                                </>
                                            )
                                        }
                                    })}
                                    {InsuranceApi?.map((item, index) => {
                                        let Result = ((format(new Date(), 'MM/dd/yyyy')) < (format(new Date(item.end_date_time), 'MM/dd/yyyy')))
                                        if (item.end_date_time && Result == false) {
                                            return (
                                                <>
                                                    <div key={Date.now()} className="row justify-content-left">
                                                        <FormGroup switch className="position-relative ">
                                                            {item.insurance_document_id ?
                                                                <i style={{ color: "#210154", cursor: 'pointer' }} className="fas fa-id-card ms-1 fs-4 float-right" onClick={() => checkCard(item.id, item.insurance_document_id, item.insurance_id, item.insurance_name)}></i> :
                                                                <i style={{ color: "gray", cursor: 'pointer' }} className="fas fa-id-card ms-1 fs-4 float-right" ></i>
                                                            }
                                                            <i style={{ color: "#210154", cursor: 'pointer' }} onClick={() => toggle("edit", item.id)} className="fas fa-edit float-right fs-4 ms-1"></i>
                                                            <div className="d-none">
                                                                {!item.end_date_time &&
                                                                    < span className="float-right fs-6 text-success fw-bold"> Coverage Active</span>
                                                                }
                                                                {(item.end_date_time && Result == true) &&
                                                                    < span className="float-right fs-6 text-warning fw-bold"> Coverage Ending on {item.end_date_time}</span>
                                                                }
                                                                {(item.end_date_time && Result == false) &&
                                                                    < span className="float-right fs-6 text-danger fw-bold"> Coverage ended on {item.end_date_time}</span>
                                                                }
                                                            </div>
                                                        </FormGroup>
                                                        <div style={{ padding: 3 }} className="col-auto d-md-none">
                                                            <CreditCard
                                                                index={"Expired"}
                                                                endDate={item.end_date_time}
                                                                InsuranceName={item.insurance_name}
                                                                MemberID={item.insurance_policy}
                                                                Group={item.insurance_group}
                                                                Service={item.insurance_code}
                                                                Effective_From={item.start_date_time}
                                                                Name={item.first_name + " " + item.last_name}
                                                                Gender={item.gender}
                                                                Realation={item.relation}
                                                                DOB={item.dob}
                                                            />
                                                        </div>
                                                        {/* Crad Detail For Mobile  */}
                                                        <div className="col-6 d-sm-none d-md-block">
                                                            <div className="card">
                                                                <span className="badge bg-danger primaryins">Expired {" "}
                                                                    {item.end_date_time}</span>
                                                                <div className="card-header bg-light-custom">
                                                                    <p className="fw-bold text-center m-0 text-light">Insurance Information </p>
                                                                </div>
                                                                <div className="card-body p-2">
                                                                    <Row>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2 ">
                                                                            <label>Insurance</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.insurance_name}</label>
                                                                        </Col>

                                                                        <Col md="6" sm="6" xs="6" className="mt-2 ">
                                                                            <label>Member ID</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.insurance_policy}</label>
                                                                        </Col>

                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label >Group / Service</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.insurance_group}{" "}/{" "}{item.insurance_code}</label>
                                                                        </Col>
                                                                        {/* <Col md="3" sm="3" xs="3" className="mt-2">
                                                                    <label >Service</label>
                                                                </Col>
                                                                <Col md="3" sm="3" xs="3" className="mt-2">
                                                                    <label className="fw-bold"> {item.service}</label>
                                                                </Col> */}
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label >Effective Date</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.start_date_time}</label>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 d-sm-none d-md-block ">
                                                            <div className="card">
                                                                <div className="card-header bg-light-custom">
                                                                    <p className="fw-bold text-center m-0 text-light">Subscriber Information </p>
                                                                </div>
                                                                <div className="card-body p-2">
                                                                    <Row>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2 ">
                                                                            <label>Holder Name</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.first_name}{" "}{item.middle_name}{" "}{item.last_name}</label>
                                                                        </Col>

                                                                        <Col md="6" sm="6" xs="6" className="mt-2 ">
                                                                            <label>Gender</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.gender === "F" && "Female"}
                                                                                {item.gender === "M" && "Male"}
                                                                                {item.gender === "O" && "Other"}
                                                                                {item.gender === "U" && "Unknown"}</label>
                                                                        </Col>

                                                                        <Col md="6" sm="6" xs="6" className="mt-2 ">
                                                                            <label>Relation With </label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.relation === "SE" && "Self"}
                                                                                {item.relation === "SP" && "Spouse"}
                                                                                {item.relation === "DE" && "Child"}
                                                                                {item.relation === "OT" && "Other"}</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2 ">
                                                                            <label>Date Of Birth</label>
                                                                        </Col>
                                                                        <Col md="6" sm="6" xs="6" className="mt-2">
                                                                            <label className="fw-bold"> {item.dob}</label>
                                                                        </Col>

                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Crad Detail For Mobile  */}
                                                        <hr className="mt-3" style={{ padding: 2 }} />
                                                    </div >
                                                </>
                                            )
                                        }
                                    })}
                                </div>
                            </div>

                            <Modal
                                zIndex={999}
                                isOpen={isOpen}
                                backdropTransition={{ timeout: 100 }}
                                toggle={() => setisOpen(false)}
                                centered={true}
                                size="lg"
                            >
                                <ModalHeader toggle={() => setisOpen(false)}>Add Insurance</ModalHeader>
                                <ModalBody>
                                    <MyInsurance toggle={isOpen} sendData={() => getData()} />
                                </ModalBody>
                            </Modal>
                        </TabPane>
                    </TabContent >
                </div>
            </div >
        </>
    );
};

export default UserProfile;