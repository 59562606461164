import React, { useEffect, useState, } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Breadcrumb, BreadcrumbItem, Col, FormGroup, Input, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table } from "reactstrap";
import { loadStripe } from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { ThreeCircles, } from 'react-loader-spinner'
import { ToastContainer, toast } from "react-toastify";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "../CSS/invoice.scss"
import '../components/Loader/loader.scss'
import LoaderComp from "./Loader/Loader";
import { useSelector } from "react-redux";
import getCurrentDate from "../Global/Global";

const options = {
    mode: 'payment',
    amount: 1099,
    currency: 'usd',
    // Fully customizable with appearance API.
    appearance: {
        /*...*/
    },
};
const steps = ['Processing Payment', 'Recording Payment', 'Applying Payment'];
const stripePromise = loadStripe('pk_test_51JsZTODfTmp1vZtT8yPFRf7sr7YIbnfIswwKminH1hjg72sQtcbu1vWVazXlHk4YPCBfsmyUg8V23zNe6kURyxOd00PI43hSsG');
const stripekey = require('stripe')('sk_test_51JsZTODfTmp1vZtTu3rOa6z0MMNbjUFAlZtF69GfD2gNU6Daz9NvaqtJiUOA9AzLDJohDfZrCM52Xn4XugB1zutz00pcoxwJyS');
let BASEURL = await localStorage.getItem("BaseApiUrl")
const UserInvoice = (props, Labcode) => {
    const [Active1, setActive1] = useState("active")
    const [Active2, setActive2] = useState("")
    const [Tabid, setTabid] = useState("1")
    const [Year, setYear] = useState()
    const [Invoice, setInvoice] = useState([])
    const [UserInfo, setUserInfo] = useState([])
    const [successInvoice, setsuccessInvoice] = useState([])
    const [Amountdue, setAmountdue] = useState(0)
    const [isOpen, setisOpen] = useState(false)
    const [invid, setinvid] = useState([])
    const [isPayment, setisPayment] = useState(false)
    const [clientSecretres, setclientSecretres] = useState("")

    const [Activestep, setActivestep] = useState(0)
    const [StepFailed, setStepFailed] = useState()

    const [parent_row_id, setparent_row_id] = useState("")
    const [Amount, setAmount] = useState("")
    const [claim_id, setclaim_id] = useState("")
    const [invoice_number, setinvoice_number] = useState("")
    const [bill_format, setbill_format] = useState("")
    const [type, settype] = useState("")
    const [sentdate, setsentdate] = useState("")
    const [paymentType, setpaymentType] = useState("")
    const [parentrowid, setparentrowid] = useState("")
    const [isLast, setisLast] = useState()
    const [currentPage, setcurrentPage] = useState()
    const [isLoading, setisLoading] = useState(false)
    const [age, setage] = useState(null)
    const { userinfo } = useSelector((state) => state.user);


    async function TabContant(TAB) {
        if (TAB === "1") {
            setActive1("active")
            setActive2("")
            setTabid("1")
        }
        if (TAB === "2") {
            setActive1("")
            setActive2("active")
            setTabid("2")
        }
    }
    async function getInfo() {
        const Baseurl = JSON.parse(await localStorage.getItem("Baseurl"))
        const match = Baseurl?.find(
            (item) => item.laboratory_code == props.Labcode
        );
        //console.log("match Hedaer", match)
        if (match) {
            let Patient_ID = userinfo?.uname
            try {
                await fetch(`${match?.base_api_url}/apipatient/public/v1/getpatientdetails/` + Patient_ID,
                    {
                        method: 'GET',
                        headers: {
                            // Authorization: 'Bearer ' + `${Token}`,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                if (responseJson.data) {
                                    setUserInfo(responseJson.data)

                                    const birthDate = new Date(`${responseJson.data.dob}`);

                                    const currentDate = new Date();
                                    const differenceInMs = currentDate - birthDate;
                                    const ageDate = new Date(differenceInMs);
                                    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
                                    setage(age)
                                    localStorage.setItem("TenantID", responseJson.data.tenant_id)
                                }
                            });
                        } else {
                            //console.log("Res False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
    }

    async function year() {
        let Patient_ID = userinfo?.uname
        let Token = userinfo?.token
        setisLoading(true)
        const Baseurl = JSON.parse(await localStorage.getItem("Baseurl"))
        const match = Baseurl?.find(
            (item) => item.laboratory_code == props.Labcode
        );
        //console.log("match Hedaer", match)
        if (match) {
            //console.log("${BASEURL}", `${BASEURL}`)
            try {
                await fetch(`${match?.base_api_url}/apipatient/public/v1/getinvoicesyearlist`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            type: "Patient",
                            parent_row_id: Patient_ID,
                            token: Token,
                            patient_id: Patient_ID,
                        }),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then(async (responseJson) => {
                                //console.log("Get Response Year List", responseJson);
                                if (responseJson.data) {
                                    setisLoading(false)
                                    setYear(responseJson.data)
                                }
                            });
                        } else {
                            //console.log("Res False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
    }
    async function invoice(status, s_year, page) {
        const Baseurl = JSON.parse(await localStorage.getItem("Baseurl"))
        const match = Baseurl?.find(
            (item) => item.laboratory_code == props.Labcode
        );
        if (match) {
            let Patient_ID = userinfo?.uname
            let Token = userinfo?.token
            const currentyear = new Date().getFullYear();
            var formdata = ""
            if (status === "Sent") {
                formdata = JSON.stringify({
                    type: "Patient",
                    status: "Sent",
                    parent_row_id: Patient_ID,
                    from: ``,
                    to: ``,
                    token: Token,
                    patient_id: Patient_ID,
                })
            }
            if (status === "Closed") {
                formdata = JSON.stringify({
                    type: "Patient",
                    status: "Closed",
                    parent_row_id: Patient_ID,
                    from: `01/01/${s_year ? s_year : currentyear}`,
                    to: `12/31/${s_year ? s_year : currentyear}`,
                    token: Token,
                    patient_id: Patient_ID,
                })
            }
            try {
                setisLoading(true)
                await fetch(page ? `${match?.base_api_url}/apipatient/public/v1/allinvoiceslist${page}` : `${match?.base_api_url}/apipatient/public/v1/allinvoiceslist`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: formdata,
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                //console.log("Get Response Invoice", responseJson, stripekey, stripePromise);
                                if (responseJson.data) {
                                    setisLoading(false)
                                    let temparr = []
                                    responseJson.data.map((item, index) => {
                                        temparr.push({ ischeck: true, item })
                                    })
                                    setInvoice(temparr)
                                    if (responseJson.data.length > 0) {
                                        let dueAmount = 0
                                        temparr.map((item) => {
                                            dueAmount = parseFloat(dueAmount) + parseFloat(item.item.outstanding_amount)
                                        })
                                        let Temp = parseFloat(dueAmount).toFixed(2)
                                        setAmountdue(Temp)

                                        setparentrowid(responseJson.data[0].parent_row_id)
                                        let lastpage = Math.ceil(responseJson.meta.pagination.total / responseJson.meta.pagination.per_page)
                                        setisLast(lastpage)
                                        setcurrentPage(responseJson.meta.pagination.current_page)
                                    } else {
                                        setAmountdue(null)
                                        setInvoice([])
                                        setisLoading(false)
                                    }
                                    setisLoading(false)
                                }
                            });
                        } else {
                            //console.log("response.status False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
    }

    const step1 = (async (paymentType, amount, invid, parent_row_id, claim_id, invoice_number, bill_format, type, send_dt) => {
        if (amount === null ||
            amount === undefined ||
            !amount.trim().length || amount == "0.00") {
            toast.info("No Invoice Selected to pay");
            return false;
        }
        setStepFailed()
        let Token = userinfo?.token
        let Patient_ID = userinfo?.uname
        let TenantID = await localStorage.getItem("TenantID")

        const Baseurl = JSON.parse(await localStorage.getItem("Baseurl"))
        const match = Baseurl?.find(
            (item) => item.laboratory_code == props.Labcode
        );
        //console.log("match Hedaer", match)
        if (match) {
            //console.log("Form Data step1", {
            //     "tenant_id": TenantID,
            //     "systemvalue": "patientportal",
            //     "eventdata": null,
            //     "amount": amount
            // })
            await fetch(`${match?.base_api_url}/apipatient/public/v1/getclientsecretforuielements`,
                {
                    method: 'POST',
                    headers: {
                        // Authorization: 'Bearer ' + Token,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "tenant_id": TenantID,
                        "systemvalue": "patientportal",
                        "eventdata": null,
                        "amount": amount,
                        "token": Token,
                        "patient_id": Patient_ID,
                    }),
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then(async (responseJson) => {
                            //console.log("Get Response getclientsecretforuielements", responseJson);
                            if (responseJson.data) {
                                setclientSecretres(responseJson.data.clientSecret)
                                setisPayment(true)
                                setinvid(invid)
                                setclaim_id(claim_id)
                                setinvoice_number(invoice_number)
                                setbill_format(bill_format)
                                settype(type)
                                setsentdate(send_dt)
                                setparent_row_id(parent_row_id ? parent_row_id : null)
                                setAmount(amount)
                                setpaymentType(paymentType)
                            }
                        });
                    } else {
                        //console.log("response status  getclientsecretforuielements False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        }
    })
    const CheckoutForm = () => {
        const stripe = useStripe();
        const elements = useElements();
        const [errorMessage, setErrorMessage] = useState(null);


        const TenantName = localStorage.getItem('TenantNameApi')
        const step2 = (async (event) => {
            let Token = userinfo?.token
            let Patient_ID = userinfo?.uname
            var date = new Date().getDate();
            var month = new Date().getMonth() + 1;
            var year = new Date().getFullYear();
            var hour = new Date().getHours();
            var minutes = new Date().getMinutes();
            var payment_date = year + '-' + month + '-' + date + " " + hour + ":" + minutes;
            //console.log("Form Datat step2", {
            //     "payment_date": payment_date.toString(),
            //     "details": "Link/Credit Card Ending",
            //     "method": "Creditcard",
            //     "received_from": "Patient",
            //     "parent_id": parent_row_id,
            //     "received_amount": Amount,
            //     "applied_amount": "0",
            //     "status": "Open",
            //     "deposited": "No",
            //     "account": ""
            // })
            event.preventDefault();
            if (elements == null) {
                return;
            }
            const { error: submitError } = await elements.submit();
            if (submitError) {
                setErrorMessage(submitError.message);
                return;
            } else {
                const Baseurl = JSON.parse(await localStorage.getItem("Baseurl"))
                const match = Baseurl?.find(
                    (item) => item.laboratory_code == props.Labcode
                );
                //console.log("match Hedaer", match)
                if (match) {
                    await fetch(`${match?.base_api_url}/apipatient/public/v1/createpayment`,
                        {
                            method: 'POST',
                            headers: {
                                // Authorization: 'Bearer ' + Token,
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                "payment_date": payment_date.toString(),
                                "details": "Link/Credit Card Ending",
                                "method": "Creditcard",
                                "received_from": "Patient",
                                "parent_id": parent_row_id ? parent_row_id : parentrowid,
                                "received_amount": Amount,
                                "applied_amount": "0",
                                "status": "Open",
                                "deposited": "No",
                                "account": "",
                                "token": Token,
                                "patient_id": Patient_ID,
                            }),
                        })
                        .then((response) => {
                            if (response.status === 200) {
                                response.json().then(async (responseJson) => {
                                    //console.log("Get Response createpayment", responseJson);
                                    if (responseJson.data) {
                                        handleSubmit(responseJson.data.id, responseJson.data.received_amount)
                                    }
                                });
                            } else {
                                //console.log("response.status createpayment False")
                            }
                        })
                        .catch((error) => {
                            console.log("Fetch Error", error);
                        });
                }
            }
        })
        const step3 = (async (payment_method, piid, paymentID, Reciveamount) => {
            try {
                const paymentMethod = await stripekey.paymentMethods.retrieve(payment_method);
                if (paymentMethod.id) {
                    //console.log("datatatataat", paymentMethod)
                    step4(piid, paymentMethod.card.last4, paymentID, Reciveamount, paymentMethod.card.brand)
                }
            } catch (error) {
                console.error('Error retrieving payment method:', error);
            }
        })
        const step4 = (async (piid, last4, paymentID, Reciveamount, cardtype) => {
            let Token = userinfo?.token
            let Patient_ID = userinfo?.uname
            let cardType = cardtype.toUpperCase()
            let arr = []
            let successarr = []
            if (paymentType === "Payall") {
                Invoice.map((item) => {
                    if (item.ischeck === true) {
                        arr.push({
                            "id": item.item.id,
                            "invoice_number": item.item.invoice_number,
                            "bill_format": item.item.bill_format,
                            "type": item.item.type,
                            "parent_row_id": item.item.parent_row_id,
                            "billed_amount": item.item.outstanding_amount,
                            "paid_amount": item.item.outstanding_amount,
                            "patient_payment": "0",
                            "adjustment": "0",
                            "payment_id": paymentID,
                            "piid": piid
                        })
                        successarr.push({
                            "id": item.item.id,
                            "invoice_number": item.item.invoice_number,
                            "bill_format": item.item.bill_format,
                            "type": item.item.type,
                            "parent_row_id": item.item.parent_row_id,
                            "billed_amount": item.item.outstanding_amount,
                            "paid_amount": item.item.outstanding_amount,
                            "patient_payment": "0",
                            "adjustment": "0",
                            "payment_id": paymentID,
                            "piid": piid,
                            "send_date": item.item.send_dt
                        })
                    }
                })
            }
            if (paymentType === "Single") {
                arr = [
                    {
                        "id": invid,
                        // "claim_id": claim_id,
                        "invoice_number": invoice_number,
                        "bill_format": bill_format,
                        "type": type,
                        "parent_row_id": parent_row_id,
                        "billed_amount": Reciveamount,
                        "paid_amount": Reciveamount,
                        "patient_payment": "0",
                        "adjustment": "0",
                        "payment_id": paymentID,
                        "piid": piid
                    }
                ]
                successarr = [
                    {
                        "id": invid,
                        // "claim_id": claim_id,
                        "invoice_number": invoice_number,
                        "bill_format": bill_format,
                        "type": type,
                        "parent_row_id": parent_row_id,
                        "billed_amount": Reciveamount,
                        "paid_amount": Reciveamount,
                        "patient_payment": "0",
                        "adjustment": "0",
                        "payment_id": paymentID,
                        "piid": piid,
                        "send_date": sentdate
                    }
                ]
            }

            //console.log("Array", arr)
            //console.log("atep 4", JSON.stringify({
            //     "details": `Link/Credit Card Ending **${last4}`,
            //     "apply_payment_data": arr
            // }))
            const Baseurl = JSON.parse(await localStorage.getItem("Baseurl"))
            const match = Baseurl?.find(
                (item) => item.laboratory_code == props.Labcode
            );
            //console.log("match Hedaer", match)
            if (match) {
                await fetch(`${match?.base_api_url}/apipatient/public/v1/applypayment`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "details": `${cardType} Card Ending **${last4}`,
                            "apply_payment_data": arr,
                            "token": Token,
                            "patient_id": Patient_ID,
                        }),
                    })
                    .then((response) => {
                        //console.log("Get Response applypayment", response);
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                //console.log("Get Response applypayment", responseJson);
                                if (responseJson.data[0].status === "Success") {
                                    setActivestep(2)
                                    setsuccessInvoice(successarr)
                                    // setisPayment(false)
                                    // invoice("Sent")
                                    // console.log("arrrarrrrarrrr", arr)
                                } else {
                                    // setsuccessInvoice(arr)
                                    setStepFailed(2)
                                    setisPayment(true)
                                }
                            });
                        } else {
                            //console.log("response.status False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            }
        })
        const handleSubmit = async (id, received_amount) => {
            const { paymentIntent, error } = await stripe.confirmPayment({
                elements,
                clientSecret: clientSecretres,
                confirmParams: {
                    return_url: 'https://example.com/order/123/complete',
                },
                redirect: 'if_required',
            });
            if (error) {
                toast.error(error.message);
                setErrorMessage(error.message);
            } else {
                setActivestep(1)
                step3(paymentIntent.payment_method, paymentIntent.id, id, received_amount)
            }
        };
        return (
            <>
                <ToastContainer
                    position="bottom-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    color
                />
                <center>
                    <form className="mt-3" onSubmit={step2}>
                        {Activestep === 0 &&
                            <>
                                <PaymentElement />
                                <button className="btn btn-primary my-3" type="submit" disabled={!stripe || !elements}>
                                    Next
                                </button>
                            </>
                        }
                        {(Activestep !== 0 && Activestep !== 2) &&
                            <div className={Activestep !== 0 && "modal-wrapper"}>
                                <div className='pos-abs-50-50'>
                                    <ThreeCircles
                                        height="100"
                                        width="100"
                                        color={Primarycolor}
                                        wrapperclassName=""
                                        visible={true}
                                        ariaLabel="three-circles-rotating"
                                        outerCircleColor={Secoundrycolor}
                                        innerCircleColor={Primarycolor}
                                        middleCircleColor={Secoundrycolor}
                                    />
                                </div>
                            </div>
                        }
                        {(Activestep !== 0 && Activestep === 2) &&
                            <>
                                <div className="success-content">
                                    <i style={{ color: "green", fontSize: 50 }} className="fas fa-check-circle" ></i>
                                    <p className="fw-bold mt-2">Thank you for your payment. Your transaction was successful!</p>
                                    <p style={{ fontWeight: "500" }}>Thank you for using {TenantName}.</p>
                                </div>
                                <center className="table-responsive">
                                    <table class="table table-light">
                                        <thead>
                                            <tr>
                                                <th scope="col">Invoice Date</th>
                                                <th scope="col">Invoice Number</th>
                                                <th scope="col">Amount</th>
                                                {/* <th scope="col">Download Invoice</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {successInvoice?.map((item) => {
                                                return (
                                                    <tr>
                                                        <th scope="row">{getCurrentDate(item.send_date?.split(" ")[0])}</th>
                                                        <td>{item.invoice_number}</td>
                                                        <td>${parseFloat(item.paid_amount).toFixed(2)}</td>
                                                        {/* <td><a href={process.env.PUBLIC_URL + `/${props.Labcode}/printpdf/${item.id}`}><i className="fas fa-download mx-2 fs-6" style={{ color: `${Primarycolor}` }}></i></a></td> */}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {/* <button className="btn-btn-primary">
                                        <a href={process.env.PUBLIC_URL + `/${props.Labcode}/printpdf/${invid}`}><i className="fas fa-download mx-2 fs-6" style={{ color: `${Primarycolor}` }}></i><span className="fw-bold fs-6" style={{ color: `${Primarycolor}` }}>Download Receipt</span></a>
                                    </button> */}
                                </center>
                                <a href="#" className="btn btn-secondary mt-2 float-right" onClick={() => {
                                    invoice("Sent")
                                    setisPayment(false)
                                }}>{Activestep === 2 ? "Ok" : "Continue"}</a>
                            </>
                        }
                        {/* {errorMessage && <div>{errorMessage}</div>} */}
                    </form >
                </center >
            </>
        );
    };
    function MultiStepForm() {
        const isStepFailed = (step: number) => {
            return step === StepFailed;
        };

        return (
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={Activestep}>
                    {steps.map((label, index) => {
                        const labelProps: {
                            optional?: React.ReactNode;
                            error?: boolean;
                        } = {};
                        if (isStepFailed(index)) {
                            labelProps.optional = (
                                <Typography variant="caption" color="error">
                                    Payment Fail
                                </Typography>
                            );
                            labelProps.error = true;
                        }

                        return (
                            <Step key={label}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm />
                </Elements>
            </Box>
        );
    }
    async function selectedPayment(item, index, value) {
        var arr = [...Invoice]
        arr.map((data) => {
            if (data === item) {
                data.ischeck = value
            }
        })
        setInvoice(arr)

        let dueAmount = 0
        Invoice.map((item) => {
            if (item.ischeck === true) {
                dueAmount = parseFloat(dueAmount) + parseFloat(item.item.outstanding_amount)
            }
        })
        let Temp = parseFloat(dueAmount).toFixed(2)
        setAmountdue(Temp)
    }
    useEffect(() => {
        getInfo()
        if (Tabid === "1") {
            invoice("Sent")
        }
        if (Tabid === "2") {
            invoice("Closed")
            year()
        }
    }, [Tabid]);
    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    };
    const handleClosed = (event, value) => {
        invoice("Closed", "", `?page=${value}`)
    };
    const handleOpen = (event, value) => {
        invoice("Sent", "", `?page=${value}`)
    };
    let root = document?.documentElement;
    let Primarycolor = root?.style?.getPropertyValue('--primary-color',) ? root?.style?.getPropertyValue('--primary-color',) : "#6D3580";
    let Secoundrycolor = root?.style?.getPropertyValue('--secoundry-color') ? root?.style?.getPropertyValue('--secoundry-color') : "#CC4165";

    const TenantNameStr = localStorage.getItem('TenantNameApi')
    return (
        <>
            <LoaderComp isLoading={isLoading} color1={Primarycolor} color2={Secoundrycolor} />
            <Modal
                zIndex={999}
                isOpen={isPayment}
                backdropTransition={{ timeout: 100 }}
                // toggle={() => setisPayment(false)}
                centered={true}
                size="lg"
            >
                <ModalHeader toggle={() => setisPayment(false)}>Online Payment Process ${Amount}</ModalHeader>
                <ModalBody>
                    <MultiStepForm />
                </ModalBody>
            </Modal>

            <Modal
                zIndex={999}
                isOpen={isOpen}
                backdropTransition={{ timeout: 100 }}
                toggle={() => setisOpen(false)}
                centered={true}
                size="md"
            >
                <ModalHeader className="p-2" toggle={() => setisOpen(false)}>Invoice Detail</ModalHeader>
                <ModalBody>
                    <div className="col-12 patients-sidebar mb-2 p-1 " data-aos="zoom-in">
                        <div className="card">
                            <div className="card-header">
                                <strong className="float-start mt-1">{invid?.send_dt?.split(" ")[0].split("-")[1] + "/" + invid?.send_dt?.split(" ")[0].split("-")[2] + "/" + invid?.send_dt?.split(" ")[0].split("-")[0]}</strong>
                                {/* <strong className="float-start mt-1">{invid?.send_dt}</strong> */}
                                <strong className="float-right"><button className="btn btn-success" style={{ height: 30, padding: 2 }}>Pay $ {parseFloat(invid?.outstanding_amount).toFixed(2)}</button></strong>
                            </div>
                            <div className="card-body p-3">
                                <Row>
                                    <Col xs="8" md="9">
                                        <span>Total Charges</span>
                                    </Col>
                                    <Col xs="4" md="3">
                                        <span className="float-right">$ {parseFloat(invid?.billed_amount).toFixed(2)}</span>
                                    </Col>
                                    <p className="mt-1" style={{ opacity: 0.5 }}>The full price of services you received</p>
                                </Row>

                                {!invid?.adjustment == 0 &&
                                    <>
                                        <Row>
                                            <Col xs="8" md="9">
                                                <span>Insurance Adjustments</span>
                                            </Col>
                                            <Col xs="4" md="3">
                                                <span className="float-right">- $ {parseFloat(invid?.adjustment).toFixed(2)}</span>
                                            </Col>
                                            <p className="mt-1" style={{ opacity: 0.5 }}>Healthcare provider has agreed not to charge this</p>
                                        </Row>
                                    </>
                                }
                                {!invid?.paid_amount == 0 &&
                                    <Row>
                                        <Col xs="8" md="9">
                                            <span>Insurance Paid</span>
                                        </Col>
                                        <Col xs="4" md="3">
                                            <span className="float-right">- $ {parseFloat(invid?.paid_amount).toFixed(2)}</span>
                                        </Col>
                                        <p className="mt-1" style={{ opacity: 0.5 }}>The amount your insurance has already paid</p>
                                    </Row>
                                }
                                <Row>
                                    <Col xs="8" md="9">
                                        <span>Your Responsibility</span>
                                    </Col>
                                    <Col xs="4" md="3">
                                        <span className="float-right">$ {parseFloat(invid?.patient_payment).toFixed(2)}</span>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal >
            <div className="overflow-x-hidden main-container" style={{ backgroundColor: "#f3f3f3", paddingBottom: 10 }}>
                <div className="container p-2">
                    <Breadcrumb className="m-0 m-md-3 px-1 py-2 colorfor" >
                        <BreadcrumbItem href={process.env.PUBLIC_URL + `/${props.Labcode}/dashboard`} tag="a">
                            Dashboard
                        </BreadcrumbItem>
                        {Tabid === "1" &&
                            <BreadcrumbItem
                                onClick={() => TabContant("1")}
                                href="#"
                                tag="a"
                                active={Active1}
                            >
                                Open Invoices
                            </BreadcrumbItem>
                        }
                        {Tabid === "2" &&
                            <BreadcrumbItem
                                onClick={() => TabContant("2")}
                                href="#"
                                tag="a"
                                active={Active2}
                            >
                                Paid Invoices
                            </BreadcrumbItem>
                        }
                    </Breadcrumb>
                    <Row >
                        <Col xs="12" md="12" className="px-4">
                            <Nav tabs={true} >
                                < NavItem >
                                    <NavLink
                                        className={`${Active1} p-1`}
                                        onClick={() => {
                                            invoice("Sent")
                                            TabContant("1")
                                        }}
                                    >
                                        Open Invoices
                                    </NavLink>
                                </NavItem>
                                < NavItem >
                                    <NavLink
                                        className={`${Active2} p-1`}
                                        onClick={() => {
                                            invoice("Closed")
                                            TabContant("2")
                                        }}
                                    >
                                        Paid Invoices
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Row>
                </div >
                <div className="container p-2 p-md-2">
                    <TabContent className="p-2 p-md-3" activeTab={Tabid}>
                        <TabPane tabId="1" >
                            <Row>
                                <div className="col-12 col-md-6 " data-aos="fade-left" >
                                    <div className="card position-relative mb-3" style={{ backgroundColor: "#fff", boxShadow: '6px 2px 5px #d3d3d3', }}>
                                        <div>
                                            <div className="row m-0">
                                                <span className="col-3 p-1">Name :</span>
                                                <span className="col-9 p-1" style={{ textAlign: 'end', fontWeight: "600" }}>{UserInfo.first_name} {UserInfo.last_name} | {age} | {UserInfo.sex} </span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="row m-0">
                                                <span className="col-2 p-1">Lab :</span>
                                                <span className="col-10 p-1" style={{ textAlign: 'end', fontWeight: "600" }}>{TenantNameStr} | {userinfo.labcode}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card card-align">
                                        <div className="card-body">
                                            <h4 className="card-title text-center">Balance Due</h4>
                                            <p className="card-text fs-1 text-center"><b>$ {Amountdue ? Amountdue : `0.00`}</b></p>
                                            <a href="#"
                                                onClick={() => {
                                                    setActivestep(0)
                                                    step1("Payall", Amountdue)
                                                }}
                                                className="btn btn-success w-100 text-light">Pay Selected $ {Amountdue ? Amountdue : `0.00`}</a>
                                        </div>
                                    </div>
                                </div>
                            </Row>

                            <div className="pt-2 col-12 col-md-6" data-aos="zoom-in-right">
                                {Invoice?.map((item, index) => {
                                    let Temp = item.item.send_dt.split(" ");
                                    let Invoice_Date = Temp[0].split("-");
                                    let monthNumber = parseInt(Invoice_Date[1], 10);
                                    let monthNames = [
                                        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                                    ];
                                    let monthName = monthNames[monthNumber - 1];
                                    let datestring = `${monthName} ${Invoice_Date[2]}, ${Invoice_Date[0]}`;
                                    return (
                                        <Accordion flush open={open} toggle={toggle} className="row accindex mb-1">
                                            <FormGroup check className="fs-5 acccheck" style={{ paddingTop: 4 }}>
                                                <Input
                                                    className="check"
                                                    checked={item.ischeck}
                                                    onClick={(e) => selectedPayment(item, index, e.target.checked)}
                                                    name="checkbox"
                                                    type="checkbox"
                                                    value={JSON.stringify({ item })}
                                                />
                                            </FormGroup>
                                            <AccordionItem className="col-md-12">
                                                <AccordionHeader targetId={item.item.id}>
                                                    <div className="w-50" style={{ marginLeft: 30 }}>
                                                        <span className="fw-bold">
                                                            {datestring}
                                                        </span>
                                                    </div>
                                                    <div className="w-50">
                                                        <span className="float-right mx-3 fw-bold">
                                                            $ {parseFloat(item.item?.outstanding_amount).toFixed(2)}
                                                        </span>
                                                    </div>
                                                </AccordionHeader>
                                                <AccordionBody accordionId={item.item.id}>
                                                    <div>
                                                        <Row className="">
                                                            <Col xs="8" md="9">
                                                                <span>Total Charges</span>
                                                            </Col>
                                                            <Col xs="4" md="3">
                                                                <span className="float-right">$ {parseFloat(item.item.billed_amount).toFixed(2)}</span>
                                                            </Col>
                                                            <p className="mt-1" style={{ opacity: 0.5 }}>The full price of services you received</p>
                                                        </Row>
                                                        <hr className="m-0" />
                                                        {/* {!item.item.adjustment == 0 && */}
                                                        {item.item.show_adjustment == 1 &&
                                                            <>
                                                                <Row>
                                                                    <Col xs="8" md="9">
                                                                        <span>Insurance Adjustments</span>
                                                                    </Col>
                                                                    <Col xs="4" md="3">
                                                                        <span className="float-right">- $ {parseFloat(item.item.adjustment).toFixed(2)}</span>
                                                                    </Col>
                                                                    <p className="mt-1" style={{ opacity: 0.5 }}>Healthcare provider has agreed not to charge this</p>
                                                                </Row>
                                                                <hr className="m-0" />
                                                            </>
                                                        }
                                                        {/* {!item.item.paid_amount == 0 && */}
                                                        {item.item.show_insurance_paid == 1 &&
                                                            <>
                                                                <Row>
                                                                    <Col xs="8" md="9">
                                                                        <span>Insurance Paid</span>
                                                                    </Col>
                                                                    <Col xs="4" md="3">
                                                                        <span className="float-right">- $ {parseFloat(item.item.paid_amount).toFixed(2)}</span>
                                                                    </Col>
                                                                    <p className="mt-1" style={{ opacity: 0.5 }}>The amount your insurance has already paid</p>
                                                                </Row>
                                                                <hr className="m-0" />
                                                            </>
                                                        }
                                                        <Row>
                                                            <Col xs="8" md="9">
                                                                <span>Your Responsibility</span>
                                                            </Col>
                                                            <Col xs="4" md="3">
                                                                <span className="float-right">$ {parseFloat(item.item.patient_payment).toFixed(2)}</span>
                                                            </Col>
                                                        </Row>
                                                        <a href="#"
                                                            onClick={() => {
                                                                setActivestep(0)
                                                                step1("Single", parseFloat(item.item?.outstanding_amount).toFixed(2), item.item.id, item.item.parent_row_id, item.item.claim_id, item.item.invoice_number, item.item.bill_format, item.item.type, item.item.send_dt)
                                                            }}
                                                            className="btn btn-success mt-3 w-100">Pay $ {parseFloat(item.item?.outstanding_amount).toFixed(2)}</a>
                                                        <center>
                                                            <a href={process.env.PUBLIC_URL + `/${props.Labcode}/printpdf/${item.item?.id}`}><i className="fas fa-download mt-3 mx-2 fs-6" style={{ color: `${Primarycolor}` }}></i><span className="fw-bold fs-6" style={{ color: `${Primarycolor}`, cursor: 'pointer' }}>Download Invoice in PDF</span></a>
                                                        </center>
                                                    </div>
                                                </AccordionBody>
                                            </AccordionItem>
                                        </Accordion>
                                    )
                                })}
                                <Stack className="float-right" mt={3} spacing={2}>
                                    <Pagination count={isLast} onChange={handleOpen} />
                                </Stack>
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="pt-2 col-12 col-md-6" data-aos="zoom-in-right">
                                <div className="card position-relative mb-3" style={{ backgroundColor: "#fff", boxShadow: '6px 2px 5px #d3d3d3', }}>
                                    <div>
                                        <div className="row m-0">
                                            <span className="col-3 p-1">Name :</span>
                                            <span className="col-9 p-1" style={{ textAlign: 'end', fontWeight: "600" }}>{UserInfo.first_name} {UserInfo.last_name} | {age} | {UserInfo.sex} </span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="row m-0">
                                            <span className="col-2 p-1">Lab :</span>
                                            <span className="col-10 p-1" style={{ textAlign: 'end', fontWeight: "600" }}>{TenantNameStr} | {userinfo.labcode}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-md-2 m-2">
                                <div className="form-group">
                                    <select
                                        defaultValue={new Date().getFullYear()}
                                        onChange={(e) => invoice("Closed", e.target.value)}
                                        style={{ borderColor: "#d3d3d3", }}
                                        className="form-select">
                                        {Year?.map((item) => {
                                            return (
                                                <option value={item.year}>{item.year}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="pt-2 col-12 col-md-6 m-2" data-aos="zoom-in-right">
                                <Table>
                                    <thead style={{ backgroundColor: "#F7F7F8" }} >
                                        <tr>
                                            <th style={{ fontSize: 13, color: "#8F9CA9" }}>Invoice Date</th>
                                            <th style={{ fontSize: 13, color: "#8F9CA9" }}>Amount</th>
                                            <th style={{ fontSize: 13, color: "#8F9CA9" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Invoice?.map((item) => {
                                            let Temp = item.item.send_dt.split(" ");
                                            let Invoice_Date = Temp[0].split("-");
                                            let monthNumber = parseInt(Invoice_Date[1], 10);
                                            let monthNames = [
                                                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                                                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                                            ];
                                            let monthName = monthNames[monthNumber - 1];
                                            let datestring = `${monthName} ${Invoice_Date[2]}, ${Invoice_Date[0]}`;
                                            return (
                                                <tr>
                                                    <td style={{ fontWeight: '600', fontSize: 15, color: "#1D1D4F" }}> {datestring}</td>
                                                    <td>
                                                        $ {parseFloat(item.item.amount).toFixed(2)}
                                                    </td>
                                                    <td >
                                                        {/* <button
                                                            className="btn btn-primary ms-1"
                                                            style={{ width: 50, height: 30, padding: 0, justifyContent: 'center' }}>PDF</button> */}
                                                        <a href={process.env.PUBLIC_URL + `/${props.Labcode}/printpdf/${item.item?.id}`}><i className="fas fa-download fs-4" style={{ color: `${Primarycolor}`, cursor: 'pointer' }}></i></a>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table>
                                <Stack className="float-right" mt={3} spacing={2}>
                                    <Pagination count={isLast} onChange={handleClosed} />
                                </Stack>
                            </div>
                        </TabPane>
                    </TabContent >
                </div>
            </div >
        </>
    );
};

export default UserInvoice;