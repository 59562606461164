import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Button, ButtonGroup, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { setinsuranceDate, setinsuranceInfo } from "../../store/slices/insurance";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import { Autocomplete, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";


const StepTwo = forwardRef((props, ref, Called) => {
    const [isDisable, setisDisable] = useState(false)
    const [rSelected, setrSelected] = useState("")
    const [Selected_ins, setSelected_ins] = useState("")
    const [Insurance_id, setInsurance_id] = useState("")
    const [Member_id, setMember_id] = useState("")
    const [Group, setGroup] = useState("")
    const [Service, setService] = useState("")
    const [Effective_date, setEffective_date] = useState()
    const [Inslist, setInslist] = useState([])
    const [Ins_type, setIns_type] = useState([]);
    const [isPDF, setisPDF] = useState(false);
    const [imageUrlID, setimageUrlID] = useState(null);
    const [selectedImage2, setSelectedImage2] = useState(null);
    const [Flag2, setFlag2] = useState(0);
    const [Flag, setFlag] = useState(false);
    const [Type, setType] = useState(false);
    const [Documentfile, setDocumentfile] = useState("")
    const [end_date, setend_date] = useState("")

    //State For validation Flag
    const [insurance_groupFlag, setinsurance_groupFlag] = useState(false)
    const [insurance_policyFlag, setinsurance_policyFlag] = useState(false)
    const [insurance_codeFlag, setinsurance_codeFlag] = useState(false)

    const dispatch = useDispatch()
    const { insuranceInfo } = useSelector((state) => state.insurance);
    const { userinfo } = useSelector((state) => state.user);
    const idcard = React.useRef();
    const { code } = useParams();
    const { navigate } = useNavigate()

    function Submit() {
        let Temp = insuranceInfo
        let datevalue3 = ""
        if (Effective_date) {
            datevalue3 = new Date(`${Effective_date}`).getFullYear() + "/" + (new Date(`${Effective_date}`).getMonth() + 1) + "/" + new Date(`${Effective_date}`).getDate()
        }
        dispatch(
            setinsuranceInfo([{
                id: Temp[0].id,
                patient_id: Temp[0].patient_id,
                are_you: Temp[0].are_you,
                first_name: Temp[0].first_name,
                middle_name: Temp[0].middle_name,
                last_name: Temp[0].last_name,
                date_birth: Temp[0].date_birth,
                ssn_num: Temp[0].ssn_num,
                gender: Temp[0].gender,
                relation_with: Temp[0].relation_with,
                insurance_type: rSelected,
                selected_insurance: Selected_ins,
                insurance_id: Insurance_id,
                member_id: Member_id,
                group: Group,
                service: Service,
                effective_from: datevalue3,
                uploaded_document: imageUrlID,
                doc_type: selectedImage2?.type,
                document_file: Documentfile,
                insurance_document_id: Temp[0].insurance_document_id,
                end_date: Temp[0].end_date
            }])
        )
    }

    const printSomething = () => {
        if (props.Called === 2) {
            if (rSelected.trim().length < 1) {
                toast.error("Please Select Insurance Type")
            }
            else if (!Selected_ins || Selected_ins?.trim()?.length < 1 || Selected_ins === "Please Select Insurance") {
                toast.error("Please Select Insurance ")
            }
            else if (insurance_policyFlag && (Member_id?.trim().length < 1 || !Member_id)) {
                toast.error("Please Enter Member ID")
                return false;
            }
            else if (insurance_groupFlag && (Group?.trim().length < 1 || !Group)) {
                toast.error("Please Enter Group")
                return false;
            }
            else if (insurance_codeFlag && (Service?.trim().length < 1 || !Service)) {
                toast.error("Please Enter Service ID")
                return false;
            }
            else {
                Submit()
                return true;
            }
        } else {
            return false;
        }
    }

    function handleClick(type) {
        if (type === "ID") {
            idcard.current.value = ""
            setimageUrlID("")
            setisPDF(false)
            setDocumentfile(null)
        }
    }

    useImperativeHandle(ref, () => ({
        printSomething: printSomething,
        local: local
    }));

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                const base64String = e.target.result;
                //console.log(base64String); // This is your base64 representation of the file
                setDocumentfile(base64String)
            };
        }
    };

    const local = async () => {
        //console.log("Caled Local")
        let Temp = insuranceInfo
        await temp(Temp[0]?.insurance_type)
        if (Temp.length > 0) {
            await setrSelected(Temp[0]?.insurance_type)
            await setSelected_ins(Temp[0].selected_insurance)
            await setInsurance_id(Temp[0].insurance_id)
            await setMember_id(Temp[0].member_id)
            await setGroup(Temp[0].group)
            await setService(Temp[0].service)
            await setEffective_date(Temp[0]?.effective_from ? Temp[0]?.effective_from : Effective_date)
            await setType(Temp[0].doc_type)
            await setend_date(Temp[0]?.end_date)
            await setDocumentfile(Temp[0].document_file)
            if (Temp[0].doc_type === "application/pdf") {
                setisPDF(true)
            }
            await setimageUrlID(Temp[0].uploaded_document)
            setFlag(true)
            if (Temp[0]?.id) {
                setisDisable(!isDisable)
            }
            if (Temp[0]?.insurance_document_id) {
                checkCard(Temp[0]?.insurance_document_id)
            }

        }
    }

    async function temp(type) {
        let Data = []
        Data = JSON.parse(await localStorage.getItem("Listdata"))
        let Temparr = []
        if (Data) {
            Data.map((item) => {
                if (item?.insurance_type === type) {
                    Temparr.push({ name: item.name, id: item.id, format: item.billing_format_id, tenantID: item.tenant_id })
                    setIns_type(Temparr)
                }
            })
        }
    }

    async function InsuranceList() {
        let BASEURL = await localStorage.getItem("BaseApiUrl")
        let Patient_ID = userinfo?.uname
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/insuranceslist`,
                {
                    method: 'POST',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "patient_id": Patient_ID
                    }),
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response Insurance List", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                setInslist(Temp)
                                localStorage.setItem("Listdata", JSON.stringify(Temp))
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    async function checkCard(doc) {
        let BASEURL = await localStorage.getItem("BaseApiUrl")
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/showpatientprivatedocument/${doc}`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            if (responseJson.document_url) {
                                if (responseJson.document_url.includes(".pdf")) {
                                    setisPDF(true)
                                }
                                setimageUrlID(responseJson.document_url)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }

    }

    const options = Ins_type.map((option) => {
        const firstLetter = option.name.toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter.charAt(0),
            ...option,
        };
    });

    async function validationCheck(billformat, tenantid) {
        let BASEURL = await localStorage.getItem("BaseApiUrl")

        let Token = userinfo?.token
        let Patient_ID = userinfo?.uname
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/getbillingformatclaimruleslist`,
                {
                    method: 'POST',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "tenant_id": tenantid,
                        "billing_format_id": billformat,
                        "patient_id": Patient_ID,
                        "token": Token
                    })
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then(async (responseJson) => {
                            if (responseJson?.message == "Token Expired!") {
                                const keysToRemove = ['BaseApiUrl', 'TenantNameApi', 'TenantID', 'isStepOne', 'Whoare', 'Listdata'];
                                navigate(process.env.PUBLIC_URL + `/${code}}`);
                                for (const key of keysToRemove) {
                                    await localStorage.removeItem(`${key}`);
                                }

                            }
                            //console.log("resopsnse JSON", responseJson)
                            let insurance_group = responseJson.data.filter(item => item.field_name == "insurance_group")
                            let insurance_policy = responseJson.data.filter(item => item.field_name == "insurance_policy")
                            let insurance_code = responseJson.data.filter(item => item.field_name == "insurance_code")
                            if (insurance_group.length) {
                                setinsurance_groupFlag(insurance_group[0].key_value === "1" ? true : false)
                            }
                            if (insurance_policy.length) {
                                setinsurance_policyFlag(insurance_policy[0].key_value === "1" ? true : false)
                            }
                            if (insurance_code.length) {
                                setinsurance_codeFlag(insurance_code[0].key_value === "1" ? true : false)
                            }

                            //console.log("KEY++++++++++++++++++++++++++", insurance_group,
                            // insurance_policy,
                            // insurance_code)
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }

    }

    useEffect(() => {
        InsuranceList()
        let Emp = 0
        if (!Flag2 == 0) {
            setimageUrlID(URL.createObjectURL(selectedImage2))
            Emp = 1
        }
        if (!Emp == 1) {
            local()
        }
    }, [Flag2]);

    let root = document?.documentElement;
    let Primarycolor = root?.style?.getPropertyValue('--primary-color',) ? root?.style?.getPropertyValue('--primary-color',) : "#6D3580";
    let Secoundrycolor = root?.style?.getPropertyValue('--secoundry-color') ? root?.style?.getPropertyValue('--secoundry-color') : "#CC4165";

    return (
        <>
            <div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    color
                />
            </div>
            <form>
                <div className="row">
                    <div className="col-sm-12 col-md-12 pt-2">
                        <div className="mt-1 col-sm-12 col-md-8 col-12">What type of Insurance Plan do you have? <span className="text-danger">*</span></div>
                        <ButtonGroup
                            className="my-2"
                            size="sm"
                        >
                            <Button
                                disabled={isDisable}
                                style={{
                                    backgroundColor: rSelected === "Medicare" ? `${Secoundrycolor}` : "#fff",
                                    color: rSelected === "Medicare" ? "#fff" : "#000"
                                }}
                                outline
                                onClick={() => {
                                    setrSelected("Medicare")
                                    temp("Medicare")
                                }}
                                defaultValue={rSelected}
                                active={rSelected === "Medicare"}
                                value="Medicare"
                            >
                                Medicare
                            </Button>
                            <Button
                                disabled={isDisable}
                                style={{
                                    backgroundColor: rSelected === "Medicaid" ? `${Secoundrycolor}` : "#fff",
                                    color: rSelected === "Medicaid" ? "#fff" : "#000"
                                }}
                                outline
                                onClick={() => {
                                    setrSelected("Medicaid")
                                    temp("Medicaid")
                                }}
                                defaultValue={rSelected}
                                active={rSelected === "Medicaid"}
                                value="Medicaid"
                            >
                                Medicaid
                            </Button>
                            <Button
                                disabled={isDisable}
                                style={{
                                    backgroundColor: rSelected === "Commercial" ? `${Secoundrycolor}` : "#fff",
                                    color: rSelected === "Commercial" ? "#fff" : "#000"
                                }}
                                outline
                                onClick={() => {
                                    setrSelected("Commercial")
                                    temp("Commercial")
                                }}
                                defaultValue={rSelected}
                                active={rSelected === "Commercial"}
                                value="Commercial"
                            >
                                Commercial
                            </Button>
                            <Button
                                disabled={isDisable}
                                style={{
                                    backgroundColor: rSelected === "Other" ? `${Secoundrycolor}` : "#fff",
                                    color: rSelected === "Other" ? "#fff" : "#000"
                                }}
                                outline
                                onClick={() => {
                                    setrSelected("Other")
                                    temp("Other")
                                }}
                                defaultValue={rSelected}
                                active={rSelected === "Other"}
                                value="Other"
                            >
                                Other
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div className="col-sm-12 col-md-12 pb-2 mt-2 pe-1">
                        <div className="form-group custom-autocomplete">
                            {Flag &&
                                <Autocomplete
                                    size="small"
                                    defaultValue={() => {
                                        return options.filter(item => item.id == Insurance_id).length > 0 ? options.filter(item => item.id == Insurance_id)[0] : {
                                            "name": "",
                                            "id": "",
                                            "firstLetter": ""
                                        };
                                    }}
                                    onChange={(event, vale) => {
                                        validationCheck(vale?.format, vale?.tenantID)
                                        //console.log("evenete eeeee", event, vale)
                                        setSelected_ins(vale?.name,
                                            setInsurance_id(vale?.id))
                                    }}
                                    id="size-small-outlined"
                                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                                    groupBy={(option) => option.firstLetter}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Please Select Insurance" />}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-sm-6 col-md-4 pb-2 mt-2 ">
                        <div className="form-group">
                            <label for="first_name">Member ID <span className={"text-danger " + (insurance_policyFlag ? "d-inline-block" : "d-none")}>*</span></label>
                            <input
                                onChange={(e) => setMember_id(e.target.value)}
                                defaultValue={Member_id}
                                type="text"
                                autocomplete="nope"
                                className="form-control"
                                id="insurance_policy"
                                name="first_name"
                                formControlName="first_name" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 pb-2 mt-2">
                        <div className="form-group">
                            <label for="middle_name">Group <span className={"text-danger " + (insurance_groupFlag ? "d-inline-block" : "d-none")}>*</span></label>
                            <input
                                onChange={(e) => setGroup(e.target.value)}
                                defaultValue={Group}
                                type="text"
                                autocomplete="nope"
                                className="form-control"
                                id="insurance_group"
                                name="middle_name"
                                formControlName="middle_name" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 pb-2 mt-2 pe-1">
                        <div className="form-group">
                            <label for="first_name">Service <span className={"text-danger " + (insurance_codeFlag ? "d-inline-block" : "d-none")}>*</span></label>
                            <input
                                onChange={(e) => setService(e.target.value)}
                                defaultValue={Service}
                                type="text"
                                autocomplete="nope"
                                className="form-control"
                                id="insurance_code"
                                name="first_name"
                                formControlName="first_name" />
                        </div>
                    </div>
                </div>
                <div className="row pt-3">
                    <div className="col-sm-6 col-md-4 py-2">
                        <div className="form-group">
                            <label for="first_name">Effective From<span className="text-danger">*</span></label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={[
                                    'DatePicker',
                                    'MobileDatePicker',
                                    'DesktopDatePicker',
                                    'StaticDatePicker',
                                ]}>
                                    <DemoItem>
                                        <MobileDatePicker
                                            views={['year', 'month', 'day']}
                                            onAccept={(date) => {
                                                if (date) {
                                                    let datevalue3 = new Date(`${date}`).getFullYear() + "/" + (new Date(`${date}`).getMonth() + 1) + "/" + new Date(`${date}`).getDate()
                                                    dispatch(
                                                        setinsuranceDate(datevalue3)
                                                    )
                                                    setEffective_date(datevalue3)
                                                }
                                            }}
                                            className="customDatePickerClass"
                                            value={insuranceInfo[0]?.effective_from ?
                                                dayjs(new Date(`${insuranceInfo[0]?.effective_from}`).getFullYear() + "/" + (new Date(`${insuranceInfo[0]?.effective_from}`).getMonth() + 1) + "/" + new Date(`${insuranceInfo[0]?.effective_from}`).getDate())
                                                : Effective_date ?
                                                    dayjs(`${Effective_date}`)
                                                    : setEffective_date(dayjs(`${new Date().getFullYear()}-01-01`))}
                                        />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Row>
                        <Col md="8" sm="12" xs="12">
                            {(insuranceInfo[0].insurance_document_id && imageUrlID) &&
                                <>
                                    {(imageUrlID && !isPDF) &&
                                        <img alt={"image-14"} className="wimg" src={imageUrlID} />
                                    }
                                    {isPDF &&
                                        <>
                                            <p></p>
                                            <object
                                                className="data-object"
                                                data={imageUrlID}
                                                type="application/pdf"
                                                width="100%"
                                                height="380px"
                                                style={{ alignContent: 'center' }}>
                                                <p>
                                                    <a href={imageUrlID} target="_blank">Link to the attached PDF!</a>
                                                </p>
                                            </object>
                                        </>
                                    }
                                </>
                            }
                            {(!insuranceInfo[0].insurance_document_id) &&
                                <>
                                    <label className="my-2">Upload Insurance Card</label>
                                    <label for="" className="drop-container">
                                        {(imageUrlID && !isPDF) &&
                                            <img alt={"image-15"} className="wimg" src={imageUrlID} />
                                        }
                                        {isPDF &&
                                            <>
                                                <p></p>
                                                <object
                                                    className="data-object"
                                                    data={imageUrlID}
                                                    type="application/pdf"
                                                    width="100%"
                                                    height="380px"
                                                    style={{ alignContent: 'center' }}>
                                                    <p>
                                                        <a href={imageUrlID} target="_blank">Link to the attached PDF!</a>
                                                    </p>
                                                </object>
                                            </>
                                        }
                                        {!imageUrlID &&
                                            <>
                                                <span className="drop-title">Drop Files Here</span>
                                                <span className="fw-bold">OR</span>
                                            </>
                                        }
                                        {imageUrlID &&
                                            <button
                                                className="btn btn-sm ms-2 btn-danger imgicon"
                                                onClick={() => handleClick("ID")}>
                                                <i className="fa fa-times" style={{ zIndex: 99 }}></i>
                                            </button>
                                        }
                                        <input ref={idcard}
                                            onChange={(e) => {
                                                handleFileSelect(e)
                                                if (e.target.files[0].type === "application/pdf") {
                                                    setisPDF(true)
                                                    setSelectedImage2(e.target.files[0])
                                                    setFlag2(Flag2 + 1)
                                                }
                                                else {
                                                    setisPDF(false)
                                                    setSelectedImage2(e.target.files[0])
                                                    setFlag2(Flag2 + 1)
                                                }
                                            }}
                                            type="file"
                                            id="images"
                                            accept="image/jpeg,image/png,application/pdf" required />
                                    </label>
                                </>}
                        </Col>
                    </Row>
                </div>
            </form >
        </>
    );
});

export default StepTwo;
