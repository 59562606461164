import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";

const CreditCard = ({ InsuranceName,
    MemberID,
    Group,
    Service,
    Effective_From,
    Name,
    Gender,
    Realation,
    DOB }) => {

    const [isHovered, setIsHovered] = useState(false);
    const handleCardHover = () => { setIsHovered(!isHovered); };

    return (
        <>
            <center>
                <div className={`credit-card ${isHovered ? 'hovered' : ''}`} onMouseEnter={handleCardHover} onMouseLeave={handleCardHover}>
                    <div className="card-front" >
                        <div className="card-logo  px-3 d-flex" >
                            <span className="ps-4 text-light fw-bold fs-5">{InsuranceName?.slice(0, 20) + '...'}</span>
                        </div>
                        <div className="card-chip d-flex w-100">
                            <span className=" text-light w-50">Subscriber Name</span>
                            <span className="fw-bold ps-10 text-light w-50"> {Name}</span>
                        </div>
                        <div className="card-chip d-flex w-100">
                            <span className=" text-light w-50">Member ID</span>
                            <span className="fw-bold ps-10 text-light w-50"> {MemberID}</span>
                        </div>
                        <div className="card-chip d-flex w-100">
                            <span className=" text-light w-50">Group</span>
                            <span className="fw-bold ps-10 text-light w-50"> {Group}</span>
                        </div>
                        <div className="card-chip d-flex w-100">
                            <span className=" text-light w-50">Service</span>
                            <span className="fw-bold ps-10 text-light w-50"> {Service}</span>
                        </div>
                        <div className="card-chip d-flex w-100">
                            <span className=" text-light w-50">Effective Date</span>
                            <span className="fw-bold ps-10 text-light w-50"> {(new Date(`${Effective_From}`).getMonth() + 1) + "/" + new Date(`${Effective_From}`).getDate() + "/" + new Date(`${Effective_From}`).getFullYear()}</span>
                        </div>

                    </div>
                    <div className={`card-back ${isHovered ? 'show' : ''}`}>
                        <div className="card-details">
                            <div className="card-holder">
                                <div className="label">Subscriber Holder</div>
                                <div className="name">{Name}</div>
                            </div>
                            <div className="expiry-date">
                                <div className="label">Relation</div>
                                <div className="date">{Realation === "SE" && "Self"}
                                    {Realation === "SP" && "Spouse"}
                                    {Realation === "DE" && "Child"}
                                    {Realation === "OT" && "Other"}</div>
                            </div>
                            <div className="d-flex">
                                <div className="expiry-date">
                                    <div className="label px-3">Gender</div>
                                    <div className="date">{Gender === "F" && "Female"}
                                        {Gender === "M" && "Male"}
                                        {Gender === "O" && "Other"}
                                        {Gender === "U" && "Unknown"}</div>
                                </div>
                                <div className="expiry-date">
                                    <div className="label px-3">Date Of Birth</div>
                                    <div className="date">{DOB}</div>
                                </div>
                            </div>
                            <div className="cvv">{MemberID}</div>
                        </div>
                    </div>
                </div>
            </center>
        </>
    );
};


const StepThree = forwardRef((props, ref, Called) => {
    const { userinfo } = useSelector((state) => state.user);
    const { insuranceInfo } = useSelector((state) => state.insurance);
    const [Data, setData] = useState([])

    useEffect(() => {
        let Temp = insuranceInfo
        if (Temp) {
            setData(Temp)
        }
    }, []);

    const printSomething = async () => {
        let BASEURL = await localStorage.getItem("BaseApiUrl")
        let Token = userinfo?.token
        let Patient_ID = userinfo?.uname
        let Temp = await insuranceInfo
        let result = false
        if (props.Called === 3) {
            if (Temp[0]?.id) {
                try {
                    await fetch(`${BASEURL}/apipatient/public/v1/createandupdatepatientinsurances`,
                        {
                            method: 'POST',
                            headers: {
                                // Authorization: 'Bearer ' + Token,
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                "id": Temp[0]?.id,
                                "patient_id": Temp[0]?.patient_id,
                                "relation": Temp[0]?.relation_with,
                                "last_name": Temp[0]?.last_name,
                                "first_name": Temp[0]?.first_name,
                                "middle_name": Temp[0]?.middle_name,
                                "dob": Temp[0]?.date_birth,
                                "start_date_time": Temp[0]?.effective_from,
                                "end_date_time": Temp[0]?.end_date,
                                // "end_date_time": null,
                                // "sequence_no": null,
                                "ssn": Temp[0]?.ssn_num,
                                "gender": Temp[0]?.gender,
                                "insurance_name": Temp[0]?.selected_insurance,
                                "insurance_code": Temp[0]?.service,
                                "insurance_group": Temp[0]?.group,
                                "insurance_policy": Temp[0]?.member_id,
                                "insurance_id": Temp[0]?.insurance_id,
                                "document_type": Temp[0]?.document_file && "1",
                                "document_file": Temp[0]?.document_file,
                                "token": Token,
                                "patient_id": Patient_ID,
                            }),
                        })
                        .then((response) => {
                            //console.log(`add insurance`, response)
                            if (response.status === 200) {
                                response.json().then((responseJson) => {
                                    //console.log(`Get Response For Edit insurance`, responseJson);
                                    localStorage.setItem("isStepOne", "")
                                });
                                result = true;
                            } else {
                                //console.log("Res False")
                                result = false;
                            }
                            if (response.status === 500) {
                                response.json().then((responseJson) => {
                                    toast.error(responseJson.message)
                                });
                            }
                        })
                        .catch((error) => {
                            console.log("Fetch Error", error);
                        });
                } catch (error) {
                    console.log('Try Error', error);
                }
            } else {
                //console.log("Console .logggg NEwwwwwwwwwwwwwwwwwwwwwwwwww", JSON.stringify({
                //     "id": "",
                //     "patient_id": Temp[0]?.patient_id,
                //     "relation": Temp[0]?.relation_with,
                //     "last_name": Temp[0]?.last_name,
                //     "first_name": Temp[0]?.first_name,
                //     "middle_name": Temp[0]?.middle_name,
                //     "dob": Temp[0]?.date_birth,
                //     "start_date_time": Temp[0]?.effective_from,
                //     "end_date_time": null,
                //     "sequence_no": "1",
                //     "ssn": Temp[0]?.ssn_num,
                //     "gender": Temp[0]?.gender,
                //     "insurance_name": Temp[0]?.selected_insurance,
                //     "insurance_code": Temp[0]?.service,
                //     "insurance_group": Temp[0]?.group,
                //     "insurance_policy": Temp[0]?.member_id,
                //     "insurance_id": Temp[0]?.insurance_id,
                //     "document_type": "1",
                //     "document_file": Temp[0]?.document_file
                // }))
                try {
                    await fetch(`${BASEURL}/apipatient/public/v1/createandupdatepatientinsurances`,
                        {
                            method: 'POST',
                            headers: {
                                // Authorization: 'Bearer ' + Token,
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                "id": "",
                                "patient_id": Temp[0]?.patient_id,
                                "relation": Temp[0]?.relation_with,
                                "last_name": Temp[0]?.last_name,
                                "first_name": Temp[0]?.first_name,
                                "middle_name": Temp[0]?.middle_name,
                                "dob": Temp[0]?.date_birth,
                                "start_date_time": Temp[0]?.effective_from,
                                "end_date_time": null,
                                "sequence_no": null,
                                "ssn": Temp[0]?.ssn_num,
                                "gender": Temp[0]?.gender,
                                "insurance_name": Temp[0]?.selected_insurance,
                                "insurance_code": Temp[0]?.service,
                                "insurance_group": Temp[0]?.group,
                                "insurance_policy": Temp[0]?.member_id,
                                "insurance_id": Temp[0]?.insurance_id,
                                "document_type": "1",
                                "document_file": Temp[0]?.document_file,
                                "token": Token,
                                "patient_id": Patient_ID,
                            }),
                        })
                        .then((response) => {
                            //console.log(`add insurance`, response)
                            if (response.status === 200) {
                                response.json().then((responseJson) => {
                                    //console.log(`Get Response For add insurance`, responseJson);
                                    localStorage.setItem("isStepOne", "")
                                });
                                result = true;
                            } else {
                                //console.log("Res False")
                                result = false;
                            }
                            if (response.status === 500) {
                                response.json().then((responseJson) => {
                                    toast.error(responseJson.message)
                                });
                            }
                        })
                        .catch((error) => {
                            console.log("Fetch Error", error);
                        });
                } catch (error) {
                    console.log('Try Error', error);
                }
            }
            return result;
        } else {
            return false;
        }
    }

    useImperativeHandle(ref, () => ({
        printSomething: printSomething
    }));

    return (
        <>
            <div className="row">
                <div className="col-auto p-2 d-md-none">
                    <CreditCard
                        InsuranceName={Data[0]?.selected_insurance}
                        MemberID={Data[0]?.member_id}
                        Group={Data[0]?.group}
                        Service={Data[0]?.service}
                        Effective_From={Data[0]?.effective_from}
                        Name={Data[0]?.first_name + " " + Data[0]?.last_name}
                        Gender={Data[0]?.gender}
                        Realation={Data[0]?.relation_with}
                        DOB={Data[0]?.date_birth}
                    />
                </div>
                <div className="col-6 d-sm-none d-md-block">
                    <div className="card">
                        <div className="card-header bg-light-custom">
                            <p className="fw-bold text-center m-0 text-light">Insurance Information </p>
                        </div>
                        <div className="card-body p-2">
                            <Row>
                                <Col md="5" sm="7" xs="6" className="mt-2 ">
                                    <label>Insurance</label>
                                </Col>
                                <Col md="6" sm="6" xs="6" className="mt-2">
                                    <label className="fw-bold"> {Data[0]?.selected_insurance}</label>
                                </Col>

                                <Col md="5" sm="7" xs="6" className="mt-2 ">
                                    <label>Member ID</label>
                                </Col>
                                <Col md="6" sm="6" xs="6" className="mt-2">
                                    <label className="fw-bold"> {Data[0]?.member_id}</label>
                                </Col>

                                <Col md="5" sm="7" xs="6" className="mt-2">
                                    <label >Group / Service</label>
                                </Col>
                                <Col md="6" sm="6" xs="6" className="mt-2">
                                    <label className="fw-bold"> {Data[0]?.group}{" "}/{""}{Data[0]?.service}</label>
                                </Col>
                                {/* <Col md="6" sm="6" xs="6" className="mt-2">
                                    <label >Service</label>
                                </Col>
                                <Col md="6" sm="6" xs="6" className="mt-2">
                                    <label className="fw-bold"> {Data[0]?.service}</label>
                                </Col> */}
                                <Col md="5" sm="7" xs="6" className="mt-2">
                                    <label >Effective Date</label>
                                </Col>
                                <Col md="6" sm="6" xs="6" className="mt-2">
                                    {/* <label className="fw-bold"> {Data[0]?.effective_from.split("-")[1] + "/" + Data[0]?.effective_from.split("-")[2] + "/" + Data[0]?.effective_from.split("-")[0]}</label> */}
                                    <label className="fw-bold"> {(new Date(`${Data[0]?.effective_from}`).getMonth() + 1) + "/" + new Date(`${Data[0]?.effective_from}`).getDate() + "/" + new Date(`${Data[0]?.effective_from}`).getFullYear()}</label>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="col-6 d-sm-none d-md-block">
                    <div className="card">
                        <div className="card-header bg-light-custom">
                            <p className="fw-bold text-center m-0 text-light">Subscriber Information </p>
                        </div>
                        <div className="card-body p-2">
                            <Row>
                                <Col md="6" sm="6" xs="6" className="mt-2 ">
                                    <label>Holder Name</label>
                                </Col>
                                <Col md="6" sm="6" xs="6" className="mt-2">
                                    <label className="fw-bold"> {Data[0]?.first_name}{" "}{Data[0]?.middle_name}{" "}{Data[0]?.last_name}</label>
                                </Col>

                                <Col md="6" sm="6" xs="6" className="mt-2 ">
                                    <label>Gender</label>
                                </Col>
                                <Col md="6" sm="6" xs="6" className="mt-2">
                                    <label className="fw-bold"> {Data[0]?.gender}</label>
                                </Col>

                                <Col md="6" sm="6" xs="6" className="mt-2 ">
                                    <label>Relation With </label>
                                </Col>
                                <Col md="6" sm="6" xs="6" className="mt-2">
                                    <label className="fw-bold">
                                        {Data[0]?.relation_with === "SE" && "Self"}
                                        {Data[0]?.relation_with === "SP" && "Spouse"}
                                        {Data[0]?.relation_with === "DE" && "Child"}
                                        {Data[0]?.relation_with === "OT" && "Other"}</label>
                                </Col>
                                <Col md="6" sm="6" xs="6" className="mt-2 ">
                                    <label>Date Of Birth</label>
                                </Col>
                                <Col md="6" sm="6" xs="6" className="mt-2">
                                    {/* <label className="fw-bold"> {Data[0]?.date_birth}</label> */}
                                    <label className="fw-bold"> {(new Date(`${Data[0]?.date_birth}`).getMonth() + 1) + "/" + new Date(`${Data[0]?.date_birth}`).getDate() + "/" + new Date(`${Data[0]?.date_birth}`).getFullYear()}</label>
                                </Col>

                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

export default StepThree;
