import { ThreeCircles, } from 'react-loader-spinner'
import '../Loader/loader.scss'

const LoaderComp = (props, isLoading, color1, color2) => {
    return (
        <div className={props.isLoading && "loader-wrapper"}>
            <div className='pos-abs-50-50'>
                <ThreeCircles
                    height="100"
                    width="100"
                    color={props.color1 ? props.color1 : "#6D3580"}
                    wrapperclassName=""
                    visible={props.isLoading}
                    ariaLabel="three-circles-rotating"
                    outerCircleColor={props.color2 ? props.color2 : "#CC4165"}
                    innerCircleColor={props.color1 ? props.color1 : "#6D3580"}
                    middleCircleColor={props.color2 ? props.color2 : "#CC4165"}
                />
            </div>
        </div>
    );
}
export default LoaderComp;