import React, { useEffect, useState } from "react";
import {
    ModalBody,
    ModalHeader,
    Modal,
} from "reactstrap";
import "../CSS/footer.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { setUserInfo } from "../store/slices/user";
import { setinsuranceInfo } from "../store/slices/insurance";


const ContactUs = ({ isOpen, ContactusToggele }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { code } = useParams();
    const [Temp, setTemp] = useState(false)
    const { userinfo } = useSelector((state) => state.user);
    const [Address, setAddress] = useState("")
    const [Phonenumber, setPhonenumber] = useState("")

    useEffect(() => {
        checkLab()
        if (userinfo.labcode || code) {
            setTemp(true)
        }
        else {
            setTemp(false)
        }
    }, []);

    async function checkLab() {
        let Token = userinfo?.token
        let Patient_ID = userinfo?.uname
        let labcode = userinfo?.labcode

        const Baseurl = await JSON.parse(localStorage.getItem("Baseurl"))
        ////console.log("Baseurl", Baseurl)

        //console.log("match CONTACTUS mODAL labcodeeeee", labcode,)
        //console.log("match CONTACTUS mODAL labcodeeeee", code,)

        const match = Baseurl?.find(
            (item) => item.laboratory_code === (labcode ? labcode : code)
        );

        //console.log("match CONTACTUS mODAL", match, labcode ? labcode : code)
        if (match) {
            localStorage.setItem("BaseApiUrl", match?.base_api_url)
            localStorage.setItem("ReportApiUrl", match?.base_app_url)
            localStorage.setItem("TenantNameApi", match?.name)
            let BASEURL = await localStorage.getItem("BaseApiUrl")
            try {
                await fetch(`${BASEURL}/apipatient/public/v1/tenantsinfo`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "laboratory_code": labcode ? labcode : code,
                            "token": Token,
                            "patient_id": Patient_ID,
                            "with_auth": Patient_ID ? "yes" : "no",
                        }),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                //console.log("Get Response User Info CONTACTUS MOADL", responseJson);
                                if (responseJson.data) {
                                    let Temp = responseJson.data
                                    if (Temp.id) {
                                        setAddress(Temp.addresses?.data[0]?.full_address)
                                        setPhonenumber(Temp.phone_number)

                                        let root = document?.documentElement;
                                        root?.style?.setProperty('--primary-color', Temp?.primary_color);
                                        root?.style?.setProperty('--secoundry-color', Temp?.secondary_color);
                                    }
                                }
                            });
                        }
                        else if (response.status === 500) {
                            response.json().then(async (responseJson) => {
                                if (responseJson.message == "Token Expired!") {
                                    const keysToRemove = ['TenantID', 'isStepOne', 'Whoare', 'Listdata'];
                                    dispatch(
                                        setUserInfo([]),
                                        setinsuranceInfo([])
                                    )
                                    // navigate(process.env.PUBLIC_URL + `/${userinfo.labcode || code}`);
                                    navigate(process.env.PUBLIC_URL + `/${code || userinfo.labcode}`);
                                    for (const key of keysToRemove) {
                                        await localStorage.removeItem(`${key}`);
                                    }
                                    // localStorage.clear()
                                    // toast.success("Session Time Out Please Login Again !!")
                                }
                            });
                        } else {
                            toast.error("Something Went Wrong !!")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        } else {
            //console.log("inside Else to pass to baseuRL Labpage")
        }
    }

    return (
        < div >
            <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                color
            />
            <Modal
                isOpen={isOpen}
                backdropTransition={{ timeout: 100 }}
                toggle={ContactusToggele}
                centered={true}
                size="md"
            >
                <ModalHeader toggle={ContactusToggele}>Contact Us</ModalHeader>
                <ModalBody>
                    <div className="table-responsive">
                        {Temp ?
                            <table className="table table-borderless text-center" >
                                <tr>
                                    <td style={{ width: "30%" }}>Address <i className="ml-2 fa fa-map-marker-alt"></i></td>
                                    <td style={{ width: "70%" }}><p className="m-0">{Address}</p></td>
                                </tr>
                                <tr>
                                    <td style={{ width: "30%" }}>Phone <i className="ml-2 fa fa-phone-alt"></i></td>
                                    <td style={{ width: "70%" }}><a href={`tel:${Phonenumber}`}>{"(" + Phonenumber?.slice(0, 3) + ")" + "-" + Phonenumber?.slice(3, 6) + "-" + Phonenumber?.slice(6, 10)}</a></td>
                                </tr>
                            </table> :
                            <table className="table table-borderless text-center" >
                                <tr>
                                    <td style={{ width: "30%" }}>Address <i className="ml-2 fa fa-map-marker-alt"></i></td>
                                    <td style={{ width: "70%" }}><p className="m-0">357 Crystal Downs Way, Suwanee, GA-30024</p></td>
                                </tr>
                                <tr>
                                    <td style={{ width: "30%" }}>Mail <i className="ml-2 fa fa-envelope"></i></td>
                                    <td style={{ width: "70%" }}><a href="mailto:sales@healthsoftus.com">sales@healthsoftus.com</a></td>
                                </tr>
                                <tr>
                                    <td style={{ width: "30%" }}>Phone <i className="ml-2 fa fa-phone-alt"></i></td>
                                    <td style={{ width: "70%" }}><a href="tel:678-622-6662">(678) 622-6662</a></td>
                                </tr>
                            </table>
                        }
                    </div>
                </ModalBody>
            </Modal>
        </div >
    );
};

export default ContactUs;