import React, { useEffect, useState, } from "react";
import {
    Breadcrumb,
    BreadcrumbItem,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Button,
} from "reactstrap";
import "../CSS/invoice.scss"
import '../components/Loader/loader.scss'
import $ from "jquery";
import LoaderComp from "./Loader/Loader";
import { useSelector } from "react-redux";
import { getSplitDate } from "../Global/Global";

const UserReports = (props, Labcode) => {
    //Tab State
    const [Active1, setActive1] = useState("active")
    const [Active2, setActive2] = useState("")
    const [Active3, setActive3] = useState("")

    //Accordian State
    const [Tabid, setTabid] = useState("1")
    //Report Response
    const [allReports, setallReport] = useState([]);
    const [UserInformation, setUserInfo] = useState([]);
    const [age, setage] = useState(null)
    const [displayedReports, setDisplayedReports] = useState([]);
    const [itemsToLoad, setItemsToLoad] = useState(15);
    const [LicenceFlag, setLicenceFlag] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const { userinfo } = useSelector((state) => state.user);
    const Patient_ID = userinfo?.uname;

    //TAB 2
    const [openMainHeader, setOpenMainHeader] = useState('');
    const [openCMP, setOpenCMP] = useState('');
    const [testTypeSelected, settestTypeSelected] = useState(1);
    const [ResultData, setResultData] = useState([]);
    const [ResultDataTest, setResultDataTest] = useState([]);

    const [test, settest] = useState(false)

    async function TabContant(TAB) {
        if (TAB === "1") {
            setActive1("active")
            setActive2("")
            setActive3("")
            setTabid("1")
        }
        if (TAB === "2") {
            setActive1("")
            setActive2("active")
            setActive3("")
            setTabid("2")
        }
        if (TAB === "3") {
            setActive1("")
            setActive2("")
            setActive3("active")
            setTabid("3")
        }
    }
    useEffect(() => {

        setTimeout(() => {
            $(".accordion-body").addClass('pddingset')
        }, 2000);


        getInfo()
        let weburl = window.location.href
        let weburl_match = weburl.includes("?")
        // console.log("weburl_matchweburl_match", weburl)

        if (weburl_match) {
            let docname = weburl.split("?")[1]
            getReport(docname)
            // getdocid(docname)
        }
        if (Tabid === "1") {
            getReport()
            getTestInfo()
            // openPopup()
        }

    }, [Tabid]);

    function openPopup() {
        const popUp = window.open('/pop.html', 'Popup Test', 'width=100, height=100, left=24, top=24, scrollbars, resizable');

        if (popUp == null || typeof popUp === 'undefined') {
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
            const isFirefox = /Firefox/.test(navigator.userAgent);
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

            if (isMobile) {
                if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                    if (isSafari) {
                        const confirmation = window.confirm('Your Safari browser on iOS is blocking popups. Please enable the popup for viewing the report. Press OK to learn more about it?');

                        if (confirmation) {
                            window.alert('To enable pop-ups in Safari on iOS, go to Settings > Safari > Block Pop-ups and turn it off.');
                        } else {
                            window.alert('Please disable your pop-up blocker in Safari on iOS and refresh the page.');
                        }
                    } else if (isChrome) {
                        window.alert('To enable pop-ups in Chrome on iOS, use Safari settings as Chrome on iOS uses Safari\'s engine and doesn\'t support direct pop-up blocker settings.');
                    } else {
                        window.alert('Please check your browser settings to enable pop-ups on this site.');
                    }
                } else if (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    if (isChrome) {
                        const confirmation = window.confirm('Your Chrome browser on Android is blocking pop-ups. Would you like to learn how to allow pop-ups for this website in Google Chrome?');

                        if (confirmation) {
                            window.alert('To enable pop-ups in Chrome on Android, click on the three-dot menu > Settings > Site settings > Pop-ups and redirects > Allow.');
                        } else {
                            window.alert('Please disable your pop-up blocker in Chrome on Android and refresh the page.');
                        }
                    } else if (isFirefox) {
                        const confirmation = window.confirm('Your Firefox browser on Android is blocking pop-ups. Would you like to learn how to allow pop-ups for this website in Firefox?');

                        if (confirmation) {
                            window.alert('To enable pop-ups in Firefox on Android, click on the three-line menu > Settings > Site permissions > Pop-ups and redirects > Allow.');
                        } else {
                            window.alert('Please disable your pop-up blocker in Firefox on Android and refresh the page.');
                        }
                    } else {
                        window.alert('Please check your browser settings to enable pop-ups on this site.');
                    }
                }
            } else {
                if (isSafari) {
                    window.alert('To enable pop-ups in Safari on desktop, go to Safari > Preferences > Websites > Pop-up Windows and choose how to handle pop-ups.');
                } else if (isChrome) {
                    window.alert('To enable pop-ups in Chrome on desktop, click on the three-dot menu > Settings > Privacy and security > Site settings > Pop-ups and redirects > Allow.');
                } else if (isFirefox) {
                    window.alert('To enable pop-ups in Firefox on desktop, click on the three-line menu > Options > Privacy & Security > Permissions > Exceptions > Allow for this site.');
                } else {
                    window.alert('Please disable your pop-up blocker and refresh the page.');
                }
            }
        } else {
            popUp.focus();
            popUp.close();
        }
    }

    const toggleMainHeader = (id) => {
        if (openMainHeader === id) {
            setOpenMainHeader();
        } else {
            setOpenMainHeader(id);
        }
    };
    const toggleCMP = (id) => {
        if (openCMP === id) {
            setOpenCMP();
        } else {
            setOpenCMP(id);
        }
    };

    async function getTestInfo() {
        const Baseurl = JSON.parse(await localStorage.getItem("Baseurl"))
        const match = Baseurl?.find(
            (item) => item.laboratory_code == userinfo.labcode
        );
        //console.log("match Hedaer", match)
        if (match) {
            setLicenceFlag(match?.app_platform)
            let Patient_ID = userinfo?.uname
            let Token = userinfo?.token
            // setIsLoading(true);
            try {
                await fetch(`${match?.base_api_url}/apipatient/public/v1/allorderresultreportlist`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + `${Token}`,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            patient_id: Patient_ID,
                            token: Token
                        })
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                setResultData(responseJson.data)
                                // setIsLoading(false);
                            });
                        } else {
                            //console.log("Res False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
    }

    const result = ResultData.reduce((acc, curr) => {
        const date = curr.resultreportdetails.data[0]?.accession?.data[0]?.service_date_time.split(" ")[0];
        if (date) {
            acc[date] = acc[date] || [];
            acc[date].push(curr);
        }
        return acc;
    }, {});

    let testarr = []
    testarr = Object.keys(result).map((item, resindex) => {
        {
            let mapval = []
            result[item].forEach(element => {
                mapval.push(...(element?.resultreportdetails?.data[0]?.resultsdata?.data || []))
            });
            return {
                key: item,
                // value: result[item],
                mapVal: mapval
            }
        }
    })
    let temp = []
    testarr.map((item, index) => {
        item?.mapVal?.map((itemResult) => {
            temp.push({ label: itemResult?.catalogs?.data[0]?.description })
        })
    })

    //By Test Logic
    let tempArr = temp.map(item => { return item.label })
    let tempObj = new Set(tempArr);
    let arr = [];
    tempObj.forEach(item => { arr.push(item) })
    arr = arr.sort()
    async function chnageType(value) {
        settestTypeSelected(value)
        let testData = []
        var dateArr = []
        testarr.map((item, index) => {
            item?.mapVal?.map((itemResult, itemindex) => {
                if (itemResult?.catalogs?.data[0]?.description === value) {
                    let pushFlag = dateArr.filter(date => date === item.key).length > 0;
                    if (!pushFlag) {
                        dateArr.push(item.key);
                        testData.push(item)
                    }
                }
            })
        })
        setResultDataTest(testData)
    }

    async function getInfo() {
        const Baseurl = JSON.parse(await localStorage.getItem("Baseurl"))
        const match = Baseurl?.find(
            (item) => item.laboratory_code == props.Labcode
        );
        //console.log("match Hedaer", match)
        if (match) {
            let Patient_ID = userinfo?.uname
            try {
                await fetch(`${match?.base_api_url}/apipatient/public/v1/getpatientdetails/` + Patient_ID,
                    {
                        method: 'GET',
                        headers: {
                            // Authorization: 'Bearer ' + `${Token}`,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                if (responseJson.data) {
                                    setUserInfo(responseJson.data)

                                    const birthDate = new Date(`${responseJson.data.dob}`);

                                    // Current date
                                    const currentDate = new Date();

                                    // Calculate the difference in milliseconds
                                    const differenceInMs = currentDate - birthDate;

                                    // Convert milliseconds to years (approximation)
                                    const ageDate = new Date(differenceInMs);
                                    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
                                    setage(age)

                                }
                            });
                        } else {
                            //console.log("Res False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        }
    }

    async function showdocument(id) {
        let BASEURL = await localStorage.getItem("BaseApiUrl")
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/showpatientprivatedocument/${id}`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            // console.log("respose Show document  url ", responseJson)
                            if (responseJson.document_url) {
                                setTimeout(() => {
                                    // window.location.href = responseJson.document_url
                                    window.location.href = process.env.PUBLIC_URL + `/${props.Labcode}/user/reports`
                                    window.open(`${responseJson.document_url}`, '_blank');
                                    // window.open(`www.google.com`, "_blank");
                                }, 500);
                                return true;
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }

    }

    async function getdocid(docname, paramsdata) {
        let BASEREPORTURL = await localStorage.getItem("ReportApiUrl")
        try {
            await fetch(`${BASEREPORTURL}/api/public/v1/viewpatientreport/${docname}`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },

                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            const decodeid = responseJson?.data[0]?.document[0].document_decode_id
                            const match = paramsdata?.find(
                                (item) => item.document_id === decodeid
                            );
                            if (match) {
                                openPopup()
                                showdocument(match.id)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }

    }

    const getReport = async (docname) => {
        let BASEURL = await localStorage.getItem("BaseApiUrl")
        setIsLoading(true);
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/getallpatientresultdocument/${Patient_ID}`)
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        throw new Error("Request failed with status " + response.status);
                    }
                })
                .then((responseJson) => {
                    //console.log("Get Response Invoice", responseJson);
                    if (responseJson.data) {
                        const newReports = responseJson.data;
                        newReports.sort((a, b) => {
                            const dateA = new Date(a.service_date_time.split("T")[0]);
                            const dateB = new Date(b.service_date_time.split("T")[0]);
                            return dateB - dateA; // Sorting in descending order
                        });
                        setallReport((prevReports) => [...newReports]);
                        getdocid(docname, newReports)
                        // setTimeout(() => {
                        // }, 10000);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    console.error("Fetch Error", error);
                    setIsLoading(false);
                });
        } catch (e) {
            //console.log(e)
        }
    };

    const handleScroll = () => {
        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
        if (
            scrollTop + clientHeight >= scrollHeight - 10 && // Adjust this value as needed
            !isLoading &&
            itemsToLoad < allReports.length
        ) {
            setIsLoading(true);
            setTimeout(() => {
                const newItemsToLoad = itemsToLoad + 5; // Load the next 5 items
                setItemsToLoad(newItemsToLoad);

                setIsLoading(false);
            }, 1500);
        }
    };

    useEffect(() => {
        if (Tabid === "1") {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [isLoading, itemsToLoad, allReports]);

    useEffect(() => {
        // Update the displayed reports when itemsToLoad changes
        setDisplayedReports(allReports.slice(0, itemsToLoad));
    }, [itemsToLoad, allReports]);

    const renderPDFItemsMobile = displayedReports.map((item, index) => {
        const formatDate = (dateString) => {
            const dateParts = dateString.split("T")[0].split("-");
            const monthNumber = parseInt(dateParts[1], 10);
            const monthNames = [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ];
            const monthName = monthNames[monthNumber - 1];
            const formattedDate = `${monthName} ${dateParts[2]}, ${dateParts[0]}`;
            return formattedDate;
        };



        return (
            <div className="card m-2 position-relative" style={{ backgroundColor: "#fff" }}>
                <div className="row m-0">
                    {/* <span className="col-md-3 col-sm-4 p-2" style={{ fontWeight: 'bold', fontSize: 13 }}>{new Date(item.service_date_time.split("T")[0].split("-")[1]).toDateString().split(" ")[1]}{" "}{item.service_date_time.split("T")[0].split("-")[2]},{item.service_date_time.split("T")[0].split("-")[0]}</span> */}
                    <span className="col-md-3 col-sm-4 p-2" style={{ fontWeight: 'bold', fontSize: 13 }}>{formatDate(item.service_date_time)}</span>
                    <span className="col-md-4 col-sm-6 p-1 my-1" style={{ fontWeight: "500", fontSize: 13 }}>{item.doctor_name}</span>
                </div>
                <i className="far fa-file-pdf fs-3 pos-abs-0-100 text-primary" style={{ textAlign: 'end' }}
                    onClick={() => window.open(item.aws_document_url, '_blank')}
                ></i>
            </div>

        )
    });

    const ImageResultModal = ({ isOpen, source, toggle }) => {
        return (
            <Modal isOpen={isOpen} modalTransition={{ timeout: 200 }} backdropTransition={{ timeout: 200 }} toggle={toggle}>
                <ModalHeader toggle={toggle}>Image Result</ModalHeader>
                <ModalBody>
                    <img src={source} alt={source} style={{ width: '100%' }} />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Ok
                    </Button>
                </ModalFooter>
            </Modal>
        );
    };

    const [modalOpen, setModalOpen] = useState(false);
    const [imageSource, setImageSource] = useState('');

    const handleImageClick = (source) => {
        setImageSource(source);
        setModalOpen(true);
    };

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const TenantNameStr = localStorage.getItem('TenantNameApi')
    let root = document?.documentElement;
    let Primarycolor = root?.style?.getPropertyValue('--primary-color',) ? root?.style?.getPropertyValue('--primary-color',) : "#6D3580";
    let Secoundrycolor = root?.style?.getPropertyValue('--secoundry-color') ? root?.style?.getPropertyValue('--secoundry-color') : "#CC4165";
    return (
        <>
            <LoaderComp isLoading={isLoading} color1={Primarycolor} color2={Secoundrycolor} />
            <div className="overflow-x-hidden main-container" style={{ backgroundColor: "#f3f3f3", paddingBottom: 10 }}>
                {/* <div className="container p-2"> */}
                <div className="container">
                    <Breadcrumb className="m-0 m-md-3 px-1 py-2 colorfor" >
                        <BreadcrumbItem href={process.env.PUBLIC_URL + `/${props.Labcode}/dashboard`} tag="a">
                            Dashboard
                        </BreadcrumbItem>
                        {Tabid === "1" &&
                            <BreadcrumbItem
                                onClick={() => TabContant("1")}
                                href="#"
                                tag="a"
                                active={Active1}
                            >
                                Reports
                            </BreadcrumbItem>
                        }
                        {Tabid === "2" &&
                            <BreadcrumbItem
                                onClick={() => TabContant("2")}
                                href="#"
                                tag="a"
                                active={Active2}
                            >
                                Results by Date
                            </BreadcrumbItem>
                        }
                        {Tabid === "3" &&
                            <BreadcrumbItem
                                onClick={() => TabContant("3")}
                                href="#"
                                tag="a"
                                active={Active3}
                            >
                                Results by Order
                            </BreadcrumbItem>
                        }
                    </Breadcrumb>
                    <Row >
                        <Col xs="12" md="12" className="px-4">
                            <Nav tabs={true} >
                                < NavItem >
                                    <NavLink
                                        className={`${Active1} p-1`}
                                        style={{ fontSize: 15, borderWidth: `${Active1 && 1}`, borderColor: `${Active1 && "#d6d6d6"}`, borderTopRightRadius: 5, borderTopLeftRadius: 5, backgroundColor: `${Active1 && "#d6d6d6"}` }}
                                        onClick={() => {

                                            TabContant("1")
                                        }}
                                    >
                                        Reports
                                    </NavLink>
                                </NavItem>
                                {/* {LicenceFlag == "ebazaar" && */}
                                <>
                                    < NavItem >
                                        <NavLink
                                            className={`${Active2} p-1`}
                                            style={{ fontSize: 15, borderWidth: `${Active2 && 1}`, borderColor: `${Active2 && "#d6d6d6"}`, borderTopRightRadius: 5, borderTopLeftRadius: 5, backgroundColor: `${Active2 && "#d6d6d6"}` }}
                                            onClick={() => {
                                                TabContant("2")
                                            }}
                                        >
                                            Results by Date
                                        </NavLink>
                                    </NavItem>
                                    < NavItem >
                                        <NavLink
                                            className={`${Active3} p-1`}
                                            style={{ fontSize: 15, borderWidth: `${Active3 && 1}`, borderColor: `${Active3 && "#d6d6d6"}`, borderTopRightRadius: 5, borderTopLeftRadius: 5, backgroundColor: `${Active3 && "#d6d6d6"}` }}
                                            onClick={() => {
                                                TabContant("3")
                                                chnageType(arr[0])
                                            }}
                                        >
                                            Results by Order
                                        </NavLink>
                                    </NavItem>
                                </>
                                {/* } */}
                            </Nav>
                        </Col>
                    </Row>
                </div >
                <div className="container p-2 p-md-2" >
                    <TabContent className="p-2 p-md-3" activeTab={Tabid}>
                        <TabPane tabId="1">
                            <div className="pt-2 col-12 col-md-6" data-aos="zoom-in-right">
                                <div className="card position-relative mb-3" style={{ backgroundColor: "#fff", boxShadow: '6px 2px 5px #d3d3d3', }}>
                                    <div>
                                        <div className="row m-0">
                                            <span className="col-3 p-1">Name :</span>
                                            <span className="col-9 p-1" style={{ textAlign: 'end', fontWeight: "600" }}>{UserInformation.first_name} {UserInformation.last_name} | {age} | {UserInformation.sex} </span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="row m-0">
                                            <span className="col-2 p-1">Lab :</span>
                                            <span className="col-10 p-1" style={{ textAlign: 'end', fontWeight: "600" }}>{TenantNameStr} | {userinfo.labcode}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cursor-pointer" style={{ cursor: 'pointer' }}>
                                    {renderPDFItemsMobile}
                                </div>
                                {itemsToLoad >= allReports.length && <div className="pt-2 text-center" style={{ color: "gray" }}>No more reports to load.</div>}
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            {testarr.map((item, index) => {
                                return (
                                    <Accordion open={openMainHeader} toggle={toggleMainHeader}>
                                        <AccordionItem>
                                            <AccordionHeader targetId={item?.key}>
                                                <div className="w-50 ms-3">
                                                    <span className="fw-bold">
                                                        {getSplitDate(item?.key)}
                                                    </span>
                                                </div>
                                            </AccordionHeader>
                                            <AccordionBody className="p-0" accordionId={item?.key}>
                                                {item.mapVal.sort((a, b) => {
                                                    const descriptionA = a?.catalogs?.data[0]?.description || '';
                                                    const descriptionB = b?.catalogs?.data[0]?.description || '';
                                                    return descriptionA.localeCompare(descriptionB);
                                                }) && item?.mapVal?.map((itemResult, indexres) => {
                                                    const accessionNotes = itemResult?.resultsdataaccession?.data[0]?.notes?.split('\n');
                                                    return (
                                                        <Accordion open={openCMP} toggle={toggleCMP} >
                                                            <AccordionItem>
                                                                <AccordionHeader targetId={itemResult?.id}>{itemResult?.catalogs?.data[0]?.description}</AccordionHeader>
                                                                <AccordionBody className="pddingset" accordionId={itemResult?.id}>
                                                                    <div key={Date.now()} className={!itemResult.isGreen ? "cardcus" : "p-1"} onClick={() => {
                                                                        if (itemResult.isGreen) {
                                                                            itemResult.isGreen = false
                                                                            settest(!test)
                                                                            console.log("true")
                                                                        } else {
                                                                            itemResult.isGreen = true
                                                                            settest(!test)
                                                                            console.log("false")
                                                                        }
                                                                    }}>
                                                                        <Row>
                                                                            <Col xs="12" md="6">
                                                                                <Row>
                                                                                    <Col md="6" sm="4" xs="6" className="mt-md-2 mt-2 ">
                                                                                        <label className="customfont">Accession</label>
                                                                                    </Col>
                                                                                    <Col md="6" sm="8" xs="6" className="mt-md-2 mt-2">
                                                                                        <label className="customfont" > {itemResult.resultsdataaccession?.data[0]?.accession_number}</label>
                                                                                    </Col>

                                                                                    <Col md="6" sm="4" xs="6" className="mt-md-2 mt-0 ">
                                                                                        <label className="customfont">Ordered</label>
                                                                                    </Col>
                                                                                    <Col md="6" sm="8" xs="6" className="mt-md-2 mt-0">
                                                                                        <label className="customfont" > {getSplitDate(itemResult.resultsdataaccession?.data[0]?.created_at)}</label>
                                                                                    </Col>

                                                                                    <Col md="6" sm="4" xs="6" className="mt-md-2 mt-0">
                                                                                        <label className="customfont" >Collected</label>
                                                                                    </Col>
                                                                                    <Col md="6" sm="8" xs="6" className="mt-md-2 mt-0">
                                                                                        <label className="customfont" > {getSplitDate(itemResult.resultsdataaccession?.data[0]?.service_date_time)}</label>
                                                                                    </Col>
                                                                                </Row>

                                                                            </Col>
                                                                            <Col xs="12" md="6">
                                                                                <Row cla>
                                                                                    <Col md="6" sm="4" xs="6" className="mt-md-2 mt-0 ">
                                                                                        <label className="customfont">Physician</label>
                                                                                    </Col>
                                                                                    <Col md="6" sm="8" xs="6" className="mt-md-2 mt-0">
                                                                                        <label className="customfont" > {itemResult.resultsdataaccession?.data[0]?.doctor_name}</label>
                                                                                        {/* <span className="customfont" > ARSENIA NEPOMUCENO</span> */}
                                                                                    </Col>

                                                                                    <Col md="6" sm="4" xs="6" className="mt-md-2 mt-0 ">
                                                                                        <label className="customfont">Received</label>
                                                                                    </Col>
                                                                                    <Col md="6" sm="8" xs="6" className="mt-md-2 mt-0">
                                                                                        <label className="customfont" > {getSplitDate(itemResult.resultsdataaccession?.data[0]?.received_dtm)}</label>
                                                                                    </Col>

                                                                                    <Col md="6" sm="4" xs="6" className="mt-md-2 mt-0">
                                                                                        <label className="customfont" >Reported</label>
                                                                                    </Col>
                                                                                    <Col md="6" sm="8" xs="6" className="mt-md-2 mt-0 mb-sm-2">
                                                                                        <label className="customfont" > {getSplitDate(itemResult.resultsdataaccession?.data[0]?.report_date_time)}</label>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                        {itemResult?.resultsdataaccession?.data[0]?.notes &&
                                                                            <label className="pt-1 fontnotes" style={{ wordWrap: 'break-word' }}>
                                                                                {accessionNotes?.map((line, index) => (
                                                                                    <React.Fragment key={index}>
                                                                                        {line}
                                                                                        {index < accessionNotes.length - 1 && <br />} {/* Add <br /> for all lines except the last one */}
                                                                                    </React.Fragment>
                                                                                ))}
                                                                            </label>
                                                                        }
                                                                    </div>
                                                                    {/* <div style={{ textAlignLast: 'center' }}>
                                                                        <button class="read-more-button" onClick={() => {
                                                                            if (itemResult.isGreen) {
                                                                                itemResult.isGreen = false
                                                                                settest(!test)
                                                                                console.log("true")
                                                                            } else {
                                                                                itemResult.isGreen = true
                                                                                settest(!test)
                                                                                console.log("false")
                                                                            }
                                                                        }}>{!itemResult.isGreen ? "Read More" : "Show Less"}</button>
                                                                    </div> */}
                                                                    {/* <Row className="my-2">
                                                                        <Table size="md" className="p-0" bordered responsive>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>
                                                                                        Test
                                                                                    </th>
                                                                                    <th>
                                                                                        Result
                                                                                    </th>
                                                                                    <th>
                                                                                        Analysis
                                                                                    </th>
                                                                                    <th>
                                                                                        Units
                                                                                    </th>
                                                                                    <th>
                                                                                        Normal range
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {itemResult?.results?.data?.map((itemResultValue) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>
                                                                                                {itemResultValue.catalogs?.data[0]?.description}
                                                                                            </td>
                                                                                            <td>
                                                                                                {itemResultValue.display_result}
                                                                                            </td>
                                                                                            <td>
                                                                                                {itemResultValue.res_analysis}
                                                                                            </td>
                                                                                            <td>
                                                                                                {itemResultValue.measuring_unit}
                                                                                            </td>
                                                                                            <td>
                                                                                                {itemResultValue.normal_range}
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </Table>
                                                                    </Row> */}

                                                                    <Row className="bordered p-0 mt-2">
                                                                        <Col className="col-6 p-1 bg-secondary customfont">
                                                                            <label className="text-light">Test Name</label>
                                                                        </Col>
                                                                        <Col className="col-3 p-1 bg-secondary customfont">
                                                                            <label className="text-light">Result</label>
                                                                        </Col>
                                                                        <Col className="col-3 p-1 bg-secondary customfont">
                                                                            <label className="text-light">Range</label>
                                                                        </Col>
                                                                    </Row>
                                                                    {itemResult?.results?.data?.map((itemResultValue) => {
                                                                        const resultNotes = itemResultValue?.notes?.split('\n');
                                                                        return (
                                                                            <Row className="bordered border">
                                                                                <Col className={`col-6 p-1 ${itemResultValue.res_analysis == "A" ? "m-0 bg-light v1" : "m-0 bg-light"} ${itemResultValue.res_analysis == "AH" ? "m-0 bg-light v1" : "m-0 bg-light"} ${itemResultValue.res_analysis == "AL" ? "m-0 bg-light v1" : "m-0 bg-light"} ${itemResultValue.res_analysis == "PH" ? "m-0 bg-light v2" : "m-0 bg-light"} ${itemResultValue.res_analysis == "PL" ? "m-0 bg-light v2" : "m-0 bg-light"}`} >
                                                                                    {/* <label className="py-2 cusromfontdescription" >{itemResultValue.catalogs?.data[0]?.description}</label> */}
                                                                                    <label onClick={() => {
                                                                                        if (itemResultValue.isGreen) {
                                                                                            itemResultValue.isGreen = false
                                                                                            settest(!test)
                                                                                            console.log("true")
                                                                                        } else {
                                                                                            itemResultValue.isGreen = true
                                                                                            settest(!test)
                                                                                            console.log("false")
                                                                                        }
                                                                                    }} className="py-2 cusromfontdescription" > {itemResultValue.isGreen ? itemResultValue.catalogs?.data[0]?.description : itemResultValue.catalogs?.data[0]?.description.slice(0, 20) + (itemResultValue.catalogs?.data[0]?.description.length > 20 ? '...' : '')}</label>
                                                                                </Col>
                                                                                <Col className="bg-light col-3 p-1" style={{ minHeight: 30 }}>
                                                                                    {itemResultValue.status == "F" &&
                                                                                        <>
                                                                                            {itemResultValue.result_type == "IM" ?
                                                                                                <>
                                                                                                    <img src={itemResultValue.res_img} style={{ height: 100, width: 100 }} onClick={() => handleImageClick(itemResultValue.res_img)} />
                                                                                                    <ImageResultModal isOpen={modalOpen} source={imageSource} toggle={toggleModal} />
                                                                                                </>
                                                                                                : <>
                                                                                                    <label className={`pt-1 cusromfontdescription ${["A", "AH", "AL", "PH", "PL"].includes(itemResultValue.res_analysis) && "fw-bold"}`} >{itemResultValue.display_result}</label>
                                                                                                    {" "}<span className="pt-1 cusromfontmeasuring_unit" >{itemResultValue.measuring_unit}</span>
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                    {itemResultValue.status == "V" &&
                                                                                        <>

                                                                                        </>
                                                                                    }
                                                                                    {itemResultValue.status == "O" &&
                                                                                        <>
                                                                                            <label className="pt-1 fw-bold cusromfontdescription">Omitted</label><br />
                                                                                        </>
                                                                                    }
                                                                                    {itemResultValue.status == "S" &&
                                                                                        <>
                                                                                            <label className="pt-1 cusromfontdescription">Skipped</label><br />
                                                                                        </>
                                                                                    }
                                                                                    {itemResultValue.status == "L" &&
                                                                                        <>
                                                                                            <label className="pt-1 cusromfontdescription">Sent to Lab</label><br />
                                                                                        </>
                                                                                    }
                                                                                </Col>
                                                                                <Col className="bg-light col-3 p-1">
                                                                                    <label className="pt-1 customfontnotes">{itemResultValue.normal_range}</label>
                                                                                </Col>
                                                                                {/* {itemResultValue.notes &&
                                                                                    <span className="text-secondary" style={{  fontSize: 10, wordWrap: 'break-word' }}><i>Notes : {itemResultValue.notes}</i></span>
                                                                                } */}
                                                                                <label className="p-1 fontnotes" style={{ wordWrap: 'break-word', }}>
                                                                                    {resultNotes?.map((line, index) => (
                                                                                        <React.Fragment key={index}>
                                                                                            {line}
                                                                                            {index < resultNotes.length - 1 && <br />} {/* Add <br /> for all lines except the last one */}
                                                                                        </React.Fragment>
                                                                                    ))}
                                                                                </label>
                                                                            </Row>
                                                                        )
                                                                    })}
                                                                </AccordionBody>
                                                            </AccordionItem>
                                                        </Accordion>
                                                    )
                                                })}
                                            </AccordionBody>
                                        </AccordionItem>
                                    </Accordion>
                                )
                            })}
                        </TabPane>
                        <TabPane tabId="3">
                            <div className="col-sm-12 col-md-4 pb-2">
                                <div className="form-group">
                                    <select
                                        onChange={(e) => {
                                            // settestTypeSelected(e.target.value)
                                            chnageType(e.target.value)
                                        }}
                                        value={testTypeSelected}
                                        style={{ borderColor: "#d3d3d3", }}
                                        className="form-select">
                                        <option>Select Test Type</option>
                                        {arr.map((item) => {
                                            return (
                                                <option value={item}>{item}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            {ResultDataTest.map((item, index) => {
                                return (
                                    <Accordion open={openMainHeader} toggle={toggleMainHeader}>
                                        <AccordionItem>
                                            <AccordionHeader targetId={item?.key}>
                                                <div className="w-50 ms-3">
                                                    <span className="fw-bold">
                                                        {getSplitDate(item?.key)}
                                                    </span>
                                                </div>
                                            </AccordionHeader>
                                            <AccordionBody accordionId={item?.key}>
                                                {item?.mapVal?.map((itemResult) => {
                                                    if (testTypeSelected === itemResult?.catalogs?.data[0]?.description) {
                                                        const accessionNotes = itemResult?.resultsdataaccession?.data[0]?.notes?.split('\n');
                                                        return (
                                                            // <Accordion open={openCMP} toggle={toggleCMP}>
                                                            //     <AccordionItem>
                                                            //         <AccordionHeader targetId={itemResult?.id}>{itemResult?.catalogs?.data[0]?.description}</AccordionHeader>
                                                            //         <AccordionBody className="pddingset" accordionId={itemResult?.id}>
                                                            <div className="pddingset">
                                                                <div key={Date.now()} className={!itemResult.isGreen ? "cardcus" : "border p-2"} onClick={() => {
                                                                    if (itemResult.isGreen) {
                                                                        itemResult.isGreen = false
                                                                        settest(!test)
                                                                        console.log("true")
                                                                    } else {
                                                                        itemResult.isGreen = true
                                                                        settest(!test)
                                                                        console.log("false")
                                                                    }
                                                                }}>
                                                                    <Row>
                                                                        <Col xs="12" md="6">
                                                                            <Row>
                                                                                <Col md="6" sm="4" xs="6" className="mt-md-2 mt-2 ">
                                                                                    <label className="customfont">Accession</label>
                                                                                </Col>
                                                                                <Col md="6" sm="8" xs="6" className="mt-md-2 mt-2">
                                                                                    <label className="customfont" > {itemResult.resultsdataaccession?.data[0]?.accession_number}</label>
                                                                                </Col>

                                                                                <Col md="6" sm="4" xs="6" className="mt-md-2 mt-0 ">
                                                                                    <label className="customfont">Ordered</label>
                                                                                </Col>
                                                                                <Col md="6" sm="8" xs="6" className="mt-md-2 mt-0">
                                                                                    <label className="customfont" > {getSplitDate(itemResult.resultsdataaccession?.data[0]?.created_at)}</label>
                                                                                </Col>

                                                                                <Col md="6" sm="4" xs="6" className="mt-md-2 mt-0">
                                                                                    <label className="customfont" >Collected</label>
                                                                                </Col>
                                                                                <Col md="6" sm="8" xs="6" className="mt-md-2 mt-0">
                                                                                    <label className="customfont" > {getSplitDate(itemResult.resultsdataaccession?.data[0]?.service_date_time)}</label>
                                                                                </Col>
                                                                            </Row>

                                                                        </Col>
                                                                        <Col xs="12" md="6">
                                                                            <Row cla>
                                                                                <Col md="6" sm="4" xs="6" className="mt-md-2 mt-0 ">
                                                                                    <label className="customfont">Physician</label>
                                                                                </Col>
                                                                                <Col md="6" sm="8" xs="6" className="mt-md-2 mt-0">
                                                                                    <label className="customfont" > {itemResult.resultsdataaccession?.data[0]?.doctor_name}</label>
                                                                                </Col>

                                                                                <Col md="6" sm="4" xs="6" className="mt-md-2 mt-0 ">
                                                                                    <label className="customfont">Received</label>
                                                                                </Col>
                                                                                <Col md="6" sm="8" xs="6" className="mt-md-2 mt-0">
                                                                                    <label className="customfont" > {getSplitDate(itemResult.resultsdataaccession?.data[0]?.received_dtm)}</label>
                                                                                </Col>

                                                                                <Col md="6" sm="4" xs="6" className="mt-md-2 mt-0">
                                                                                    <label className="customfont" >Reported</label>
                                                                                </Col>
                                                                                <Col md="6" sm="8" xs="6" className="mt-md-2 mt-0 mb-sm-2">
                                                                                    <label className="customfont" > {getSplitDate(itemResult.resultsdataaccession?.data[0]?.report_date_time)}</label>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                    {itemResult?.resultsdataaccession?.data[0]?.notes &&
                                                                        <label className="pt-1 fontnotes" style={{ wordWrap: 'break-word', }}>
                                                                            {accessionNotes?.map((line, index) => (
                                                                                <React.Fragment key={index}>
                                                                                    {line}
                                                                                    {index < accessionNotes.length - 1 && <br />} {/* Add <br /> for all lines except the last one */}
                                                                                </React.Fragment>
                                                                            ))}
                                                                        </label>
                                                                    }
                                                                </div>
                                                                <Row className="bordered p-0 mt-2">
                                                                    <Col className="col-6 p-1 bg-secondary customfont">
                                                                        <label className="text-light">Test Name</label>
                                                                    </Col>
                                                                    <Col className="col-3 p-1 bg-secondary customfont">
                                                                        <label className="text-light">Result</label>
                                                                    </Col>
                                                                    <Col className="col-3 p-1 bg-secondary customfont">
                                                                        <label className="text-light">Range</label>
                                                                    </Col>
                                                                </Row>
                                                                {itemResult?.results?.data?.map((itemResultValue) => {
                                                                    const resultNotes = itemResultValue?.notes?.split('\n');
                                                                    return (
                                                                        <Row className="bordered border">
                                                                            <Col className={`col-6 p-1 ${itemResultValue.res_analysis == "A" ? "m-0 bg-light v1" : "m-0 bg-light"} ${itemResultValue.res_analysis == "AH" ? "m-0 bg-light v1" : "m-0 bg-light"} ${itemResultValue.res_analysis == "AL" ? "m-0 bg-light v1" : "m-0 bg-light"} ${itemResultValue.res_analysis == "PH" ? "m-0 bg-light v2" : "m-0 bg-light"} ${itemResultValue.res_analysis == "PL" ? "m-0 bg-light v2" : "m-0 bg-light"}`} >
                                                                                <label onClick={() => {
                                                                                    if (itemResultValue.isGreen) {
                                                                                        itemResultValue.isGreen = false
                                                                                        settest(!test)
                                                                                        console.log("true")
                                                                                    } else {
                                                                                        itemResultValue.isGreen = true
                                                                                        settest(!test)
                                                                                        console.log("false")
                                                                                    }
                                                                                }} className="py-2 cusromfontdescription" > {itemResultValue.isGreen ? itemResultValue.catalogs?.data[0]?.description : itemResultValue.catalogs?.data[0]?.description.slice(0, 23) + (itemResultValue.catalogs?.data[0]?.description.length > 20 ? '...' : '')}</label>
                                                                            </Col>
                                                                            <Col className="bg-light col-3 p-1" style={{ minHeight: 30 }}>
                                                                                {itemResultValue.status == "F" &&
                                                                                    <>
                                                                                        {itemResultValue.result_type == "IM" ?
                                                                                            <>
                                                                                                <img src={itemResultValue.res_img} style={{ height: 100, width: 100 }} onClick={() => handleImageClick(itemResultValue.res_img)} />
                                                                                                <ImageResultModal isOpen={modalOpen} source={imageSource} toggle={toggleModal} />
                                                                                            </>
                                                                                            : <>
                                                                                                <label className={`pt-1 cusromfontdescription ${["A", "AH", "AL", "PH", "PL"].includes(itemResultValue.res_analysis) && "fw-bold"}`} >{itemResultValue.display_result}</label>
                                                                                                {" "}<span className="pt-1 cusromfontmeasuring_unit" >{itemResultValue.measuring_unit}</span>
                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                }
                                                                                {itemResultValue.status == "V" &&
                                                                                    <>

                                                                                    </>
                                                                                }
                                                                                {itemResultValue.status == "O" &&
                                                                                    <>
                                                                                        <label className="pt-1 fw-bold cusromfontdescription">Omitted</label><br />
                                                                                    </>
                                                                                }
                                                                                {itemResultValue.status == "S" &&
                                                                                    <>
                                                                                        <label className="pt-1 cusromfontdescription">Skipped</label><br />
                                                                                    </>
                                                                                }
                                                                                {itemResultValue.status == "L" &&
                                                                                    <>
                                                                                        <label className="pt-1 cusromfontdescription">Sent to Lab</label><br />
                                                                                    </>
                                                                                }
                                                                            </Col>
                                                                            <Col className="bg-light col-3 p-1">
                                                                                <label className="pt-1 customfontnotes">{itemResultValue.normal_range}</label>
                                                                            </Col>
                                                                            {/* {itemResultValue.notes &&
                                                                                    <span className="text-secondary" style={{  fontSize: 10, wordWrap: 'break-word' }}><i>Notes : {itemResultValue.notes}</i></span>
                                                                                } */}
                                                                            <label className="p-1 fontnotes" style={{ wordWrap: 'break-word', }}>
                                                                                {resultNotes?.map((line, index) => (
                                                                                    <React.Fragment key={index}>
                                                                                        {line}
                                                                                        {index < resultNotes.length - 1 && <br />} {/* Add <br /> for all lines except the last one */}
                                                                                    </React.Fragment>
                                                                                ))}
                                                                            </label>
                                                                        </Row>
                                                                    )
                                                                })}
                                                            </div>
                                                            //         </AccordionBody>
                                                            //     </AccordionItem>
                                                            // </Accordion>
                                                        )
                                                    }
                                                })}
                                            </AccordionBody>
                                        </AccordionItem>
                                    </Accordion>
                                )
                            })}
                        </TabPane>
                    </TabContent >
                </div>
            </div >
        </>
    );
};

export default UserReports;