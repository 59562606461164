import "../App.scss";
import React, { useEffect, useState } from "react";
import AOS from "aos";

import Footer from "../components/Footer";
import Header from "../components/Header";
import LoginModal from "../components/Modal";
import HIPAAPolicy from "../components/HIPAAPolicy";
import ContactUs from "../components/ContactUsModal";
import { useSelector } from "react-redux";

const HIPAA = () => {
    const [modal, setModal] = useState(false);
    const [labCode, setlabCode] = useState(null);
    const [contactmodal, setcontactModal] = useState(false);
    const { userinfo } = useSelector((state) => state.user);

    const toggleModal = (code) => {
        setModal(!modal);
        setlabCode(code);
    };
    const ContactusToggele = () => {
        setcontactModal(!contactmodal);
    };

    useEffect(() => {
        AOS.init({
            duration: 1500,
        });
    }, []);

    return (
        <>
            <Header toggleModal={toggleModal}
                ContactusToggele={ContactusToggele}
                Labcode={userinfo.labcode} />
            <LoginModal isOpen={modal} toggle={toggleModal} Labcode={labCode} />
            <ContactUs isOpen={contactmodal} ContactusToggele={ContactusToggele} />
            <HIPAAPolicy />
            <Footer Labcode={labCode} />
        </>
    );
};

export default HIPAA;
