import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { BASE_URL, ENV_STATUS } from "../Global/Global";

function PrivateRoute({ }) {
  const { userinfo } = useSelector((state) => state.user);
  const { code } = useParams();

  useEffect(() => {
    getInfo()
    // checkauth()
  }, [])

  async function getInfo() {
    try {
      await fetch(`${BASE_URL}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            response.json().then(async (responseJson) => {
              if (responseJson.data) {
                // console.log("Resopnse 121212", responseJson)
                let temp = []
                responseJson.data.map((item) => {
                  if (item.environment_status === `${ENV_STATUS}`) {
                    temp.push(item)
                  }
                })
                // console.log("TEMP   ARRAYYYYY", temp)
                await localStorage.setItem("Baseurl", JSON.stringify(responseJson.data))

                checkauth()
              }
            });
          } else {
            // toast.error("Something Went Wrong !!")
          }
        })
        .catch((error) => {
          console.log("Fetch Error", error);
        });
    } catch (error) {
      console.log('Try Error', error);
    }
  }

  let Temp = true
  async function checkauth() {
    console.log("CALLLEDD")
    const Baseurl = JSON.parse(await localStorage.getItem("Baseurl"))
    // if (userinfo.token) {
    if (Baseurl && userinfo.token) {
      console.log("userinfo")
      const match = Baseurl?.find(
        (item) => item.laboratory_code === code
      );
      if (match) {
        Temp = true
      }
      else {
        Temp = false
      }
    }
  }
  return Temp ? <Outlet /> : <Navigate to={process.env.PUBLIC_URL + "/"} />;
}

export default PrivateRoute;
