import "../App.scss";
import React, { useEffect, useState } from "react";
import AOS from "aos";

import Footer from "../components/Footer";
import Header from "../components/Header";
import LoginModal from "../components/Modal";
import ContactUs from "../components/ContactUsModal";
import UserProfile from "../components/userProfile";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Profile = () => {
    const navigate = useNavigate();
    const { code } = useParams();
    const [modal, setModal] = useState(false);
    const [labCode, setlabCode] = useState(null);
    const [Logoimage, setLogoimage] = useState("");
    const [contactmodal, setcontactModal] = useState(false);
    const { userinfo } = useSelector((state) => state.user);

    const toggleModal = (code) => {
        setModal(!modal);
        setlabCode(code);
    };
    const ContactusToggele = () => {
        setcontactModal(!contactmodal);
    };
    async function checkLab() {
        // const match = LabDetails.find(
        //   (item) => item.labcode === code
        // );
        const Baseurl = await JSON.parse(localStorage.getItem("Baseurl"))
        const match = Baseurl.find(
            (item) => item.laboratory_code === code
        );
        localStorage.setItem("BaseApiUrl", match?.base_api_url)
        localStorage.setItem("ReportApiUrl", match?.base_app_url)
        localStorage.setItem("TenantNameApi", match?.name)
        let BASEURL = await localStorage.getItem("BaseApiUrl")
        let Token = userinfo?.token
        let Patient_ID = userinfo?.uname
        if (match) {
            try {
                await fetch(`${BASEURL}/apipatient/public/v1/tenantsinfo`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "laboratory_code": code,
                            "token": Token,
                            "patient_id": Patient_ID,
                            "with_auth": Patient_ID ? "yes" : "no",
                        }),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                //console.log("Get Response User info Dashboard", responseJson);
                                if (responseJson.data) {
                                    let Temp = responseJson.data
                                    if (Temp.id) {
                                        setLogoimage(Temp.tenant_logo)
                                        let root = document?.documentElement;
                                        root?.style?.setProperty('--primary-color', Temp?.primary_color);
                                        root?.style?.setProperty('--secoundry-color', Temp?.secondary_color);
                                    }
                                }
                            });
                        } else {
                            //console.log("Res False")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        } else {
            // console.log("inside Else to pass to baseuRL Profile")
            navigate(process.env.PUBLIC_URL + "/");
        }
    }
    useEffect(() => {
        checkLab()
        AOS.init({
            duration: 1500,
        });
    }, []);

    return (
        <>
            <Header
                Logoimage={Logoimage}
                toggleModal={toggleModal}
                ContactusToggele={ContactusToggele}
                Labcode={code} />
            <LoginModal isOpen={modal} toggle={toggleModal} Labcode={labCode} />
            <ContactUs isOpen={contactmodal} ContactusToggele={ContactusToggele} />
            <UserProfile Labcode={code} />
            <Footer Labcode={code} />
        </>
    );
};

export default Profile;
