import React, { useEffect, useState } from "react";
import "../CSS/Privacy.scss"
import { applicationSetting } from "../Global/Global";
import { useParams, } from "react-router-dom";

const HIPAAPolicy = () => {
    const { code } = useParams();
    const [htmlContentHippa, sethtmlContentHippa] = useState()
    const [htmlContentCokkie, sethtmlContentCokkie] = useState()
    const [htmlContentPrivacy, sethtmlContentPrivacy] = useState()

    useEffect(() => {
        const url = window.location.href
        if (url.includes("#")) {
            let type = url.split("#")[1]
            setTimeout(() => {
                const scrollToID = document.getElementById(`${type}`);
                if (scrollToID) {
                    scrollToID.scrollIntoView({ behavior: 'smooth' });
                }
            }, 1000);
        }
        test()

        //console.log("LabCode Term Condition", props.Labcode)
    }, []);


    async function test() {
        try {
            const settings = await applicationSetting(code);
            settings.data.map((item) => {
                if (item.section_name === "hippa_notice_of_privacy_practices") {
                    sethtmlContentHippa({ __html: item.section_value })
                }
                if (item.section_name === "cookie_notice") {
                    sethtmlContentCokkie({ __html: item.section_value })
                }
                if (item.section_name === "privacypolicy") {
                    sethtmlContentPrivacy({ __html: item.section_value })
                }
            })
            // Use 'settings' data as needed
        } catch (error) {
            console.error('Error fetching application settings:', error);
            // Handle the error appropriately
        }
    }

    return (
        <div className="overflow-x-hidden">
            <section className="privacy-header" data-aos="fade-left">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="m-0 text-white">Our Policies</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="hippa" id="hippa-compliance">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" data-aos="fade-left">
                            <h2 className="section-title">HIPAA Notice of Privacy Practices</h2>
                            <div dangerouslySetInnerHTML={htmlContentHippa}>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="privacy-notice" id="privacy-notice" data-aos="fade-right">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="section-title">Privacy Notice for MyHealth Patient Portal</h2>
                            <div dangerouslySetInnerHTML={htmlContentPrivacy}>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="cookie" id="cookie-notice">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12" data-aos="fade-left">
                            <h2 className="section-title">Cookie Notice</h2>
                            <div dangerouslySetInnerHTML={htmlContentCokkie}>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HIPAAPolicy;