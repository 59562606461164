import "../App.scss";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import { useParams, } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoginModal from "../components/Modal";
import PrivacyPolicy from "../components/PrivacyPolicy";
import ContactUs from "../components/ContactUsModal";
import { useSelector } from "react-redux";

const Privacy = () => {
    const { code } = useParams();
    const [modal, setModal] = useState(false);
    const [labCode, setlabCode] = useState(null);
    const [contactmodal, setcontactModal] = useState(false);
    const { userinfo } = useSelector((state) => state.user);

    //console.log("Labcode Privacy Page", userinfo.labcode)

    const toggleModal = (code) => {
        setModal(!modal);
        setlabCode(code);
    };
    const ContactusToggele = () => {
        setcontactModal(!contactmodal);
    };

    useEffect(() => {
        AOS.init({
            duration: 1500,
        });
    }, []);

    return (
        <>
            <Header toggleModal={toggleModal}
                ContactusToggele={ContactusToggele}
                Labcode={userinfo.labcode} />
            <LoginModal isOpen={modal} toggle={toggleModal} Labcode={labCode} />
            <ContactUs isOpen={contactmodal} ContactusToggele={ContactusToggele} />
            <PrivacyPolicy Labcode={code} />
            <Footer Labcode={userinfo.labcode ? userinfo.labcode : code} />
        </>
    );
};

export default Privacy;
