import "../App.scss";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useParams, useNavigate } from "react-router-dom";
import LoginModal from "../components/Modal";
import Main from "../components/Main";
import ContactUs from "../components/ContactUsModal";
import { useSelector } from "react-redux";
import { BASE_URL, ENV_STATUS } from "../Global/Global";

const LabPage = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [Logoimage, setLogoimage] = useState()
  const [BannerTitle, setBannerTitle] = useState()
  const [BannerDesc, setBannerDesc] = useState()
  const [BannerImage, setBannerImage] = useState()
  const [modal, setModal] = useState(false);
  const [contactmodal, setcontactModal] = useState(false);
  const [Main_Dis, setMain_Dis] = useState();
  const { userinfo } = useSelector((state) => state.user);

  const toggleModal = (code) => {
    setModal(!modal);
  };
  const ContactusToggele = () => {
    setcontactModal(!contactmodal);
  };

  async function getInfo() {
    try {
      await fetch(`${BASE_URL}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            response.json().then(async (responseJson) => {
              if (responseJson.data) {
                // console.log("Resopnse 121212", responseJson)
                let temp = []
                responseJson.data.map((item) => {
                  if (item.environment_status === `${ENV_STATUS}`) {
                    temp.push(item)
                  }
                })
                // console.log("TEMP   ARRAYYYYY", temp)
                await localStorage.setItem("Baseurl", JSON.stringify(responseJson.data))

                checkLab()
              }
            });
          } else {
            // toast.error("Something Went Wrong !!")
          }
        })
        .catch((error) => {
          console.log("Fetch Error", error);
        });
    } catch (error) {
      console.log('Try Error', error);
    }
  }

  async function checkLab() {
    // const match = LabDetails.find(
    //   (item) => item.labcode === code
    // );
    let Token = userinfo?.token
    let Patient_ID = userinfo?.uname

    const Baseurl = await JSON.parse(localStorage.getItem("Baseurl"))
    //console.log("Baseurl", Baseurl)

    const match = Baseurl?.find(
      (item) => item.laboratory_code === code
    );

    //console.log("match labpage", match)
    if (match) {
      localStorage.setItem("BaseApiUrl", match?.base_api_url)
      localStorage.setItem("ReportApiUrl", match?.base_app_url)
      localStorage.setItem("TenantNameApi", match?.name)
      let BASEURL = await localStorage.getItem("BaseApiUrl")
      try {
        await fetch(`${BASEURL}/apipatient/public/v1/tenantsinfo`,
          {
            method: 'POST',
            headers: {
              // Authorization: 'Bearer ' + Token,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              "laboratory_code": code,
              "token": Token,
              "patient_id": Patient_ID,
              "with_auth": Patient_ID ? "yes" : "no",
            }),
          })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((responseJson) => {
                //console.log("Get Response User Info", responseJson);
                if (responseJson.data) {
                  let Temp = responseJson.data
                  if (Temp.id) {
                    setLogoimage(Temp.tenant_logo)
                    setBannerTitle(Temp.banner_title)
                    setBannerDesc(Temp.banner_description)
                    setBannerImage(Temp.banner_image)
                    setMain_Dis(Temp.main_description)

                    let root = document?.documentElement;
                    root?.style?.setProperty('--primary-color', Temp?.primary_color);
                    root?.style?.setProperty('--secoundry-color', Temp?.secondary_color);
                  }
                }
              });
            } else {
              //console.log("Res False")
            }
          })
          .catch((error) => {
            console.log("Fetch Error", error);
          });
      } catch (error) {
        console.log('Try Error', error);
      }
    } else {
      // console.log("inside Else to pass to baseuRL bh Labpage")
      navigate(process.env.PUBLIC_URL + "/");
    }
  }

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
    const Weburl = window.location.href
    let weburl_match = Weburl.includes("?doc_name=")
    let inv_noMatch = Weburl.includes("?inv_no=")

    if (weburl_match || inv_noMatch) {
      setModal(!modal);
    }
    getInfo()
    // checkLab();
  }, []);

  return (
    <>
      <Header
        toggleModal={toggleModal}
        Labcode={code}
        ContactusToggele={ContactusToggele}
        Logoimage={Logoimage} />
      <LoginModal
        isOpen={modal}
        toggle={toggleModal}
        Labcode={code} />
      <Main
        toggleModal={toggleModal}
        Labcode={code}
        MainDisc={Main_Dis}
        BannerTitle={BannerTitle}
        BannerDesc={BannerDesc}
        BannerImage={BannerImage} />
      <ContactUs isOpen={contactmodal} ContactusToggele={ContactusToggele} />
      <Footer Labcode={code} />
    </>
  );
};

export default LabPage;