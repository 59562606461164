import React from "react";
import "../CSS/Privacy.scss"

const PrivacyPolicy = (props, Labcode) => {
    return (
        <div className="overflow-x-hidden main-container" style={{ backgroundColor: "#e4e5e6" }} >
            <section className="privacy-header" data-aos="fade-left">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="m-0 text-white">Privacy</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="privacy-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8" data-aos="fade-right">
                            <p>Our <a href={props.Labcode ? process.env.PUBLIC_URL + `/${props.Labcode}/about/policies` : process.env.PUBLIC_URL + '/about/policies'}
                                fragment="hippa-compliance">HIPAA Notice of Privacy Practices</a> describes how we use and
                                disclose your protected health
                                information or PHI, our legal duties with respect to your PHI, and your rights with respect to
                                your PHI and how you may exercise them.</p>
                            <p>Our <a href={props.Labcode ? process.env.PUBLIC_URL + `/${props.Labcode}/about/policies#privacy-notice` : process.env.PUBLIC_URL + '/about/policies#privacy-notice'}
                                fragment="privacy-notice">Privacy Notice</a> and <a href={props.Labcode ? process.env.PUBLIC_URL + `/${props.Labcode}/about/policies#cookie-notice` : process.env.PUBLIC_URL + '/about/policies#cookie-notice'}
                                    fragment="cookie-notice">Cookie Notice</a>
                                {" "}provide information about our privacy practices on our
                                websites or applications that link to them.</p>
                        </div>
                        <div className="col-md-4 mt-3 mt-md-0" data-aos="fade-left">
                            <div className="card p-3 card-privacy">
                                <div className="card-title">
                                    <h4 className="text-center">Privacy</h4>
                                </div>
                                <div className="card-body p-1 text-center">
                                    <a className="m-2" href={props.Labcode ? process.env.PUBLIC_URL + `/${props.Labcode}/about/policies#hippa-compliance` : process.env.PUBLIC_URL + '/about/policies#hippa-compliance'}
                                        fragment="hippa-compliance">HIPAA Notice of Privacy Practices</a><br />
                                    <a className="m-2" href={props.Labcode ? process.env.PUBLIC_URL + `/${props.Labcode}/about/policies#privacy-notice` : process.env.PUBLIC_URL + '/about/policies#privacy-notice'}
                                        fragment="privacy-notice">Privacy Notice</a><br />
                                    <a className="m-2" href={props.Labcode ? process.env.PUBLIC_URL + `/${props.Labcode}/about/policies#cookie-notice` : process.env.PUBLIC_URL + '/about/policies#cookie-notice'}
                                        fragment="cookie-notice">Cookie Notice</a><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PrivacyPolicy;