import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setinsuranceInfo } from "../../store/slices/insurance";
import { Button, ButtonGroup, Input, InputGroup, } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import InputMask from "react-input-mask";
import LoaderComp from "../Loader/Loader";
import 'react-toastify/dist/ReactToastify.css';

const StepOne = forwardRef((props, ref, Called, nexPage) => {
    const dispatch = useDispatch()
    const { insuranceInfo } = useSelector((state) => state.insurance);
    const { userinfo } = useSelector((state) => state.user);
    const [GenderList, setGenderList] = useState([])
    const [RelationList, setRelationList] = useState([])
    const [Data, setData] = useState([])
    const [FirstName, setFirstName] = useState("");
    const [MiddleName, setMiddleName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Gender, setGender] = useState("");
    const [DOB, setDOB] = useState();
    const [SSN, setSSN] = useState("");
    const [rSelected, setrSelected] = useState("");
    const [Relation, setRelation] = useState("");
    const [Document, setDocument] = useState("")
    const [Doctype, setDoctype] = useState("")
    const [Document_file, setDocument_file] = useState("")
    const [insurance_document_id, setinsurance_document_id] = useState("")
    const [end_date, setend_date] = useState("")

    const [view, setview] = useState(true)
    const [isLoading, setisLoading] = useState(false)
    const [isDisable, setisDisable] = useState(false)


    async function Submit() {
        let Data_ins = insuranceInfo[0]
        let datevalue = ""
        if (Data_ins?.effective_from?.includes("/")) {
            let datestring = Data_ins?.effective_from?.split("/")
            if (datestring && Data_ins?.effective_from) {
                // datevalue = datestring[0] + "/" + datestring[1] + "/" + datestring[2]
                datevalue = new Date(`${Data_ins?.effective_from}`).getFullYear() + "/" + (new Date(`${Data_ins?.effective_from}`).getMonth() + 1) + "/" + new Date(`${Data_ins?.effective_from}`).getDate()
            }
        }
        if (Data_ins?.effective_from?.includes("-")) {
            // let datestring = Data_ins?.effective_from?.split("-")
            // if (datestring && Data_ins?.effective_from) {
            //     ////console.log("inside")
            //     // datevalue = datestring[0] + "/" + datestring[1] + "/" + datestring[2]
            //     datevalue = datestring[2] + "/" + datestring[0] + "/" + datestring[1]
            // }
            datevalue = Data_ins?.effective_from
        }
        let datevalue2 = ""
        if (await Data?.dob) {
            let datestring = Data?.dob.split("T")
            if (datestring && Data?.dob) {
                let Temp = datestring[0].split("-")
                datevalue2 = Temp[1] + "/" + Temp[2] + "/" + Temp[0]
            }
        }
        let datevalue3 = ""
        if (DOB) {
            // datevalue3 = (new Date(`${DOB}`).getMonth() + 1) + "/" + new Date(`${DOB}`).getDate() + "/" + new Date(`${DOB}`).getFullYear()
            datevalue3 = new Date(`${DOB}`).getFullYear() + "/" + (new Date(`${DOB}`).getMonth() + 1) + "/" + new Date(`${DOB}`).getDate()
        }
        dispatch(
            setinsuranceInfo([{
                id: Data_ins?.id ? Data_ins?.id : null,
                patient_id: Data.id,
                are_you: rSelected,
                first_name: rSelected === "Subscriber" ? Data.first_name : FirstName,
                middle_name: rSelected === "Subscriber" ? Data.middle_name : MiddleName,
                last_name: rSelected === "Subscriber" ? Data.last_name : LastName,
                date_birth: rSelected === "Subscriber" ? datevalue2 : datevalue3,
                ssn_num: rSelected === "Subscriber" ? Data.ssn : SSN,
                gender: rSelected === "Subscriber" ? Data.sex : Gender,
                relation_with: rSelected === "Subscriber" ? "SE" : Relation,
                insurance_type: Data_ins?.insurance_type ? Data_ins?.insurance_type : "",
                selected_insurance: Data_ins?.selected_insurance ? Data_ins?.selected_insurance : "",
                insurance_id: Data_ins?.insurance_id ? Data_ins?.insurance_id : "",
                member_id: Data_ins?.member_id ? Data_ins?.member_id : "",
                group: Data_ins?.group ? Data_ins?.group : "",
                service: Data_ins?.service ? Data_ins?.service : "",
                effective_from: datevalue ? datevalue : "",
                uploaded_document: Document ? Document : "",
                doc_type: Doctype ? Doctype : "",
                document_file: Document_file ? Document_file : "",
                insurance_document_id: insurance_document_id ? insurance_document_id : null,
                end_date: end_date ? end_date : null
            }])
        )
    }

    const printSomething = () => {
        if (props.Called === 1) {
            if (rSelected.trim().length < 1) {
                toast.error("Please Select Who are you ?")
                return false;
            }
            else if (rSelected === "Dependent" && Relation.trim().length < 1) {
                toast.error("Please Select Relation")
                return false;
            }
            else if (rSelected === "Dependent" && FirstName.trim().length < 1) {
                toast.error("Please Enter First Name")
                return false;
            }
            else if (rSelected === "Dependent" && LastName.trim().length < 1) {
                toast.error("Please Enter Last Name")
                return false;
            }
            else if (rSelected === "Dependent" && Gender.trim().length < 1) {
                toast.error("Please Select Gender")
                return false;
            }
            else if (rSelected === "Dependent" && DOB == undefined || null) {
                toast.error("Please Enter Date OF Birth")
                return false;
            }

            else if ((rSelected === "Dependent" && SSN.length > 0) && SSN.length < 9) {
                toast.error("Please Enter Valid SSN")
                return false;
            }
            else {
                Submit()
                return true;
            }
        } else {
            return false;
        }
    }

    async function getInfo() {
        let BASEURL = await localStorage.getItem("BaseApiUrl")
        let Patient_ID = userinfo?.uname
        setisLoading(true)
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/getpatientdetails/` + Patient_ID,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            if (responseJson.data) {
                                //console.log("Data of response ", responseJson)
                                let Temp = responseJson.data
                                setData(Temp)
                                let LocalStep = localStorage.getItem("isStepOne")
                                let Data = insuranceInfo[0]
                                if (LocalStep === "New") {
                                    //console.log("inside the cdondition")
                                    setisLoading(false)
                                }
                                if (LocalStep === "Edit") {
                                    if (Data?.relation_with !== "SE") {
                                        setisLoading(false)
                                    }
                                }
                                return true;
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    useEffect(() => {
        getInfo()
        let LocalStep = localStorage.getItem("isStepOne")
        let Data = insuranceInfo[0]
        if (LocalStep === "Edit") {
            if (Data?.relation_with !== "SE") {
                setrSelected("Dependent")
                setisDisable(!isDisable)
                if (Data?.ssn == "" || null || undefined) {
                    //console.log("in")
                    setview(true)
                } else {
                    //console.log("out")
                    setview(false)
                }
            }
            if (Data?.relation_with === "SE") {
                setrSelected("Subscriber")
                setisDisable(!isDisable)
                if (rSelected) {
                    if (LocalStep === "Edit") {
                        //console.log("Promise Called 1")
                        const promise1 = Promise.resolve(getInfo());
                        promise1.then((value) => {
                            //console.log("Promise Called 2")
                            props.nexPage()
                            setisLoading(false)
                        });
                    }
                }
            }
        }
    }, [rSelected]);

    useImperativeHandle(ref, () => ({
        printSomething: printSomething
    }));

    async function genderList() {
        let BASEURL = await localStorage.getItem("BaseApiUrl")
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/mastertable/sex`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response Gender", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                setGenderList(Temp)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    async function relationList() {
        let BASEURL = await localStorage.getItem("BaseApiUrl")
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/mastertable/relation`,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response Relation", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                setRelationList(Temp)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }
    function handleChanges(event) {
        let data = event.target.value.replace(/[^0-9]/g, '')
        setSSN(data)
    }

    useEffect(() => {
        genderList()
        relationList()
        let Temp = insuranceInfo
        if (Temp.length > 0) {
            setrSelected(Temp[0].are_you)
            setFirstName(Temp[0].first_name)
            setMiddleName(Temp[0].middle_name)
            setLastName(Temp[0].last_name)
            setDOB(Temp[0].date_birth)
            setSSN(Temp[0].ssn_num)
            setGender(Temp[0].gender)
            setRelation(Temp[0].relation_with)
            setDocument(Temp[0].uploaded_document)
            setDoctype(Temp[0].doc_type)
            setDocument_file(Temp[0].document_file)
            setinsurance_document_id(Temp[0].insurance_document_id)
            setend_date(Temp[0].end_date)
        }
        // new Date(`${DOB}`).getFullYear() + "/" + (new Date(`${DOB}`).getMonth() + 1) + "/" + new Date(`${DOB}`).getDate()
    }, []);
    let root = document?.documentElement;
    let Primarycolor = root?.style?.getPropertyValue('--primary-color',) ? root?.style?.getPropertyValue('--primary-color',) : "#6D3580";
    let Secoundrycolor = root?.style?.getPropertyValue('--secoundry-color') ? root?.style?.getPropertyValue('--secoundry-color') : "#CC4165";
    return (
        <>
            <LoaderComp isLoading={isLoading} color1={Primarycolor} color2={Secoundrycolor} />
            <div>
                <ToastContainer
                    position="bottom-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    color
                />
            </div>
            <div className="col-sm-12 col-md-12 pt-2">
                <div className="mt-1 col-sm-3 col-md-2 col-4" >Are you <span className="text-danger">*</span></div>
                <ButtonGroup
                    className="my-2"
                    size="sm"
                    style={{ borderRadius: 5 }}
                >
                    <Button
                        disabled={rSelected === "Dependent" ? isDisable : false}
                        defaultValue={rSelected}
                        style={{
                            backgroundColor: rSelected === "Subscriber" ? `${Secoundrycolor}` : "#fff",
                            color: rSelected === "Subscriber" ? "#fff" : "#000"
                        }}
                        outline
                        onClick={() => {
                            setrSelected("Subscriber")
                            localStorage.setItem("Whoare", "Subscriber")
                            props.nexPage()
                        }}
                        active={rSelected === "Subscriber"}
                        value="Subscriber"
                    >
                        Subscriber
                    </Button>
                    <Button
                        disabled={rSelected === "Subscriber" ? isDisable : false}
                        defaultValue={rSelected}
                        style={{
                            backgroundColor: rSelected === "Dependent" ? `${Secoundrycolor}` : "#fff",
                            color: rSelected === "Dependent" ? "#fff" : "#000"
                        }}
                        outline
                        onClick={() => {
                            localStorage.setItem("Whoare", "Dependent")
                            setrSelected("Dependent")
                        }}
                        active={rSelected === "Dependent"}
                        value="Dependent"
                    >
                        Dependent
                    </Button>
                </ButtonGroup>
            </div>
            {rSelected === "Dependent" &&
                <>
                    <div className="col-sm-12 col-md-12 pt-2">
                        <h6 className="mt-2 col-sm-12 col-12 col-md-4 col-4">Relation to the Subscriber <span className="text-danger">*</span></h6>
                        <ButtonGroup
                            className="my-2"
                            size="sm"
                        >
                            {RelationList.map((item) => {
                                return (
                                    <Button
                                        disabled={isDisable}
                                        style={{
                                            backgroundColor: Relation === item.key ? `${Secoundrycolor}` : "#fff",
                                            color: Relation === item.key ? "#fff" : "#000"
                                        }}
                                        outline
                                        onClick={() => {
                                            setRelation(item.key)
                                        }}
                                        defaultValue={Relation}
                                        active={Relation === item.key}
                                        value={item.key}
                                    >
                                        {item.value}
                                    </Button>
                                )
                            })}
                        </ButtonGroup>

                    </div >
                    < div className="row pt-3">
                        <div className="col-sm-6 col-md-4 p-2">
                            <div className="form-group">
                                <label for="first_name">First Name <span className="text-danger">*</span></label>
                                <input
                                    onChange={(e) => setFirstName(e.target.value)}
                                    defaultValue={FirstName}
                                    type="text"
                                    autocomplete="nope"
                                    className="form-control"
                                    id="first_name"
                                    name="first_name"
                                    formControlName="first_name" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 p-2">
                            <div className="form-group">
                                <label for="first_name">Middle Name</label>
                                <input
                                    onChange={(e) => setMiddleName(e.target.value)}
                                    defaultValue={MiddleName}
                                    type="text"
                                    autocomplete="nope"
                                    className="form-control"
                                    id="first_name"
                                    name="first_name"
                                    formControlName="first_name" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 p-2">
                            <div className="form-group">
                                <label for="middle_name">Last Name <span className="text-danger">*</span></label>
                                <input
                                    onChange={(e) => setLastName(e.target.value)}
                                    defaultValue={LastName}
                                    type="text"
                                    autocomplete="nope"
                                    className="form-control"
                                    id="middle_name"
                                    name="middle_name"
                                    formControlName="middle_name" />
                            </div>
                        </div>
                    </div >
                    <div className="col-sm-12 col-md-12 pt-1">
                        <h6 className="mt-0 col-sm-12 col-12 col-md-4 col-4">Gender <span className="text-danger">*</span></h6>
                        <ButtonGroup
                            className="my-2"
                            size="sm"
                        // style={{ borderRadius: 5 }}
                        >
                            {GenderList.map((item) => {
                                return (
                                    <Button
                                        // className="me-md-2 me-1"
                                        style={{
                                            // borderRadius: 12,
                                            backgroundColor: Gender === item.key ? `${Secoundrycolor}` : "#fff",
                                            color: Gender === item.key ? "#fff" : "#000"
                                        }}
                                        outline
                                        onClick={() => {
                                            setGender(item.key)
                                        }}
                                        defaultValue={Gender}
                                        active={Gender === item.key}
                                        value={item.key}
                                    >
                                        {item.value}
                                    </Button>
                                )
                            })}
                        </ButtonGroup>
                    </div>
                    <div className="row pt-3">
                        <div className="col-sm-6 col-md-4 p-2">
                            <div className="form-group">
                                <label for="first_name">Date Of Birth <span className="text-danger">*</span></label>
                                {/* <DatePicker
                                    placeholderText="MM-DD-YYYY"
                                    dateFormat="MM-dd-yyyy"
                                    className="form-control"
                                    selected={DOB && new Date(`${DOB}`)}
                                    onChange={(date) => setDOB(date)} /> */}



                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={[
                                        'DatePicker',
                                        'MobileDatePicker',
                                        'DesktopDatePicker',
                                        'StaticDatePicker',
                                    ]}>
                                        <DemoItem>
                                            <MobileDatePicker

                                                views={['year', 'month', 'day']}
                                                onAccept={async (e) => {
                                                    await setDOB(e.$d.toString())
                                                }}
                                                // onChange={async (e) => {
                                                //     if (e?.$d != "Invalid Date") {
                                                //         await setDOB(e?.$d)
                                                //     }
                                                // }}
                                                className="customDatePickerClass"
                                                defaultValue={dayjs(`${DOB}`)}
                                            />
                                        </DemoItem>
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 p-2">
                            <div className="form-group">
                                <label for="first_name">SSN</label>
                                <InputGroup >
                                    <Input
                                        autoComplete="nope"
                                        className="form-control"
                                        type={view ? "hidden" : "text"}
                                        value={'****-**-' + SSN?.substring(SSN.length - 4)}
                                        // onChange={(e) => handleChanges(e)}
                                        disabled
                                    />
                                    <InputMask
                                        type={view ? "text" : "hidden"}
                                        value={SSN}
                                        className="form-control"
                                        mask={"999-99-9999"}
                                        maskChar=""
                                        alwaysShowMask={true}
                                        id="ssn"
                                        name="ssn"
                                        onChange={(e) => handleChanges(e)}
                                    />
                                    <Button onClick={() => setview(!view)}>
                                        <i className={view ? "fas fa-eye" : "far fa-eye-slash"}></i>
                                    </Button>
                                </InputGroup>

                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
});


export default StepOne;