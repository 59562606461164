import React, { useEffect, useState } from "react";
import LOGO from "../Assets/myhealth-logo.png";
import "../CSS/Header.scss";
import "../Assets/Fontawasome/fontawesome-free-6.4.0-web/css/all.min.css";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../store/slices/user";
import { setinsuranceInfo } from "../store/slices/insurance";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL, ENV_STATUS } from "../Global/Global";

const Header = (props, Labcode, Logoimage) => {
  const { code } = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [image, setimage] = useState()
  const [Temp, setTemp] = useState(false)
  const { userinfo } = useSelector((state) => state.user);

  const handleButtonClick = (key) => {
    if (key === "Login") {
      props.toggleModal();
    }
    if (key === "ContactUS") {
      props.ContactusToggele();
    }
  };

  async function logOut() {
    const keysToRemove = ['BaseApiUrl', 'TenantNameApi', 'TenantID', 'isStepOne', 'Whoare', 'Listdata',];

    dispatch(
      setUserInfo([]),
      setinsuranceInfo([])
    )
    navigate(process.env.PUBLIC_URL + `/${props.Labcode}`);
    for (const key of keysToRemove) {
      await localStorage.removeItem(`${key}`);
    }
    // localStorage.clear()
    toast.success("Logout Successful")
    window.location.reload();
  }

  async function getInfo() {
    try {
      await fetch(`${BASE_URL}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            response.json().then(async (responseJson) => {
              if (responseJson.data) {
                let temp = []
                responseJson.data.map((item) => {
                  if (item.environment_status === `${ENV_STATUS}`) {
                    temp.push(item)
                  }
                })
                await localStorage.setItem("Baseurl", JSON.stringify(responseJson.data))

                checkLab()
              }
            });
          } else {
            // toast.error("Something Went Wrong !!")
          }
        })
        .catch((error) => {
          console.log("Fetch Error", error);
        });
    } catch (error) {
      console.log('Try Error', error);
    }
  }

  async function checkLab() {
    const Baseurl = JSON.parse(await localStorage.getItem("Baseurl"))
    // console.log("code  in Header Maychhhh============", code, props.Labcode, Baseurl)
    const match = Baseurl?.find(
      (item) => item.laboratory_code == (code ? code : props.Labcode)
    );
    //console.log("match Hedaer", match)
    if (match) {
      localStorage.setItem("BaseApiUrl", match?.base_api_url)
      localStorage.setItem("ReportApiUrl", match?.base_app_url)
      localStorage.setItem("TenantNameApi", match?.name)
      let BASEURL = await localStorage.getItem("BaseApiUrl")
      let Token = userinfo?.token
      let Patient_ID = userinfo?.uname
      try {
        await fetch(`${BASEURL}/apipatient/public/v1/tenantsinfo`,
          {
            method: 'POST',
            headers: {
              // Authorization: 'Bearer ' + Token,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              "laboratory_code": code ? code : props.Labcode,
              "token": Token,
              "patient_id": Patient_ID,
              "with_auth": Patient_ID ? "yes" : "no",
            }),
          })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((responseJson) => {
                //console.log("Get Response Lab Info12", responseJson);
                if (responseJson.data) {
                  let Temp = responseJson.data
                  if (Temp.id) {
                    setimage(Temp.tenant_logo)

                    //console.log("iMAGE TTTT", Temp.tenant_logo)
                    let root = document?.documentElement;
                    root?.style?.setProperty('--primary-color', Temp?.primary_color);
                    root?.style?.setProperty('--secoundry-color', Temp?.secondary_color);
                  }
                }
              });
            } else if (response.status === 500) {
              response.json().then(async (responseJson) => {
                if (responseJson.message == "Token Expired!") {
                  const keysToRemove = ['TenantID', 'isStepOne', 'Whoare', 'Listdata'];
                  dispatch(
                    setUserInfo([]),
                    setinsuranceInfo([])
                  )
                  // navigate(process.env.PUBLIC_URL + `/${userinfo.labcode || code}`);
                  navigate(process.env.PUBLIC_URL + `/${code || userinfo.labcode}`);
                  for (const key of keysToRemove) {
                    await localStorage.removeItem(`${key}`);
                  }
                  // localStorage.clear()
                  toast.success("Session Time Out Please Login Again !!")
                }
              });
            } else {
              toast.error("Something Went Wrong !!")
            }
          })
          .catch((error) => {
            console.log("Fetch Error", error);
          });
      } catch (error) {
        console.log('Try Error', error);
      }
    } else {
      // console.log("inside Else to pass to baseuRL Header 12")
      // navigate(process.env.PUBLIC_URL + "/");
    }
  }

  useEffect(() => {
    if (userinfo.token) {
      setTemp(true)
      getInfo()
      // checkLab()
    }
    else {
      setTemp(false)
      // checkLab()
      getInfo()
    }
    //console.log("LabCode Header", props.Labcode)
  }, []);
  let root = document?.documentElement;
  let Primarycolor = root?.style?.getPropertyValue('--primary-color',) ? root?.style?.getPropertyValue('--primary-color',) : "#6D3580";
  let Secoundrycolor = root?.style?.getPropertyValue('--secoundry-color') ? root?.style?.getPropertyValue('--secoundry-color') : "#CC4165";

  //console.log("Primary Color ", Primarycolor)
  return (
    <>
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          color
        />
      </div>
      <nav className="main-header navbar navbar-expand-xl position-sticky-top-0 navbar-dark" style={{ backgroundColor: "#fff" }}>
        <div className="container-fluid">
          {props.Labcode ?
            <img alt={"image-1"} src={props.Logoimage ? props.Logoimage : image}></img> :
            <img alt={"image-2"} className="logoimage" src={LOGO}></img>
          }
          <button
            className="navbar-toggler"
            style={{ backgroundColor: `${Primarycolor}` }}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <div className="navbar-nav topnv ms-auto">
              {Temp &&
                <li className="nav-item" style={{ marginTop: 2.5 }}>
                  <a href={process.env.PUBLIC_URL + `/${props.Labcode}/dashboard`}
                    className="nav-link active thmclr text-center py-0"
                  // onClick={() => handleButtonClick()}
                  >
                    <i className="fas fa-home absheadernav"></i>
                    <span> Dashboard</span>
                  </a>
                </li>
              }
              {Temp ?
                <li className="nav-item" style={{ marginTop: 2.5 }}>
                  <a href={process.env.PUBLIC_URL + `/${props.Labcode}/user/profile`}
                    className="nav-link active thmclr text-center py-0"
                  // onClick={() => handleButtonClick()}
                  >
                    <i className="fa fa-user-cog absheadernav"></i>
                    <span> My Profile</span>
                  </a>
                </li>
                :
                <li className="nav-item" style={{ marginTop: 2.5 }}>
                  <a href="#"
                    className="nav-link active thmclr text-center py-0"
                    onClick={() => handleButtonClick("Login")}>
                    <i className="fa fa-user-shield absheadernav"></i>
                    <span> Login</span>
                  </a>
                </li>
              }
              <li className="nav-item" style={{ marginTop: 2.5 }}>
                <a
                  a href="#"
                  onClick={() => handleButtonClick("ContactUS")}
                  className="nav-link thmclr text-center py-0"
                  data-target="#model-contact-myhealth"
                  data-toggle="modal"
                >
                  <i className="fa fa-phone-alt absheadernav"></i>
                  <span> Contact Us</span>
                </a>
              </li>
              {Temp &&
                <li className="nav-item" style={{ marginTop: 2.5 }}>
                  <a onClick={() => logOut()} className="nav-link thmclr text-center py-0" href="#" >
                    <i className="fa fa-sign-out-alt absheadernav"></i>
                    <span> Log Out</span></a>
                </li>
              }
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
