const { createSlice } = require("@reduxjs/toolkit");

const newInsurance = createSlice({
    name: "insurance",
    initialState: {
        insuranceInfo: [],
        // insuranceList: []
    },
    reducers: {
        setinsuranceInfo(state, action) {
            state.insuranceInfo = action.payload;
        },
        setinsuranceDate(state, action) {
            //console.log("Slice Data", state.insuranceInfo)
            state.insuranceInfo[0].effective_from = action.payload;
        },
        // setinsuranceList(state, action) {
        //     state.insuranceList = [...state.insuranceList, action.payload];
        // },
    },
});

export const { setinsuranceInfo, setinsuranceDate } = newInsurance.actions;
export default newInsurance.reducer;
