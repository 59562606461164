import React, { useEffect, useState } from "react";
import "../CSS/Terms.scss"
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { setUserInfo } from "../store/slices/user";
import { setinsuranceInfo } from "../store/slices/insurance";
import { applicationSetting } from "../Global/Global";

const TermsCondition = (props, Labcode) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [Temp, setTemp] = useState(false)
    const { userinfo } = useSelector((state) => state.user);
    const { code } = useParams()
    const [htmlContent, sethtmlContent] = useState()

    useEffect(() => {
        if (props.Labcode) {
            setTemp(true)
        } else {
            setTemp(false)
        }
        checkLab()
        test()
        //console.log("LabCode Term Condition", props.Labcode)
    }, []);


    async function test() {
        // console.log("lab code ", props.Labcode ? props.Labcode : code)
        try {
            const settings = await applicationSetting(props.Labcode ? props.Labcode : code);
            settings.data.map((item) => {
                if (item.section_name === "termsandcondition") {
                    sethtmlContent({ __html: item.section_value })
                }
            })
            // Use 'settings' data as needed
        } catch (error) {
            console.error('Error fetching application settings:', error);
            // Handle the error appropriately
        }
    }

    async function checkLab() {
        let Token = userinfo?.token
        let Patient_ID = userinfo?.uname
        let labcode = userinfo?.labcode

        const Baseurl = await JSON.parse(localStorage.getItem("Baseurl"))
        //console.log("Baseurl", Baseurl)

        const match = Baseurl?.find(
            (item) => item.laboratory_code === (labcode ? labcode : code)
        );

        //console.log("match CONTACTUS mODAL", match)
        if (match) {
            localStorage.setItem("BaseApiUrl", match?.base_api_url)
            localStorage.setItem("ReportApiUrl", match?.base_app_url)
            localStorage.setItem("TenantNameApi", match?.name)
            let BASEURL = await localStorage.getItem("BaseApiUrl")
            try {
                await fetch(`${BASEURL}/apipatient/public/v1/tenantsinfo`,
                    {
                        method: 'POST',
                        headers: {
                            // Authorization: 'Bearer ' + Token,
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            "laboratory_code": (labcode ? labcode : code),
                            "token": Token,
                            "patient_id": Patient_ID,
                            "with_auth": Patient_ID ? "yes" : "no",
                        }),
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            response.json().then((responseJson) => {
                                //console.log("Get Response User Info Term Condition", responseJson);
                                if (responseJson.data) {
                                    let Temp = responseJson.data
                                    if (Temp.id) {
                                        let root = document?.documentElement;
                                        root?.style?.setProperty('--primary-color', Temp?.primary_color);
                                        root?.style?.setProperty('--secoundry-color', Temp?.secondary_color);
                                    }
                                }
                            });
                        } else if (response.status === 500) {
                            response.json().then(async (responseJson) => {
                                if (responseJson.message == "Token Expired!") {
                                    const keysToRemove = ['TenantID', 'isStepOne', 'Whoare', 'Listdata'];
                                    dispatch(
                                        setUserInfo([]),
                                        setinsuranceInfo([])
                                    )
                                    // navigate(process.env.PUBLIC_URL + `/${userinfo.labcode || code}`);
                                    navigate(process.env.PUBLIC_URL + `/${code || userinfo.labcode}`);
                                    for (const key of keysToRemove) {
                                        await localStorage.removeItem(`${key}`);
                                    }
                                    // localStorage.clear()
                                    // toast.success("Session Time Out Please Login Again !!")
                                }
                            });
                        } else {
                            toast.error("Something Went Wrong !!")
                        }
                    })
                    .catch((error) => {
                        console.log("Fetch Error", error);
                    });
            } catch (error) {
                console.log('Try Error', error);
            }
        } else {
            //console.log("inside Else to pass to baseuRL Labpage")
        }
    }

    return (
        <>
            <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                color
            />
            <div className="overflow-x-hidden">
                <section className="tos-header" data-aos="fade-left">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="m-0 text-white">Terms and Conditions</h1>
                            </div>
                        </div>
                    </div>
                </section>
                {Temp ?
                    <section className="tos-content" >
                        <div className="container">
                            <div dangerouslySetInnerHTML={htmlContent}>
                            </div>
                        </div>
                    </section>
                    :
                    <section className="tos-content" >
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8" data-aos="fade-left">
                                    <p>Welcome to MyHealth patient portal, a platform that allows you to manage your healthcare
                                        information with ease. By accessing and using our services, you agree to the following terms and
                                        conditions:
                                    </p>
                                </div>
                                <div dangerouslySetInnerHTML={htmlContent}>

                                </div>
                                <div className="col-md-8" data-aos="fade-right">
                                    <p className="m-10px">Thank you for choosing MyHealth patient portal. We are committed to providing you with a secure
                                        and convenient platform to manage your healthcare information.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </div>
        </>
    );
};

export default TermsCondition;
