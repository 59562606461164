// export const homepage = "https://ibazaar.app/myHealth"
export const homepage = "https://my.ihealth.care"

// export const BASE_URL = "https://ibazaar.app/api/tenantdatalist/getalltenant.php?app_mode=test";
export const BASE_URL = "https://my.ihealth.care/tenantdatalist/getalltenant.php?app_mode=prod";

export const ENV_STATUS = "Production";
// export const ENV_STATUS = "Staging";

export default function getCurrentDate(value) {
    const date = new Date(`${value}`);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
}

export const applicationSetting = async (code) => {
    try {
        const response = await fetch(`https://api.ebazaar.app/apipatient/public/v1/tenantapplicationsettings`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "laboratory_code": (code ? code : "")
            })
        });

        if (response.status === 200) {
            const responseJson = await response.json(); // Parse JSON asynchronously
            return responseJson; // Return the parsed JSON data
        } else {
            throw new Error('Request failed with status ' + response.status);
        }
    } catch (error) {
        console.log("Fetch Error", error);
        throw error; // Rethrow the error for handling at a higher level
    }
};


export const getSplitDate = (value) => {
    const temp = value?.split(" ")[0]
    const date = new Date(`${temp}`);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
}