/* eslint-disable jsx-a11y/img-redundant-alt */
import "../CSS/Main.scss";
import React, { useEffect, useState } from "react";
import Banner from "../Assets/medical-banner-2.webp";
import Banner1 from "../Assets/insurance-info.webp";
import Banner2 from "../Assets/medical-info-myHealth.webp";
import Banner3 from "../Assets/view-reports.webp";
import WhyChose from "../Assets/convinence.webp";
import WhyChose2 from "../Assets/secure_access.webp";
import WhyChose3 from "../Assets/ins_info.webp";
import WhyChose4 from "../Assets/user_friendly.webp";
import BannerChnage from "../Assets/medical-banner-1.webp";
import { useNavigate } from "react-router-dom";
import LoaderComp from "./Loader/Loader";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL, ENV_STATUS } from "../Global/Global";

const Main = (props,
  Labcode,
  MainDisc,
  BannerTitle,
  BannerDesc,
  BannerImage) => {
  const navigate = useNavigate()
  const [html, sethtml] = useState({ __html: "" });
  const [isLoading, setisLoading] = useState(false)
  const [response, setresponse] = useState([])
  const handleButtonClick = (code) => {
    props.toggleModal(code);
  };


  async function getInfo() {
    setisLoading(true)
    try {
      await fetch(`${BASE_URL}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((responseJson) => {
              setisLoading(false)
              if (responseJson.data) {
                let temp = []
                responseJson.data.map((item) => {
                  if (item.environment_status === `${ENV_STATUS}`) {
                    temp.push(item)
                  }
                })
                setresponse(temp)
                //console.log("TEMP Y", temp)
                localStorage.setItem("Baseurl", JSON.stringify(responseJson.data))
                // localStorage.setItem("Baseurl", JSON.stringify(temp))
                // console.log("Resopnse 1212", responseJson)
              }
            });
          } else {
            toast.error("Something Went Wrong !!")
            setisLoading(false)
            //console.log("Res False")
          }
        })
        .catch((error) => {
          console.log("Fetch Error", error);
        });
    } catch (error) {
      console.log('Try Error', error);
    }
  }

  useEffect(() => {
    if (html) {
      sethtml({ __html: props.MainDisc })
    }
  }, [html]);

  useEffect(() => {
    setTimeout(() => {
      getInfo()
    }, 1000)
  }, []);
  let root = document?.documentElement;
  let color1 = root?.style?.getPropertyValue('--primary-color',);
  let color2 = root?.style?.getPropertyValue('--secoundry-color');
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        color
      />
      <LoaderComp isLoading={isLoading} color1={color1} color2={color2} />
      <div className="overflow-x-hidden" style={{ backgroundColor: "#E9ECEF" }}>
        <section
          style={{
            backgroundImage: props.BannerImage ? `url(${props.BannerImage})` : `url(${BannerChnage})`,
            backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', height: 500, padding: 0
          }}
          className="position-relative"
        >
          <div
            className="container position-abs-left-0-top-50"
            data-aos="fade-right"
          >
            <div className="d-flex h-100px">
              <div className="col-md-12 col-xs-12 col-sm-12 mx-sm-2 p-3">
                <h2 className="my-1 font-700">{props.BannerTitle ? props.BannerTitle : `The Patient Portal`} :</h2>
                <h4 className="my-2 w-55">
                  {props.BannerDesc ? props.BannerDesc : `Empower Patients with Access to Health Information`}
                </h4>
                <button
                  className="btn btn-lg bg-thmclr-login my-2"
                  onClick={() => handleButtonClick()}
                >
                  Login <i className="fa fa-shield-alt ml-2"></i>
                </button>
              </div>
            </div>
          </div>
        </section>
        {props.Labcode ?
          <section data-aos="zoom-in client-content">
            <div className="container" >
              <div dangerouslySetInnerHTML={html} />
            </div>
          </section>
          :
          <></>
        }

      </div>
      <div className="overflow-x-hidden">
        <section className="portal-intro text-align-center">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-4 p-sm-0 p-md-5" data-aos="fade-left">
                <div className="card p-0 m-0 card-hover-zoom">
                  <img
                    src={Banner}
                    className="img-fluid"
                  ></img>
                </div>
              </div>
              <div className="col-md-8" data-aos="fade-right">
                <h2 className="section-title mb-0">Healthcare Information</h2>
                <h2 className="section-sub-title">
                  Right at Patients’ Fingertips
                </h2>
                <p className="m-0 mb-1">
                  The Patient Portal gives patients 24/7 access to labs, Pay
                  their bill and update their demographics anywhere and on any
                  device.
                </p>
                <p className="m-0 mb-1">
                  The Patient Portal i]s tightly linked to the LIS and offers the
                  lab a complete LIMS solution. With the Patient Portal, labs
                  can brand their portal by adding their personalized logo or a
                  welcome message to the Patient Portal homepage.
                </p>
                <p className="m-0 mb-1">
                  Healthcare professionals also have access to settings in the
                  LIS where they can choose what features they would like to
                  provide to patients.
                </p>
                <h5 className="my-2 font-weight-bold font-italic">
                  For life-threatening medical or mental health emergencies,
                  please call 911 or go to the Emergency Room
                </h5>
              </div>
            </div>
          </div>
        </section>
        <section
          className="dashboard-cards "
          style={{ backgroundColor: "#E9ECEF" }}
        >
          <div className="container">
            <h2 className="section-title" data-aos="zoom-in">
              MyHealth Features
            </h2>
            <div className="row align-items-center">
              <div className="col-md-12" data-aos="fade-up">
                <p>
                  The easiest way to keep track of your lab testing, Bills and
                  Payments. The laboratory is now at your fingertips with the
                  HIPAA-compliant, secure MyHealth patient portal. Access your
                  most recent laboratory reports, review previous results, pay
                  your bill, and update your insurance information – all online.
                  Simply click one of the links below to get started.
                </p>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-8" data-aos="fade-right">
                <h4 className="my-2 text-center set-1">View My Bill</h4>
                <p>
                  Understanding the cost of your care. Convinient and accessible
                  way to see bill details, pay online, and more. See all your
                  current and past bills at one place. Understand your
                  Laboratory bills and pay securely with total transparent and
                  streamlined payment process.
                </p>
              </div>
              <div className="col-md-4 p-sm-0 p-md-5" data-aos="fade-left">
                <div className="card p-0 m-0 card-hover-zoom cursor-pointer">
                  <img alt={"image-3"} src={Banner2} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div
                className="col-md-4 p-sm-0 p-md-5 d-sm-none d-md-block"
                data-aos="fade-right"
              >
                <div className="card p-0 m-0 card-hover-zoom cursor-pointer">
                  <img alt={"image-4"} src={Banner3} className="img-fluid" />
                </div>
              </div>
              <div className="col-md-8" data-aos="fade-left">
                <h4 className="my-2 text-center set-2">View My Reports</h4>
                <p>
                  The easiest way to keep track of your lab testing. MyHealth
                  patient portal, HIPAA-compliant, secure, cloud-based patient
                  portal offers 24-7 access to your test results. MyHealth
                  patient portal make the results accessible and close the gap
                  between data and diagnosis.
                </p>
              </div>
              <div
                className="col-md-4 p-sm-0 p-md-5 d-sm-block d-md-none"
                data-aos="fade-right"
              >
                <div className="card p-0 m-0 card-hover-zoom cursor-pointer">
                  <img alt={"image-5"} src={Banner3} className="img-fluid" />
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-md-8" data-aos="fade-right">
                <h4 className="my-2 text-center set-3">
                  Update Insurance Information
                </h4>
                <p>
                  Add or update your profile information securely. Provide and
                  update your latest insurance information to allow us to bill
                  correctly to your insurance and correct your bill.
                </p>
              </div>
              <div className="col-md-4 p-sm-0 p-md-5" data-aos="fade-left">
                <div className="card p-0 m-0 card-hover-zoom cursor-pointer">
                  <img alt={"image-6"} src={Banner1} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        {props.Labcode ?
          <></> :
          <>
            <section className="myHealth-users">
              <h2 className="section-title" data-aos="zoom-in">
                MyHealth Users
              </h2>
              <div className="container">
                <ul className="ul-users">
                  {response.map((item) => {
                    return (
                      <li
                        data-aos={item.aos_data}
                        className="w-20 card-hover-zoom"
                        title={item.laboratory_code}
                        style={{ height: '150px' }}
                      >
                        <img
                          //   onClick={() => funLogin(item.labcode)}
                          // onClick={() => handleButtonClick(item.labcode)}
                          onClick={() => {
                            navigate(process.env.PUBLIC_URL + `/${item.laboratory_code}`)
                            window.scrollTo(0, 0);
                          }}
                          className="cardimg p-2"
                          src={item.tenant_logo}
                          alt={item.laboratory_code}
                          style={{ objectFit: 'contain', width: "90%", height: '100%' }} // Maintain aspect ratio
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </section>
            <section className="why-choose-us" style={{ backgroundColor: "#E9ECEF" }}>
              <div className="container">
                <h2 className="section-title" data-aos="zoom-in">
                  Why Choose us
                </h2>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        At <strong>MyHealth</strong>, we understand the importance
                        of managing your healthcare needs efficiently and
                        effectively. That's why we offer a patient portal that
                        provides you with easy access to your medical information
                        and insurance-related details. Here are some reasons why you
                        should choose us:
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="row align-items-center">
                        <div className="col-md-8" data-aos="fade-right">
                          <h4 className="my-2 set-1">Convinence</h4>
                          <p>
                            With our patient portal, you can easily manage your
                            bills from laboratories without any hassle. You can view
                            and pay your bills online, which saves you time and
                            effort.
                          </p>
                        </div>
                        <div className="col-md-4 p-2" data-aos="fade-left">
                          <div className="card p-0 m-0 card-hover-zoom cursor-pointer">
                            <img
                              src={WhyChose}
                              className="img-fluid"
                              alt="Convinence Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="row align-items-center">
                        <div
                          className="col-md-4 p-2 d-sm-none d-md-block"
                          data-aos="fade-right"
                        >
                          <div className="card p-0 m-0 card-hover-zoom cursor-pointer">
                            <img
                              src={WhyChose3}
                              className="img-fluid"
                              alt="Insurance Image"
                            />
                          </div>
                        </div>
                        <div className="col-md-8" data-aos="fade-left">
                          <h4 className="my-2 set-1">
                            Access to Insurance Information
                          </h4>
                          <p>
                            Our patient portal allows you to view and change your
                            insurance-related information anytime, anywhere. You can
                            update your insurance details, check coverage, and track
                            claims all in one place.
                          </p>
                        </div>
                        <div
                          className="col-md-4 p-2 d-md-none d-sm-block"
                          data-aos="fade-right"
                        >
                          <div className="card p-0 m-0 card-hover-zoom cursor-pointer">
                            <img
                              src={WhyChose3}
                              className="img-fluid"
                              alt="Insurance Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="row align-items-center">
                        <div className="col-md-8" data-aos="fade-right">
                          <h4 className="my-2 set-1">Secure Platform</h4>
                          <p>
                            We take the security of your personal information
                            seriously. Our patient portal is designed with advanced
                            security features to ensure that your data is safe and
                            protected at all times.
                          </p>
                        </div>
                        <div className="col-md-4 p-2" data-aos="fade-left">
                          <div className="card p-0 m-0 card-hover-zoom cursor-pointer">
                            <img
                              src={WhyChose2}
                              className="img-fluid"
                              alt="Secure Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="row align-items-center">
                        <div
                          className="col-md-4 p-2 d-sm-none d-md-block"
                          data-aos="fade-right"
                        >
                          <div className="card p-0 m-0 card-hover-zoom cursor-pointer">
                            <img
                              src={WhyChose4}
                              className="img-fluid"
                              alt="Insurance Image"
                            />
                          </div>
                        </div>
                        <div className="col-md-8" data-aos="fade-left">
                          <h4 className="my-2 set-1">User-Friendly Interface</h4>
                          <p>
                            Our patient portal is easy to use and navigate, even for
                            those who are not tech-savvy. You can access all the
                            features with just a few clicks.
                          </p>
                        </div>
                        <div
                          className="col-md-4 p-2 d-md-none d-sm-block"
                          data-aos="fade-right"
                        >
                          <div className="card p-0 m-0 card-hover-zoom cursor-pointer">
                            <img
                              src={WhyChose4}
                              className="img-fluid"
                              alt="Insurance Image"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        }
      </div>
    </>
  );
};

export default Main;
