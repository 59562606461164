import React, { useEffect, useState } from "react";
import "../CSS/dashborad.scss"
import "../Assets/Fontawasome/fontawesome-free-6.4.0-web/css/all.min.css";
import { useSelector } from "react-redux";
import BannerChnage from "../Assets/medical-banner-1.webp";


const Patientdashboard = (props, Labcode, Bannerimage) => {
    const [Data, setData] = useState([])
    const { userinfo } = useSelector((state) => state.user);

    //console.log("User info", userinfo)

    async function getInfo() {
        let BASEURL = await localStorage.getItem("BaseApiUrl")
        let Patient_ID = userinfo?.uname
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/getpatientdetails/` + Patient_ID,
                {
                    method: 'GET',
                    headers: {
                        // Authorization: 'Bearer ' + `${ Token }`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            //console.log("Get Response User Info patientdashboard", responseJson);
                            if (responseJson.data) {
                                let Temp = responseJson.data
                                setData(Temp)
                                localStorage.setItem("TenantID", responseJson.data.tenant_id)
                                // localStorage.setItem("TenantName", responseJson.data.)
                            }
                        });
                    } else {
                        //console.log("Res False")
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    useEffect(() => {
        getInfo()
    }, []);
    //console.log("bannee", props.Bannerimage)
    return (
        <>
            <div className="overflow-hidden main-container">
                <section
                    style={{
                        backgroundImage: props.Bannerimage ? `url(${props.Bannerimage})` : `url(${BannerChnage})`,
                        backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover', height: 500, padding: 0
                    }}
                    className="position-relative">
                    <div className="container position-abs-left-0-top-50" data-aos="zoom-in">
                        <div className="row h-100px">
                            <div className="col-md-12 mx-sm-2 p-0">
                                <h4 className="thmclr">{`Welcome, ${Data?.first_name !== undefined ? Data?.first_name : ""} ${Data?.last_name !== undefined ? Data?.last_name : ""}`}</h4>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="dashboard-cards">
                    <div className="container">
                        <div className="row m-0 ">
                            <div className="col-md-4" data-aos="fade-right">
                                <a className="text-muted"
                                >
                                    <div className="card card-custom"
                                        style={{ minHeight: "170px" }}
                                    >
                                        <div className="card-body p-0 set-1">
                                            <a href={process.env.PUBLIC_URL + `/${props.Labcode}/user/invoice`}>
                                                <div className="right text-center">
                                                    <div className="card-img-top">
                                                        <i className="fa fa-file-invoice-dollar"></i>
                                                    </div>
                                                    <h4>View & Pay Bills</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4" data-aos="fade-up">
                                <a className="text-muted"
                                >
                                    <div className="card card-custom"
                                        style={{ minHeight: "170px" }}
                                    >
                                        <div className="card-body p-0 set-2">
                                            <a href={process.env.PUBLIC_URL + `/${props.Labcode}/user/reports`}>
                                                <div className="right text-center">
                                                    <div className="card-img-top">
                                                        <i className="fa fa-file-contract"></i>
                                                    </div>
                                                    <h4>View Reports</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4" data-aos="fade-left">
                                <a className="text-muted"
                                >
                                    <div className="card card-custom"
                                        style={{ minHeight: "170px" }}
                                    >
                                        <div className="card-body p-0 set-3">
                                            <a href={process.env.PUBLIC_URL + `/${props.Labcode}/user/profile?2`}>
                                                <div className="right text-center">
                                                    <div className="card-img-top">
                                                        <i className="fa fa-shield-alt"></i>
                                                    </div>
                                                    <h4>Update Insurance Information</h4>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Patientdashboard;