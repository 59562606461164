import { useEffect, useRef, useState } from 'react';
import '../PDF/printpdf.scss'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import getCurrentDate, { homepage } from '../../Global/Global';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PrintPDF = (Labcode) => {
    const { userinfo } = useSelector((state) => state.user);
    const { id } = useParams();
    const { code } = useParams()
    const [imageFlag, setimageFlag] = useState("")
    const navigate = useNavigate();

    useEffect(() => {
        getInvoiceDetails(id)
        // generatePDF()
    }, []);
    async function getBase64ImageFromURL(url) {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.setAttribute("crossOrigin", "anonymous");

            img.onload = () => {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;

                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var dataURL = canvas.toDataURL("image/png");

                resolve(dataURL);
            };

            img.onerror = error => {
                reject(error);
            };

            img.src = url;
        });
    }

    async function getInvoiceDetails(id) {
        let BASEURL = localStorage.getItem("BaseApiUrl")
        let Patient_ID = userinfo?.uname
        let Token = userinfo?.token
        let labcode = userinfo?.labcode
        //console.log("Form Data", id)
        try {
            await fetch(`${BASEURL}/apipatient/public/v1/invoicespaymentdetails`,
                {
                    method: 'POST',
                    headers: {
                        // Authorization: 'Bearer ' + `${Token}`,
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "id": id,
                        "patient_id": Patient_ID,
                        "token": Token
                    }),
                })
                .then((response) => {
                    if (response.status === 500) {
                        response.json().then(async (responseJson) => {
                            if (responseJson.message == "Token Expired!") {
                                const keysToRemove = ['TenantID', 'isStepOne', 'Whoare', 'Listdata'];
                                for (const key of keysToRemove) {
                                    await localStorage.removeItem(`${key}`);
                                }
                                toast.warn("Session Expired !!")
                                // navigate(process.env.PUBLIC_URL + `/${props.Labcode}`);
                            }
                        });
                    }
                    if (response.status === 200) {
                        response.json().then((responseJson) => {
                            if (responseJson) {
                                //console.log("LOG CALLEDDDD", responseJson)
                                // setinvoiceData(responseJson.data)
                                generatePDF(responseJson.data, BASEURL, labcode)
                                // genrate()
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.log("Fetch Error", error);
                });
        } catch (error) {
            console.log('Try Error', error);
        }
    }

    async function generatePDF(invoice, url, labcode) {
        const Baseurl = await JSON.parse(localStorage.getItem("Baseurl"))
        //console.log("Baseurl", Baseurl)

        const match = Baseurl?.find(
            (item) => item.laboratory_code === labcode
        );
        // if (match) {
        const invoiceDataAccession = await Promise.all(invoice[0].accessiondetails.data.map(async (invoiceItem, index) => {
            const serviceDesarrPromises = invoiceItem.accession.data.ordercatalog.map(async (item) => {
                return item.data[0].catalog_name + " " + "(" + item.data[0].cptcode_name + ")";
            });

            const chargePromises = invoiceItem.accession.data.ordercatalog.map(async (item) => {
                return `$` + parseFloat(item.data[0].main_charge_amount).toFixed(2);
            });

            const insurancePromises = invoiceItem.accession.data.ordercatalog.map(async (item) => {
                return `$` + parseFloat(item.data[0].insurance_paid_amount).toFixed(2);
            });

            const adjustmentPromises = invoiceItem.accession.data.ordercatalog.map(async (item) => {
                return `$` + parseFloat(item.data[0].adjustment_amount).toFixed(2);
            });

            const responsibilityPromises = invoiceItem.accession.data.ordercatalog.map(async (item) => {
                return `$` + parseFloat(item.data[0].responsibility_charge_amount).toFixed(2);
            });

            const serviceDesarr = await Promise.all(serviceDesarrPromises);
            const chargearr = await Promise.all(chargePromises);
            const insurancearr = await Promise.all(insurancePromises);
            const adjustmentarr = await Promise.all(adjustmentPromises);
            const responsibilityarr = await Promise.all(responsibilityPromises);

            return [
                {
                    text: `DOS: ${invoiceItem.accession.data[0].service_date_time}\n Accession: ${invoiceItem.accession.data[0].accession_number}`,
                    fontSize: 6,
                    alignment: 'left',
                    margin: [0, 5, 0, 0]
                },
                [...serviceDesarr.map(service => ({
                    text: service,
                    fontSize: 6,
                    alignment: 'left',
                    margin: [0, 5, 0, 0]
                }))],
                [...chargearr.map(service => ({
                    text: service,
                    fontSize: 6,
                    alignment: 'right',
                    margin: [0, 5, 0, 0]
                }))],
                [...insurancearr.map(service => ({
                    text: service,
                    fontSize: 6,
                    margin: [0, 5, 0, 0],
                    alignment: 'right',
                }))],
                [...adjustmentarr.map(service => ({
                    text: service,
                    fontSize: 6,
                    margin: [0, 5, 0, 0],
                    alignment: 'right',
                }))],
                [...responsibilityarr.map(service => ({
                    text: service,
                    fontSize: 6,
                    margin: [0, 5, 0, 0],
                    alignment: 'right',
                }))]
            ];
        }));

        const invoiceData = invoice[0].accessiondetails.data.map((invoiceItem, index) => {
            let date = invoiceItem.patients.data[0].dob
            const dob = new Date(date);

            // Get the current date
            const currentDate = new Date();

            // Calculate the difference in milliseconds
            const timeDiff = currentDate - dob;

            // Convert the time difference to years
            // const age = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365.25));
            const age = invoiceItem.patients.data[0].dob;
            return [
                {
                    text: `Patient : ${invoiceItem.patients.data[0].first_name} ${invoiceItem.patients.data[0].middle_name} ${invoiceItem.patients.data[0].last_name} (${invoiceItem.patients.data[0].sex}) (${age}) Provider: ${invoiceItem.accession.data[0].doctor_name}`,
                    colSpan: 6,
                    alignment: 'left',
                    fontSize: 7.5,
                    color: "#000"
                },
                {},
                {},
                {},
                {},
                {}
            ];

        });

        const invoiceDataBalance = invoice[0].accessiondetails.data.map((invoiceItem, index) => {
            //console.log("Data oF Invoice Item", invoiceItem)
            return [
                {
                    text: `Patient Balance:        $${parseFloat(invoiceItem.patients.data[0].total_patient_amount).toFixed(2)}`,
                    colSpan: 6,
                    fontSize: 7,
                    alignment: 'right',
                    bold: true,
                },
                {},
                {},
                {},
                {},
                {}
            ];

        });
        function testFunction() {
            let temp = []
            for (let index = 0; index < invoice[0].accessiondetails.data?.length; index++) {
                temp.push(invoiceData[index])
                temp.push(invoiceDataAccession[index])
                temp.push(invoiceDataBalance[index])
            }
            return temp;
        }

        const createDate = new Date(invoice[0].create_dt);
        const today = new Date();
        const timeDifference = today - createDate;

        const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

        let colorpipe = '';
        let colorpipe2 = '';
        let colorpipe3 = '';
        let colorpipe4 = '';
        let colorpipe5 = '';
        let dayCount = true;
        let dayCounttwo = false;
        let dayCountthree = false;
        let dayCountfour = false;

        if (daysDifference <= 30) {
            for (let i = 0; i < daysDifference; i++) {
                colorpipe += '#';
            }
        } else if (daysDifference <= 60) {
            colorpipe = "##############################"
            let temp = daysDifference
            temp = 60 - daysDifference
            dayCounttwo = true;
            for (let i = 0; i < temp; i++) {
                colorpipe2 += '#';
            }
        } else if (daysDifference <= 90) {
            colorpipe = "##############################"
            colorpipe2 = "##############################"
            let temp = daysDifference
            temp = 90 - daysDifference
            dayCounttwo = true;
            dayCountthree = true;
            for (let i = 0; i < temp; i++) {
                colorpipe3 += '#';
            }
        } else if (daysDifference >= 120) {
            colorpipe = "##############################"
            colorpipe2 = "##############################"
            colorpipe3 = "##############################"
            let temp = daysDifference
            temp = Math.abs(120 - daysDifference)
            dayCounttwo = true;
            dayCountthree = true;
            dayCountfour = true;
            if (temp >= 30) {
                temp = 30
                for (let i = 0; i < temp; i++) {
                    colorpipe4 += '#';
                }
            } else {
                for (let i = 0; i < temp; i++) {
                    colorpipe4 += '#';
                }
            }
        }

        let tanantAdd1 = invoice[0].full_tenant_address.split(",")[0]
        let tanantAdd2 = invoice[0].full_tenant_address.split(",")[1]
        let tanantAdd3 = invoice[0].full_tenant_address.split(",")[2].trim().split(" ")[0]
        let tanantAdd4 = invoice[0].full_tenant_address.split(",")[2].trim().split(" ")[1]?.length > 8 ? invoice[0].full_tenant_address.split(",")[2].trim().split(" ")[1].slice(0, 5) + "-" + invoice[0].full_tenant_address.split(",")[2].trim().split(" ")[1].slice(5, 9) : invoice[0].full_tenant_address.split(",")[2].trim().split(" ")[1]

        const daysDueTable =
            [{
                table: {
                    widths: [39, 39, 39, 11, 18],
                    body: [
                        [
                            {
                                border: [
                                    true,
                                    true,
                                    false,
                                    true
                                ], borderColor: "#fff", margin: [-2, 0, 0, 0], height: 5, fillColor: "#ffffff",


                                table: {
                                    //   width:[5],

                                    body: [
                                        [{
                                            text: colorpipe, fontSize: 1.79, border: [
                                                false,
                                                false,
                                                false,
                                                false
                                            ], fillColor: colorpipe ? "#660000" : "#fff", color: "#660000"
                                        }],

                                    ],

                                },
                            },
                            {
                                border: [
                                    true,
                                    true,
                                    false,
                                    true
                                ], borderColor: "#fff", margin: [-2, 0, 0, 0], height: 5, fillColor: "#ffffff",


                                table: {
                                    //   width:[5],

                                    body: [
                                        [{
                                            text: colorpipe2, fontSize: 1.79, border: [
                                                false,
                                                false,
                                                false,
                                                false
                                            ], fillColor: colorpipe2 ? "#660000" : "#fff", color: "#660000"
                                        }],

                                    ],

                                },
                            },
                            {
                                border: [
                                    true,
                                    true,
                                    false,
                                    true
                                ], borderColor: "#fff", margin: [-2, 0, 0, 0], height: 5, fillColor: "#ffffff",


                                table: {
                                    //   width:[5],

                                    body: [
                                        [{
                                            text: colorpipe3, fontSize: 1.79, border: [
                                                false,
                                                false,
                                                false,
                                                false
                                            ], fillColor: colorpipe3 ? "#660000" : "#fff", color: "#660000"
                                        }],

                                    ],

                                },
                            },
                            {
                                border: [
                                    true,
                                    true,
                                    false,
                                    true
                                ], borderColor: "#fff", margin: [-2, 0, 0, 0], height: 5, fillColor: "#ffffff",


                                table: {
                                    //   width:[5],

                                    body: [
                                        [{
                                            text: colorpipe4, fontSize: 1.79, border: [
                                                false,
                                                false,
                                                false,
                                                false
                                            ], fillColor: colorpipe4 ? "#660000" : "#fff", color: "#660000"
                                        }],

                                    ],

                                },
                            },
                            {
                                border: [
                                    false,
                                    true,
                                    true,
                                    true
                                ], borderColor: "#fff", margin: [-2, 0, 0, 0], height: 5, fillColor: "#ffffff",


                                table: {
                                    //   width:[5],

                                    body: [
                                        [{
                                            text: colorpipe5, fontSize: 1.79, border: [
                                                false,
                                                false,
                                                false,
                                                false
                                            ], fillColor: colorpipe5 ? "#660000" : "#fff", color: "#660000"
                                        }],

                                    ],

                                },
                            },
                        ],
                        [
                            {
                                text: '0',
                                fontSize: 8,
                                margin: [-6, 0, 0, 0],
                            },
                            {
                                text: '30',
                                fontSize: 8,
                                margin: [-7, 0, 0, 0],
                            },
                            {
                                text: '60',
                                fontSize: 8,
                                margin: [-7, 0, 0, 0],
                            },
                            {
                                text: '90',
                                fontSize: 8,
                                margin: [-7, 0, 0, 0],
                            },
                            {
                                text: 'more',
                                fontSize: 8,
                                alignment: 'right',
                                margin: [0, 0, -6, 0],
                            },
                        ],
                        [
                            {
                                text: `Total Balance Due .............. $${invoice[0].status === 'Sent' && parseFloat(invoice[0].outstanding_amount).toFixed(2) || invoice[0].status === 'Closed' && "0.00" || invoice[0].status === 'Partial' && parseFloat(invoice[0].outstanding_amount).toFixed(2)}`,
                                fontSize: 7,
                                colSpan: 5,
                                margin: [0, 10, 0, 10],
                                bold: true,
                            },
                            {},
                            {},
                            {},
                            {},
                        ],
                        [
                            {
                                text: 'AMOUNT DUE:',
                                colSpan: 2,
                                margin: [5, 0, 5, 0],
                                fontSize: 7,
                                fillColor: "#d3d3d3",
                                bold: true,
                            },
                            {},
                            {
                                fontSize: 7.5,
                                colSpan: 3,
                                text: `$${invoice[0].status === 'Closed' ? "0.00" : invoice[0].status === 'Sent' ? parseFloat(invoice[0].outstanding_amount).toFixed(2) : invoice[0].status === 'Partial' ? parseFloat(invoice[0].outstanding_amount).toFixed(2) : ''}`,
                                alignment: 'right',
                                fillColor: "#fff",
                                margin: [5, 0, 0, 0],
                            },
                            {},
                            {},
                        ],
                    ],
                },
                layout: {
                    hLineColor: function (i, node) {
                        return 'gray';
                    },
                    vLineColor: function (i, node) {
                        return 'gray';
                    },
                    defaultBorder: false,
                    // paddingLeft: function(i, node) { return 4; },
                    // paddingRight: function(i, node) { return 4; },
                    // paddingTop: function(i, node) { return 2; },
                    // paddingBottom: function(i, node) { return 2; },
                    // fillColor: function (i, node) { return null; }
                }
                // layout: {
                //     defaultBorder: false,
                // },

            }
            ]

        const daysDueTablePaid =
            [{
                table: {
                    widths: [39, 39, 39, 11, 18],
                    body: [
                        [
                            {
                                text: `Total Balance Due .............. $${invoice[0].status === 'Sent' && parseFloat(invoice[0].outstanding_amount).toFixed(2) || invoice[0].status === 'Closed' && "0.00" || invoice[0].status === 'Partial' && parseFloat(invoice[0].outstanding_amount).toFixed(2)}`,
                                fontSize: 7,
                                colSpan: 5,
                                margin: [0, 10, 0, 10],
                                bold: true,
                            },
                            {},
                            {},
                            {},
                            {},
                        ],
                        [
                            {
                                text: 'AMOUNT DUE:',
                                colSpan: 2,
                                margin: [5, 0, 5, 0],
                                fontSize: 7,
                                fillColor: "#d3d3d3",
                                bold: true,
                            },
                            {},
                            {
                                fontSize: 7.5,
                                colSpan: 3,
                                text: `$${invoice[0].status === 'Closed' ? "0.00" : invoice[0].status === 'Sent' ? parseFloat(invoice[0].outstanding_amount).toFixed(2) : invoice[0].status === 'Partial' ? parseFloat(invoice[0].outstanding_amount).toFixed(2) : ''}`,
                                alignment: 'right',
                                fillColor: "#fff",
                                margin: [5, 0, 0, 0],
                            },
                            {},
                            {},
                        ],
                    ],
                },
                layout: {
                    hLineColor: function (i, node) {
                        return 'gray';
                    },
                    vLineColor: function (i, node) {
                        return 'gray';
                    },
                    defaultBorder: false,
                    // paddingLeft: function(i, node) { return 4; },
                    // paddingRight: function(i, node) { return 4; },
                    // paddingTop: function(i, node) { return 2; },
                    // paddingBottom: function(i, node) { return 2; },
                    // fillColor: function (i, node) { return null; }
                }
                // layout: {
                //     defaultBorder: false,
                // },

            }
            ]

        const qrcode = require('qrcode');
        // let qrcodeurl = `${process.env.PUBLIC_URL}/myHealth/${labcode}/user/invoice`
        let qrcodeurl = `${homepage}/${labcode}/user/invoice`
        const qrCodeDataURL = await qrcode.toDataURL(qrcodeurl, {
            errorCorrectionLevel: 'M',
            width: 200, // Set the desired width
            margin: 2, // Set the margin as needed
        });
        const address = invoice[0].full_patient_address ? `${invoice[0].full_patient_address?.split(",")[0]} ${invoice[0].full_patient_address?.split(",")[1]}, ${invoice[0].full_patient_address?.split(",")[2].split(" ")[1]} ${invoice[0].full_patient_address?.split(",")[2].split(" ")[2]?.length > 8 ? invoice[0].full_patient_address?.split(",")[2].split(" ")[2].slice(0, 5) + "-" + invoice[0].full_patient_address?.split(",")[2].split(" ")[2].slice(5, 9) : invoice[0].full_patient_address?.split(",")[2].split(" ")[2] ? invoice[0].full_patient_address?.split(",")[2].split(" ")[2] : ""}` : "";

        const docDefinition = {
            content: [
                {
                    style: 'tableExample',
                    table: {
                        widths: ['45%', '55%',
                        ],
                        body: [
                            [
                                {
                                    image: await getBase64ImageFromURL(`${match?.base_app_url}${invoice[0].tenant_logo}`),
                                    alignment: 'left',
                                    fit: [
                                        100,
                                        100
                                    ],
                                    width: 250,
                                },
                                [
                                    {
                                        fillColor: "#e1e1e1",
                                        table: {
                                            widths: ['*',
                                                150,
                                            ],
                                            body: [
                                                [
                                                    {
                                                        border: [
                                                            false,
                                                            false,
                                                            false,
                                                            false
                                                        ],
                                                        image: qrCodeDataURL,
                                                        alignment: 'left',
                                                        width: 80,
                                                        height: 80,
                                                        fillColor: "#e1e1e1",
                                                    },
                                                    {
                                                        alignment: 'left',
                                                        margin: [0, 10, 0,],
                                                        table: {
                                                            widths: [
                                                                150
                                                            ],
                                                            body: [
                                                                [
                                                                    {
                                                                        text: 'Invoice', fontSize: 13, alignment: 'center', fillColor: '#5356a5', color: "#fff"
                                                                    },
                                                                ],
                                                                [{
                                                                    table: {
                                                                        widths: [
                                                                            '*', '*'
                                                                        ],
                                                                        body: [
                                                                            [
                                                                                {
                                                                                    text: 'Invoice Number:', fontSize: 7.5, alignment: 'right', color: "#000"
                                                                                },
                                                                                {
                                                                                    text: `${invoice[0].invoice_number}`, fontSize: 7.5, alignment: 'left', color: "#000", bold: true,
                                                                                },
                                                                            ],
                                                                            [
                                                                                {
                                                                                    text: 'laboratory Code:', fontSize: 7.5, alignment: 'right', color: "#000"
                                                                                },
                                                                                {
                                                                                    text: `${invoice[0].laboratory_code}`, fontSize: 7.5, alignment: 'left', color: "#000", bold: true,
                                                                                },
                                                                            ]
                                                                        ]
                                                                    },
                                                                    layout: 'noBorders'
                                                                }]
                                                            ]
                                                        },
                                                        layout: 'noBorders'
                                                    },
                                                ],
                                            ],
                                        },
                                        //  layout: 'noBorders'
                                        layout: {
                                            defaultBorder: false,
                                        }
                                    }
                                ]
                            ],

                            [
                                {
                                    text: `${tanantAdd1 ? tanantAdd1 : ""} ${tanantAdd2 ? tanantAdd2 : ""} ,${tanantAdd3 ? tanantAdd3 : ""} ${tanantAdd4 ? tanantAdd4 : ""}`, alignment: 'left', fontSize: 7.5, bold: true,
                                },
                                [
                                    {
                                        table: {
                                            widths: ['*', '*', '*', '*'
                                            ],
                                            body: [
                                                [
                                                    {
                                                        colSpan: 4, text: `Pay Online: ${qrcodeurl?.split("/user/invoice")[0]}`, fontSize: 7, alignment: 'center', fillColor: '#5356a5', color: "#fff"
                                                    },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                            ]
                                        },
                                        layout: 'noBorders'
                                    }
                                ]
                            ],
                            [
                                [
                                    {
                                        table: {
                                            widths: ['*', '*', '*', '*'
                                            ],
                                            body: [
                                                [
                                                    {
                                                        colSpan: 4, text: 'Invoice For Services ', fontSize: 7, alignment: 'center', fillColor: '#5356a5', color: "#fff"
                                                    },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                            ]
                                        },
                                        layout: 'noBorders'
                                    }
                                ],
                                [
                                    {
                                        table: {
                                            widths: ['*', '*', '*', '*'
                                            ],
                                            body: [
                                                [
                                                    {
                                                        text: 'Patient Name', fontSize: 7.5, alignment: 'center', fillColor: '#e1e190', border: [true, true, false, false], bold: true,
                                                    },
                                                    {
                                                        text: 'Due Date', fontSize: 7.5, alignment: 'center', border: [false, true, false, false], bold: true,
                                                    },
                                                    {
                                                        text: 'Amount Due', fontSize: 7.5, alignment: 'center', fillColor: '#c7c6c6', border: [false, true, false, false], bold: true,
                                                    },
                                                    {
                                                        text: 'Amount Paid', fontSize: 7.5, alignment: 'center', border: [false, true, true, false], bold: true,
                                                    }
                                                ],
                                                [
                                                    {
                                                        text: `${invoice[0].patient_name}`, fontSize: 7.5, alignment: 'center', fillColor: '#e1e190', border: [true, false, false, true], bold: true,
                                                    },
                                                    {
                                                        // text: `${invoice[0].invoice_due_dt}`, fontSize: 7.5, alignment: 'center', border: [false, false, false, true], bold: true,
                                                        text: `${getCurrentDate(invoice[0].due_dt?.split(" ")[0])}`, fontSize: 7.5, alignment: 'center', border: [false, false, false, true], bold: true,
                                                    },
                                                    {
                                                        fillColor: '#c7c6c6', text: `$${invoice[0].status == "Partial" && parseFloat(invoice[0].amount - invoice[0].outstanding_amount).toFixed(2) || invoice[0].status === "Closed" && "00.00" || invoice[0].status == "Sent" && parseFloat(invoice[0].outstanding_amount).toFixed(2)}`, fontSize: 7.5, alignment: 'center', border: [false, false, false, true], bold: true,
                                                    },
                                                    {
                                                        text: `$${invoice[0].status === "Closed" && parseFloat(invoice[0].amount).toFixed(2) || invoice[0].status === "Sent" && "0.00"}`, fontSize: 7.5, alignment: 'center', border: [false, false, true, true], bold: true,
                                                    }
                                                ],
                                            ]
                                        },
                                        layout: {
                                            hLineColor: function (i, node) {
                                                return 'gray';
                                            },
                                            vLineColor: function (i, node) {
                                                return 'gray';
                                            },
                                            defaultBorder: false,
                                            // paddingLeft: function(i, node) { return 4; },
                                            // paddingRight: function(i, node) { return 4; },
                                            // paddingTop: function(i, node) { return 2; },
                                            // paddingBottom: function(i, node) { return 2; },
                                            // fillColor: function (i, node) { return null; }
                                        }
                                    }
                                ]

                            ],
                            [
                                [
                                    {
                                        table: {
                                            widths: ['*', '*', '*', '*'
                                            ],
                                            body: [
                                                [
                                                    {
                                                        colSpan: 4, text: 'Billing Question', fontSize: 7, alignment: 'left', fillColor: '#d3d3d3', color: "#000"
                                                    },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                                [
                                                    {
                                                        // colSpan: 4, text: `${invoice[0].tenant_full_contact ? invoice[0].tenant_full_contact : ""}`, fontSize: 7, alignment: 'left', fillColor: '#d3d3d3', color: "#000"
                                                        colSpan: 4, text: `${invoice[0].tenant_full_contact?.includes(",") ? "(" + invoice[0].tenant_full_contact?.split(",")[0].slice(0, 3) + ")" + "-" + invoice[0].tenant_full_contact?.split(",")[0].slice(3, 6) + "-" + invoice[0].tenant_full_contact?.split(",")[0].slice(6) + " " + invoice[0].tenant_full_contact?.split(",")[1] : ""}`, fontSize: 7, alignment: 'left', fillColor: '#d3d3d3', color: "#000"
                                                    },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                            ]
                                        },
                                        layout: 'noBorders'
                                    }
                                ],
                                [
                                    {
                                        table: {
                                            widths: ['*', '*', '*', '*'
                                            ],
                                            body: [
                                                [
                                                    {
                                                        colSpan: 4, text: 'Please make cheeks payable and remit to :', fontSize: 7, alignment: 'center', fillColor: '#5356a5', color: "#fff"
                                                    },
                                                    {},
                                                    {},
                                                    {}
                                                ],
                                            ]
                                        },
                                        layout: 'noBorders'
                                    }
                                ]
                            ],
                            [
                                {
                                    margin: [35, 20, 0, 0],
                                    fontSize: 6,
                                    bold: true,
                                    text: `${invoice[0].patient_name ? invoice[0].patient_name : ""}`
                                },
                                {
                                    margin: [0, 20, 0, 0],
                                    fontSize: 6,
                                    bold: true,
                                    text: `${invoice[0].tenant_name ? invoice[0].tenant_name : ""}`
                                }
                            ],
                            [
                                {
                                    margin: [35, 0, 0, 0],
                                    fontSize: 6,
                                    // text: `${invoice[0].full_patient_address ? invoice[0].full_patient_address : ""}`
                                    // text: `${invoice[0].full_patient_address?.split(",")[0]} ${invoice[0].full_patient_address?.split(",")[1]}, ${invoice[0].full_patient_address?.split(",")[2].split(" ")[1]} ${invoice[0].full_patient_address?.split(",")[2].length > 8 ? invoice[0].full_patient_address?.split(",")[2].split(" ")[2].slice(0, 5) + "-" + invoice[0].full_patient_address?.split(",")[2].split(" ")[2].slice(5, 9) : invoice[0].full_patient_address?.split(",")[2]}`
                                    text: address

                                },
                                {
                                    fontSize: 6,
                                    text: `${invoice[0].full_tenant_address.split(",")[0] ? invoice[0].full_tenant_address.split(",")[0] : ""}`
                                }
                            ],
                            [
                                {},
                                {
                                    fontSize: 6,
                                    text: `${invoice[0].full_tenant_address.split(",")[1]}, ${invoice[0].full_tenant_address.split(",")[2].split(" ")[1]} ${invoice[0].full_tenant_address.split(",")[2]?.length > 8 ? invoice[0].full_tenant_address.split(",")[2].split(" ")[2].slice(0, 5) + "-" + invoice[0].full_tenant_address.split(",")[2].split(" ")[2].slice(5, 9) : invoice[0].full_tenant_address.split(",")[2]}`
                                }
                            ],
                            ['\n', '\n'
                            ],
                            [
                                {
                                    text: '----------------------------------------------------------------------------------------------------------------------------------------------------', colSpan: 2, alignment: 'center'
                                },
                                {}
                            ],
                            ['\n', '\n'
                            ],
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    style: 'tableExample',
                    table: {

                        widths: ['*', '*', 40, 65, 60, 85
                        ],
                        headerRows: 2,
                        body: [
                            [
                                {
                                    text: 'Date/Accession',
                                    fontSize: 7.5,
                                    alignment: 'center',
                                    margin: [2, 2],
                                    bold: true,

                                },
                                {
                                    text: 'Service Description',
                                    fontSize: 7.5,
                                    alignment: 'center',
                                    margin: [2, 2],
                                    bold: true,
                                },
                                {
                                    text: 'Charge',
                                    fontSize: 7.5,
                                    alignment: 'center',
                                    margin: [2, 2],
                                    bold: true,
                                },
                                {
                                    text: 'Insurance Paid',
                                    fontSize: 7.5,
                                    alignment: 'center',
                                    margin: [2, 2],
                                    bold: true,
                                },
                                {
                                    text: 'Adjustment',
                                    fontSize: 7.5,
                                    alignment: 'center',
                                    margin: [2, 2],
                                    bold: true,
                                },
                                {
                                    text: 'Your Responsibility',
                                    fontSize: 7.5,
                                    alignment: 'center',
                                    margin: [2, 2],
                                    bold: true,
                                },
                            ],
                            ...testFunction(),
                        ]
                    },
                    // pageBreak: 'after',
                    layout: {
                        fillColor: function (rowIndex, node, columnIndex) {
                            return (rowIndex % 3 === 1) ? '#ababab' : null;
                        },
                        hLineColor: function (i, node) {
                            return 'gray';
                        },
                        vLineColor: function (i, node) {
                            return 'gray';
                        },
                    }
                },
                {
                    style: 'tableExample',
                    table: {
                        widths: [
                            275, 1, 192
                        ],
                        // dontBreakRows: true,
                        // keepWithHeaderRows: 1,
                        body: [
                            [
                                {
                                    text: 'MESSAGE', border: [
                                        true,
                                        true,
                                        true,
                                        false
                                    ], style: 'tableHeader'
                                }, {},
                                {
                                    text: 'Statement Summary',
                                    style: 'tableHeader',
                                    alignment: 'center',
                                    border: [false],
                                    fillColor: "#5356a5",
                                    color: "#fff"
                                },
                            ],
                            [
                                {
                                    text: `Thank you for using ${(match?.app_platform === "ebazaar" || "estiHealthcare" || "clwihealthcare" || "ihealth") && "iHealth" || (match?.app_platform === "ibazaar" || "ihomehealth") && "iHomeHealth"}. If you have question about your bill or need finacial assistance, do not hesitate to reach out us.Patients enrolled in MyHealth can view and pay their invoices at ${homepage}/${labcode}.`, border: [
                                        true,
                                        false,
                                        true,
                                        true
                                    ],
                                    fontSize: 7.5
                                }, {},
                                invoice[0].status != "Closed" ? [...daysDueTable] : [...daysDueTablePaid]
                            ]
                        ]
                    },
                    // pageBreak: 'after',
                    layout: {
                        defaultBorder: false,
                        hLineColor: function (i, node) {
                            return 'gray';
                        },
                        vLineColor: function (i, node) {
                            return 'gray';
                        },
                    }

                },
                ['\n',],
                invoice[0].status === "Closed" ?
                    {
                        style: 'tableExample',
                        table: {
                            // headerRows: 1,
                            // dontBreakRows: true,
                            // keepWithHeaderRows: 1,
                            width: 500,
                            body: [
                                [
                                    {
                                        text: 'Payment Details', border: [
                                            true,
                                            true,
                                            true,
                                            false
                                        ], style: 'tableHeader'
                                    },
                                ],
                                [
                                    {
                                        text: `Payment received of $${parseFloat(invoice[0].paymentdetails?.data[0]?.received_amount).toFixed(2)} on ${getCurrentDate(invoice[0].paymentdetails?.data[0]?.payment_date.split(" ")[0])} ${invoice[0].paymentdetails?.data[0]?.payment_date.split(" ")[1].split(":")[0]}:${invoice[0].paymentdetails?.data[0]?.payment_date.split(" ")[1].split(":")[1]} by ${invoice[0].paymentdetails?.data[0]?.method} Details: ${invoice[0].paymentdetails?.data[0]?.details} `,
                                        border: [
                                            true,
                                            false,
                                            true,
                                            true
                                        ],
                                        fontSize: 7.5
                                    },
                                ],
                            ]
                        },
                        layout: {
                            defaultBorder: false,
                            hLineColor: function (i, node) {
                                return 'gray';
                            },
                            vLineColor: function (i, node) {
                                return 'gray';
                            },
                        },
                        // pageBreak: 'after',
                    } : null
            ],
            styles: {
                tableExample: {
                    margin: [
                        10,
                        10
                    ]
                },
                MessageHeader: {
                    fontSize: 15,
                    margin: [
                        0,
                        10,
                    ]
                },
            }
        };

        pdfMake.createPdf(docDefinition).download(`${invoice[0].invoice_number}_${invoice[0].laboratory_code}`);
        setTimeout(() => {
            navigate(-1)
        }, 200);
        // }
    };

    return (
        <div>
            <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                color
            />
        </div>
    );
}
export default PrintPDF;