import React, { useEffect, useState } from "react";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Modal,
  Input,
  Row,
  Col,
  Label,
} from "reactstrap";
import "../CSS/Main.scss";
import { useNavigate } from "react-router";
import { setUserInfo } from "../store/slices/user";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useParams, } from "react-router-dom";

const LoginModal = ({ isOpen, toggle, Labcode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [Login, setLogin] = useState("1");
  const [inputLabcode, setinputLabcode] = useState("");
  const [inputFirstName, setinputFirstName] = useState("");
  const [inputLastName, setinputLastName] = useState("");
  const [inputDOB, setinputDOB] = useState();
  const [inputInvoice, setinputInvoice] = useState("");
  const { code } = useParams();

  useEffect(() => {
    const Weburl = window.location.href
    let doc_nameMatch = Weburl.includes("?doc_name=")
    let inv_noMatch = Weburl.includes("?inv_no=")

    if (doc_nameMatch) {
      setLogin("1")
    }
    if (inv_noMatch) {
      setLogin("2")
    }
    if (Labcode) {
      setinputLabcode(Labcode)
    }
  }, []);

  async function login(type) {
    const Weburl = window.location.href
    let doc_nameMatch = Weburl.includes("?doc_name=")
    let inv_noMatch = Weburl.includes("?inv_no=")
    if (Labcode) {
      setinputLabcode(Labcode)
    }
    //Type 1 Validation
    if (type == "1") {
      if (inputLabcode.length < 1) {
        toast.error("Please Enter Labcode");
        return false;
      }
      else if (inputFirstName.length < 1) {
        toast.error("Please Enter First Name");
        return false;
      }
      else if (inputLastName.length < 1) {
        toast.error("Please Enter Last Name");
        return false;
      }
      else if (inputDOB === undefined) {
        toast.error("Please Enter Date Of Birth");
        return false;
      }
      else {
        let BASEURL = await localStorage.getItem("BaseApiUrl")
        let datevalue = ""
        datevalue = new Date(`${inputDOB}`).getFullYear() + "-" + (new Date(`${inputDOB}`).getMonth() + 1) + "-" + new Date(`${inputDOB}`).getDate()
        let docname = Weburl.split("?doc_name=")[1]
        try {
          await fetch(`${BASEURL}/apipatient/public/v1/patientslogin`,
            {
              method: 'POST',
              headers: {
                // Authorization: 'Bearer ' + `${Token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                "loginflag": "personal",
                "labcode": inputLabcode,
                "first_name": inputFirstName,
                "last_name": inputLastName,
                "dob": datevalue,
                "doc_name": docname ? docname : ""
              })
            })
            .then((response) => {
              if (response.status === 200) {
                response.json().then((responseJson) => {
                  toast.error(responseJson.message);
                  if (responseJson.id) {
                    //console.log("Data of response ", responseJson)
                    dispatch(
                      setUserInfo({
                        uname: responseJson.id,
                        token: responseJson.token,
                        labcode: inputLabcode
                      })
                    )
                    if (doc_nameMatch) {
                      // let docname = Weburl.split("?doc_name=")[1]
                      navigate(process.env.PUBLIC_URL + `/${inputLabcode}/user/reports?${docname}`);
                      return true;
                    }
                    else if (inv_noMatch) {
                      let invno = Weburl.split("?inv_no=")[1]
                      navigate(process.env.PUBLIC_URL + `/${inputLabcode}/user/invoice?${invno}`);
                      return true;
                    }
                    else {
                      navigate(process.env.PUBLIC_URL + `/${inputLabcode}/dashboard`);
                      return true;
                    }
                  }
                });
              } else {
                //console.log("Res False")
              }
            })
            .catch((error) => {
              console.log("Fetch Error", error);
            });
        } catch (error) {
          console.log('Try Error', error);
        }
      }
    }

    //Type 2 Validation
    if (type == "2") {
      if (inputLabcode.length < 1) {
        toast.error("Please Enter Labcode");
        return false;
      }
      else if (inputInvoice.length < 1) {
        toast.error("Please Enter Valid Invoice");
        return false;
      }
      else if (inputDOB === undefined) {
        toast.error("Please Enter Date Of Birth");
        return false;
      }
      else {
        let BASEURL = await localStorage.getItem("BaseApiUrl")
        let datevalue = ""
        datevalue = new Date(`${inputDOB}`).getFullYear() + "-" + (new Date(`${inputDOB}`).getMonth() + 1) + "-" + new Date(`${inputDOB}`).getDate()
        try {
          await fetch(`${BASEURL}/apipatient/public/v1/patientslogin`,
            {
              method: 'POST',
              headers: {
                // Authorization: 'Bearer ' + `${Token}`,
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                "loginflag": "invoice",
                "labcode": inputLabcode,
                "invoice_number": inputInvoice,
                "dob": datevalue
              })
            })
            .then((response) => {
              if (response.status === 200) {
                response.json().then((responseJson) => {
                  toast.error(responseJson.message);
                  if (responseJson.id) {
                    //console.log("Data of response ", responseJson)
                    dispatch(
                      setUserInfo({
                        uname: responseJson.id,
                        token: responseJson.token,
                        labcode: inputLabcode
                      })
                    )
                    if (doc_nameMatch) {
                      let docname = Weburl.split("?doc_name=")[1]
                      navigate(process.env.PUBLIC_URL + `/${inputLabcode}/user/reports?${docname}`);
                      return true;
                    } else if (inv_noMatch) {
                      let invno = Weburl.split("?inv_no=")[1]
                      navigate(process.env.PUBLIC_URL + `/${inputLabcode}/user/invoice?${invno}`);
                      return true;
                    } else {
                      navigate(process.env.PUBLIC_URL + `/${inputLabcode}/dashboard`);
                      return true;
                    }
                    // navigate(process.env.PUBLIC_URL + `/${inputLabcode}/dashboard`);
                    // return true;
                  }
                });
              } else {
                //console.log("Res False")
              }
            })
            .catch((error) => {
              console.log("Fetch Error", error);
            });
        } catch (error) {
          console.log('Try Error', error);
        }
      }
    }
  }

  return (
    < div >
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          color
        />
      </div>

      <Modal
        isOpen={isOpen}
        backdropTransition={{ timeout: 100 }}
        toggle={toggle}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={toggle}>Login In to Proceed</ModalHeader>
        <ModalBody>
          <p className="text-center mb-1">
            Authentication is required because the requested page may contain
            personal health information
          </p>
          <p className="text-center mb-1">
            <em>Lab Code and Invoice number are on your on invoice.</em>
          </p>
          <hr />
          <Row className="absmodal">
            <Col md="6" style={{ textAlign: "center" }}>
              <Input
                type="radio"
                value="1"
                checked={Login === "1" ? true : false}
                onChange={(e) => {
                  setLogin(e.target.value);
                }}
              />{" "}
              &nbsp;&nbsp;
              <b>
                <span className="mdsize">Login with Personal Details</span>
              </b>
            </Col>
            <Col md="6">
              <Input
                type="radio"
                checked={Login === "2" ? true : false}
                value="2"
                onChange={(e) => {
                  setLogin(e.target.value);
                }}
              />{" "}
              &nbsp;&nbsp;
              <b>
                <span className="mdsize">Login with Invoice Number</span>
              </b>
            </Col>
          </Row>
          {Login === "2" ? (
            <>
              <Row className="px-5">
                <Label
                  className="mt-2 fw-bold"
                  style={{ marginLeft: "-11px", fontSize: 12 }}
                  for="Lab Code"
                >
                  Lab Code:
                </Label>
                <Input
                  disabled={Labcode ? true : false}
                  type="input"
                  defaultValue={Labcode}
                  className="pt-2 "
                  name=""
                  onChange={(e) => {
                    setinputLabcode(e.target.value);
                  }}
                />
                <Label
                  className="mt-2 fw-bold"
                  style={{ marginLeft: "-11px", fontSize: 12 }}
                  for="Lab Code"
                >
                  Invoice Number:
                </Label>
                <Input
                  type="input"
                  className="pt-2 "
                  onChange={(e) => {
                    setinputInvoice(e.target.value);
                  }}
                  name="" />
                <Label
                  className="mt-2 fw-bold"
                  style={{ marginLeft: "-11px", fontSize: 12 }}
                  for="Lab Code"
                >
                  Date Of Birth:
                </Label>
                {/* <DatePicker
                  dateFormat="MM-dd-yyyy"
                  className="form-control"
                  selected={inputDOB && new Date(`${inputDOB}`)}
                  placeholderText="MM-DD-YYYY"
                  onChange={(date) => {
                    setinputDOB(date);
                  }} /> */}
                <div className="p-0">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={[
                      'DatePicker',
                      'MobileDatePicker',
                      'DesktopDatePicker',
                      'StaticDatePicker',
                    ]}>
                      <DemoItem>
                        <MobileDatePicker
                          views={['year', 'month', 'day']}
                          onAccept={async (e) => {
                            if (e?.$d != "Invalid Date") {
                              await setinputDOB(e?.$d)
                            }
                          }}
                          // onChange={async (e) => {
                          //   if (e?.$d != "Invalid Date") {
                          //     await setinputDOB(e?.$d)
                          //   }
                          // }}
                          className="customDatePickerClass"
                          defaultValue={dayjs(`${inputDOB}`)}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </Row>
            </>
          ) : (
            <>
              <Row className="px-5">
                <Label
                  className="mt-2 fw-bold"
                  style={{ marginLeft: "-11px", fontSize: 12 }}
                  for="Lab Code"
                >
                  Lab Code:
                </Label>
                <Input
                  disabled={Labcode ? true : false}
                  type="input"
                  className="pt-2 "
                  defaultValue={Labcode}
                  name=""
                  onChange={(e) => {
                    setinputLabcode(e.target.value);
                  }}
                />
                <Label
                  className="mt-2 fw-bold"
                  style={{ marginLeft: "-11px", fontSize: 12 }}
                  for="Lab Code"
                >
                  First Name:
                </Label>
                <Input
                  type="input"
                  className="pt-2 "
                  onChange={(e) => {
                    setinputFirstName(e.target.value);
                  }}
                  name="" />
                <Label
                  className="mt-2 fw-bold"
                  style={{ marginLeft: "-11px", fontSize: 12 }}
                  for="Lab Code"
                >
                  Last Name:
                </Label>
                <Input
                  type="input"
                  className="pt-2 "
                  onChange={(e) => {
                    setinputLastName(e.target.value);
                  }}
                  name="" />
                <Label
                  className="mt-2 fw-bold"
                  style={{ marginLeft: "-11px", fontSize: 12 }}
                  for="Lab Code"
                >
                  Date Of Birth:
                </Label>
                {/* <DatePicker
                  dateFormat="MM-dd-yyyy"
                  className="form-control"
                  selected={inputDOB && new Date(`${inputDOB}`)}
                  placeholderText="MM-DD-YYYY"
                  onChange={(date) => {
                    setinputDOB(date);
                  }} /> */}
                <div className="p-0">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={[
                      'DatePicker',
                      'MobileDatePicker',
                      'DesktopDatePicker',
                      'StaticDatePicker',
                    ]}>
                      <DemoItem>
                        <MobileDatePicker
                          views={['year', 'month', 'day']}
                          onAccept={async (e) => {
                            if (e?.$d != "Invalid Date") {
                              await setinputDOB(e?.$d)
                            }
                          }}
                          // onChange={async (e) => {
                          //   if (e?.$d != "Invalid Date") {
                          //     await setinputDOB(e?.$d)
                          //   }
                          // }}
                          className="customDatePickerClass"
                          defaultValue={dayjs(`${inputDOB}`)}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </Row>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() =>
            login(Login)}>
            Login
          </Button>
        </ModalFooter>
      </Modal>
    </div >
  );
};

export default LoginModal;
