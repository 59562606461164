import "../App.scss";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LoginModal from "../components/Modal";
import Main from "../components/Main";
import ContactUs from "../components/ContactUsModal";
import { useSelector } from "react-redux";

const Homepage = () => {
  const [modal, setModal] = useState(false);
  const [contactmodal, setcontactModal] = useState(false);
  const [labCode, setlabCode] = useState(null);
  const { userinfo } = useSelector((state) => state.user);

  const toggleModal = (code) => {
    setModal(!modal);
    setlabCode(code);
  };

  const ContactusToggele = () => {
    setcontactModal(!contactmodal);
  };

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  return (
    <>
      {/* <Footer Labcode={labCode} /> */}
      {/* <LoginModal isOpen={modal} toggle={toggleModal} Labcode={labCode} /> */}
      <Header toggleModal={toggleModal}
        ContactusToggele={ContactusToggele}
        Labcode={userinfo?.labcode ? userinfo?.labcode : labCode} />
      <LoginModal isOpen={modal}
        toggle={toggleModal}
        Labcode={userinfo?.labcode ? userinfo?.labcode : labCode} />
      <ContactUs isOpen={contactmodal}
        ContactusToggele={ContactusToggele} />
      <Main toggleModal={toggleModal} />
      <Footer
        Labcode={userinfo?.labcode ? userinfo?.labcode : labCode} />
    </>
  );
};

export default Homepage;
