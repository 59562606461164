import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import AOS from "aos";
import Patientdashboard from "../components/Patientdashboard";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LoginModal from "../components/Modal";
import ContactUs from "../components/ContactUsModal";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [Logoimage, setLogoimage] = useState()
  const [modal, setModal] = useState(false);
  const [contactmodal, setcontactModal] = useState(false);
  const { userinfo } = useSelector((state) => state.user);
  const [image, setimage] = useState()

  const toggleModal = (code) => {
    setModal(!modal);
  };

  const ContactusToggele = () => {
    setcontactModal(!contactmodal);
  };
  ;
  async function checkLab() {
    // const match = LabDetails.find(
    //   (item) => item.labcode === code
    // );
    const Baseurl = await JSON.parse(localStorage.getItem("Baseurl"))
    const match = Baseurl?.find(
      (item) => item.laboratory_code === code
    );
    localStorage.setItem("BaseApiUrl", match?.base_api_url)
    localStorage.setItem("ReportApiUrl", match?.base_app_url)
    localStorage.setItem("TenantNameApi", match?.name)
    let BASEURL = await localStorage.getItem("BaseApiUrl")
    let Token = userinfo?.token
    let Patient_ID = userinfo?.uname
    if (match) {
      try {
        await fetch(`${BASEURL}/apipatient/public/v1/tenantsinfo`,
          {
            method: 'POST',
            headers: {
              // Authorization: 'Bearer ' + Token,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              "laboratory_code": code,
              "token": Token,
              "patient_id": Patient_ID,
              "with_auth": Patient_ID ? "yes" : "no",
            }),
          })
          .then((response) => {
            if (response.status === 200) {
              response.json().then((responseJson) => {
                //console.log("Get Response User info Dashboard", responseJson);
                if (responseJson.data) {
                  let Temp = responseJson.data
                  if (Temp.id) {
                    setLogoimage(Temp.tenant_logo)
                    setimage(responseJson.data.banner_image)
                    let root = document?.documentElement;
                    root?.style?.setProperty('--primary-color', Temp?.primary_color);
                    root?.style?.setProperty('--secoundry-color', Temp?.secondary_color);
                  }
                }
              });
            } else {
              //console.log("Res False")
            }
          })
          .catch((error) => {
            console.log("Fetch Error", error);
          });
      } catch (error) {
        console.log('Try Error', error);
      }
    } else {
      // console.log("inside Else to pass to baseuRL Dashboard")
      navigate(process.env.PUBLIC_URL + "/");
    }
  }

  //console.log("image", image)

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
    checkLab()
  }, []);

  return (
    <>
      <Header
        ContactusToggele={ContactusToggele}
        toggleModal={toggleModal}
        Labcode={code}
        Logoimage={Logoimage}
      />
      <LoginModal
        isOpen={modal}
        toggle={toggleModal}
        Labcode={code} />
      <ContactUs isOpen={contactmodal} ContactusToggele={ContactusToggele} />
      <Patientdashboard Bannerimage={image} Labcode={code} />
      <Footer Labcode={code} />
    </>
  );
};

export default Dashboard;
